$textColor: #5d5d5d;
$backgroundColor: #00aae5;

.settings_container {
    padding: 10px;

    .settings_main_container {
        padding: 20px;
        // box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;

        .settings_profile {
            color: $textColor;
        }

        .settings_left_side {
            border-right: 2px solid lightgray;
            padding: 0px;

            .active li {
                color: white;
                background-color: $backgroundColor;
            }

            .settings_navlinks {
                text-decoration: none;
                color: $textColor;
            }

            ul {
                list-style: none;
                margin: 10px 0px;
                padding: 0px;

                li {
                    // padding: 5px 20px;
                    padding: 5px 40px 5px 10px;
                    font-size: 20px;
                    color: $textColor;
                    cursor: pointer;
                }
            }
        }
    }


}