.chatbox {
  width: auto;
  overflow: hidden;
  height: 100%;

  .modal-body {
    height: auto;

    @include breakpoint(small) {
      height: 28rem;
    }
  }
}

.popup-approved-chat .chatbox .modal-body {
  height: 280px;
}

.chatbox .modal-dialog,
.chatlist .modal-dialog {
  max-width: 100%;
  margin: 0;
}

.msg-search {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.chat-area .form-control {
  display: block;
  width: 80%;
  padding: 0.375rem 0.75rem;
  font-size: 14px;
  font-weight: 400;
  line-height: 1.5;
  color: #222;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ccc;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border-radius: 0.25rem;
  transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
}

.chat-area .form-control:focus {
  outline: 0;
  box-shadow: inherit;
}

a.add img {
  height: 36px;
}

.chat-area .nav-tabs {
  border-bottom: 1px solid #dee2e6;
  align-items: center;
  justify-content: space-between;
  flex-wrap: inherit;
}

.chat-area .nav-tabs .nav-item {
  width: 100%;
}

.chat-area .nav-tabs .nav-link {
  width: 100%;
  color: #180660;
  font-size: 14px;
  font-weight: 500;
  line-height: 1.5;
  text-transform: capitalize;
  margin-top: 5px;
  margin-bottom: -1px;
  background: 0 0;
  border: 1px solid transparent;
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
}

.chat-area .nav-tabs .nav-item.show .nav-link,
.chat-area .nav-tabs .nav-link.active {
  color: #222;
  background-color: #fff;
  border-color: transparent transparent #000;
}

.chat-area .nav-tabs .nav-link:focus,
.chat-area .nav-tabs .nav-link:hover {
  border-color: transparent transparent #000;
  isolation: isolate;
}

.chat-list h3 {
  color: #222;
  font-size: 16px;
  font-weight: 500;
  line-height: 1.5;
  text-transform: capitalize;
  margin-bottom: 0;
}

.chat-list p {
  color: #343434;
  font-size: 14px;
  font-weight: 400;
  line-height: 1.5;
  text-transform: capitalize;
  margin-bottom: 0;
}

.chat-list a.d-flex {
  margin-bottom: 15px;
  position: relative;
  text-decoration: none;
}

.chat-list .active {
  display: block;
  content: '';
  clear: both;
  position: absolute;
  bottom: 3px;
  left: 34px;
  height: 12px;
  width: 12px;
  background: #00DB75;
  border-radius: 50%;
  border: 2px solid #fff;
}

.msg-head h3 {
  color: #222;
  font-size: 18px;
  font-weight: 600;
  line-height: 1.5;
  margin-bottom: 0;
}

.msg-head p {
  color: #343434;
  font-size: 14px;
  font-weight: 400;
  line-height: 1.5;
  text-transform: capitalize;
  margin-bottom: 0;
}

.msg-head {
  padding: 15px;
  border-bottom: 1px solid #ccc;
}

.moreoption {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.moreoption .navbar {
  padding: 0;
}

.moreoption li .nav-link {
  color: #222;
  font-size: 16px;
}

.moreoption .dropdown-toggle::after {
  display: none;
}

.moreoption .dropdown-menu[data-bs-popper] {
  top: 100%;
  left: auto;
  right: 0;
  margin-top: 0.125rem;
}

.msg-body ul {
  overflow: hidden;
  padding-left: 0;
}

.msg-body ul li {
  list-style: none;
  margin: 15px 0;
}

.msg-body ul li.sender {
  display: block;
  width: 100%;
  position: relative;
}

.msg-body ul li.sender:before {
  display: block;
  clear: both;
  content: '';
  position: absolute;
  top: -6px;
  left: -7px;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 0 4px 15px 12px;
  border-color: transparent transparent $color-offwhite transparent;
  -webkit-transform: rotate(-37deg);
  -ms-transform: rotate(-37deg);
  transform: rotate(-37deg);
}

.msg-body ul li.sender p {
  color: #000;
  font-size: 14px;
  line-height: 1.5;
  font-weight: 400;
  padding: 15px;
  background: $color-offwhite;
  display: inline-block;
  border-bottom-left-radius: 10px;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
  margin-bottom: 0;
}

.msg-body ul li.sender p b {
  display: block;
  color: #180660;
  font-size: 14px;
  line-height: 1.5;
  font-weight: 500;
}

.msg-body ul li.reply {
  display: block;
  width: 100%;
  text-align: right;
  position: relative;
}

.msg-body ul li.reply:before {
  display: block;
  clear: both;
  content: '';
  position: absolute;
  bottom: 15px;
  right: -7px;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 0 12px 15px 12px;
  border-color: transparent transparent $color-e8e8e8 transparent;
  -webkit-transform: rotate(37deg);
  -ms-transform: rotate(37deg);
  transform: rotate(37deg);
}

.msg-body ul li.reply p {
  color: $color-black;
  font-size: 14px;
  line-height: 1.5;
  font-weight: 400;
  padding: 15px;
  background: $color-e8e8e8;
  display: inline-block;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  border-bottom-left-radius: 10px;
  margin-bottom: 0;
}

.msg-body ul li.reply p b {
  display: block;
  color: #061061;
  font-size: 14px;
  line-height: 1.5;
  font-weight: 500;
}

.msg-body ul li.reply:after {
  display: block;
  content: '';
  clear: both;
}

.time {
  display: block;
  color: #000;
  font-size: 12px;
  line-height: 1.5;
  font-weight: 400;
}

li.reply .time {
  margin-right: 20px;
}

.divider {
  position: relative;
  z-index: 1;
  text-align: center;
}

.msg-body h6 {
  text-align: center;
  font-weight: normal;
  font-size: 14px;
  line-height: 1.5;
  color: #222;
  background: $color-white;
  display: inline-block;
  padding: 0 5px;
  margin-bottom: 0;
}

.divider:after {
  display: block;
  content: '';
  clear: both;
  position: absolute;
  top: 12px;
  left: 0;
  border-top: 1px solid #bcbcbc;
  width: 100%;
  height: 100%;
  z-index: -1;
}

// .send-box {
//   border-top: 1px solid $color-bcbcbc;
//   display: flex;
//   align-items: center;
//   background-color: $color-white;

//   .btn {
//     margin-right: .625rem;
//   }

// }

.send-box form {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 0px;
  flex: 1;
}

.send-box .form-control {
  display: block;
  width: 85%;
  padding: 18px 0.75rem;
  font-size: 14px;
  font-weight: 400;
  line-height: normal;
  color: #222;
  background-color: #fff;
  background-clip: padding-box;
  border: none !important;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border-radius: 0.25rem;
  transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
}

.send-box .form-control:focus {
  outline: none;
  box-shadow: none;
}

.send-box button.btn-primary {
  border: none !important;
  padding: 18px 20px;
  color: $color-black;
  border-radius: 0.25rem;
  font-size: 14px;
  font-weight: 400;
  width: auto;

  &:hover {
    border: none !important;
    color: $color-body;
  }

}

.send-box button i {
  margin-right: 5px;
}

.send-btns .button-wrapper {
  position: relative;
  width: 125px;
  height: auto;
  text-align: left;
  margin: 0 auto;
  display: block;
  background: #F6F7FA;
  border-radius: 3px;
  padding: 5px 15px;
  float: left;
  margin-right: 5px;
  margin-bottom: 5px;
  overflow: hidden;
}

.send-btns .button-wrapper span.label {
  position: relative;
  z-index: 1;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  width: 100%;
  cursor: pointer;
  color: #343945;
  font-weight: 400;
  text-transform: capitalize;
  font-size: 13px;
}

#upload {
  display: inline-block;
  position: absolute;
  z-index: 1;
  // width: 100%;
  // height: 100%;
  top: 0;
  left: 0;
  opacity: 0;
  cursor: pointer;
  width: 30px;
}

.attach {
  padding-left: 10px;
  padding-right: 10px;
}

.send-btns .attach .form-control {
  display: inline-block;
  width: 120px;
  height: auto;
  padding: 5px 8px;
  font-size: 13px;
  font-weight: 400;
  line-height: 1.5;
  color: #343945;
  background-color: #F6F7FA;
  background-clip: padding-box;
  border: 1px solid #F6F7FA;
  border-radius: 3px;
  margin-bottom: 5px;
}

.send-btns .button-wrapper span.label img {
  margin-right: 5px;
}

.button-wrapper {
  position: relative;
  // width: 100px;
  // height: 100px;
  text-align: center;
  margin: 0 auto;
}

button:focus {
  outline: 0;
}

.add-apoint {
  display: inline-block;
  margin-left: 5px;
}

.add-apoint a {
  text-decoration: none;
  background: #F6F7FA;
  border-radius: 8px;
  padding: 8px 8px;
  font-size: 13px;
  font-weight: 400;
  line-height: 1.2;
  color: #343945;
}

.add-apoint a svg {
  margin-right: 5px;
}

.chat-icon {
  display: none;
}

.closess i {
  display: none;
}



@media (max-width: 767px) {
  .chat-icon {
    display: block;
    margin-right: 5px;
  }

  .chatlist {
    width: 100%;
  }

  .chatbox {
    width: 100%;
    position: static;
    left: 1000px;
    right: 0;
    background: #fff;
    transition: all 0.5s ease;
    border-left: none;
  }

  .showbox {
    left: 0 !important;
    transition: all 0.5s ease;
  }

  .msg-head h3 {
    font-size: 14px;
  }

  .msg-head p {
    font-size: 12px;
  }

  .msg-head .flex-shrink-0 img {
    height: 30px;
  }

  .send-box button {
    width: 28%;
    margin-right: 10px;
  }

  .send-box .form-control {
    width: 70%;
  }

  .chat-list h3 {
    font-size: 14px;
  }

  .chat-list p {
    font-size: 12px;
  }

  .msg-body ul li.sender p {
    font-size: 13px;
    padding: 8px;
    border-bottom-left-radius: 6px;
    border-top-right-radius: 6px;
    border-bottom-right-radius: 6px;
  }

  .msg-body ul li.reply p {
    font-size: 13px;
    padding: 8px;
    border-top-left-radius: 6px;
    border-top-right-radius: 6px;
    border-bottom-left-radius: 6px;
  }
}

@media (width > 768px) {
  .creative-popup .modal-dialog-scrollable {
    height: 450px;

  }
}

@media (width < 768px) {
  .creative-popup .modal-dialog-scrollable {
    height: calc(100vh - 45%);

  }
}


.creative-popup .popup_content_container {
  max-width: 1230px;
  margin: 0 auto;

  @media (width < 48rem) {
    padding-left: 5px;
    padding-right: 5px;
  }
}

// .chatbox .modal-dialog-scrollable .modal-body {
//   padding: 0 0 20px;
// }

.creative-detail-img {
  position: relative;
}

.creative-detail-img-zoom {
  position: absolute;
  right: 10px;
  top: 10px;
  cursor: pointer;
  padding: 5px;
}

.creative-detail-img-zoom svg {
  font-size: 36px;
}