.main-container-campaign {
  padding-bottom: 40px;
  background-color: #f9fafc;
  font-family: "Open Sans", sans-serif;
  color: #2b2b2b;

  .btn {
    width: 208px;
    padding: 10px 36px 10px 38px;
    border-radius: 10px;
    border: solid 1px;
    margin-bottom: 15px;
  }

  .alert {
    position: relative;
    padding: 0.75rem 1.25rem;
    margin-bottom: 1rem;
    border: 1px solid transparent;
    border-radius: 0.25rem;
  }

  .alert-blue {
    color: #fff;
    background-color: #36a1d1;
    border-color: #36a1d1;
  }
}

// .row {
//   display: flex;
//   flex-wrap: wrap;
//   margin-right: -15px;
//   margin-left: -15px;
// }

.heading {
  display: flex;
  justify-content: space-between;
}

.custom-container {
  max-width: 1280px;
}

.container-configuration {
  padding: 20px;
  background: #fff;
  margin-bottom: 30px;
  border: 1px solid #aaa;
  border-radius: 13px;
}

.infl-type-card {
  display: flex;
  flex-wrap: wrap;
  font-size: 14px;
  margin-right: 8px;
  border-right: 1px solid #eee;
  padding-right: 8px;
  min-width: 140px;
}

.infl-type-card img {
  max-width: 50px;
  margin-right: 10px;
}

.microplus {
  height: 72px;
}

.infl-type-card-content {
  display: flex;
  flex-direction: column;
}

.infl-type-card-content strong {
  padding-right: 10px;
  line-height: 1;
  padding-bottom: 2px;
  font-weight: 600;
}

.infl-type-card-content span {
  color: #777;
  font-size: 12px;
}

.infl-type-card-perc {
  font-weight: 600;
  font-size: 16px;
}

.highchartmain {
  height: 163;
  width: 153px;
}

.card-project-configurator {
  margin: 0 0px 15px 0;
  border-radius: 13px;
  border-style: solid !important;
  border-width: 1px !important;
  border-image-slice: 1 !important;
  width: 100% !important;
  padding: 24px 16px;
  border: 1px solid #aaa;

  .infl-type-card {
    margin-bottom: 15px;
  }

  .card-title {
    font-weight: 600 !important;
    font-size: 24px;
    padding-bottom: 10px;
  }
}

.conf-list-detail {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
}

.conf-list-detail-left .infl-type-card img {
  max-width: 35px;

  .microplus2 {
    height: 72px;
  }
}

.infl-search-res-value {
  padding-top: 15px;
}

// .card-content div span {
//   font-size: 14px;
//   font-weight: normal;
// }

.card-content div span.infl-search-res-value-count {
  font-size: 18px;
  font-weight: 600;
}