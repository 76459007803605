$color-white: #fff;


.product-shop-container {
    background-color: $color-white;
    padding: 20px;
    border-radius: 8px;
}

.product-shop-main-container {
    display: flex;
    /* align-items: center; */
    margin-top: 30px;


    .brand-container {
        flex: 0 0 240px;

        ul {
            margin-left: 0;
            padding-left: 0;
            margin-bottom: 0;
        }

        li {
            list-style: none;
            margin-bottom: 8px;
            padding-bottom: 8px;
            border-bottom: 1px solid #dee2e6;

            &:last-of-type {
                border: none;
                margin-bottom: 0;
                padding-bottom: 0;
            }

            &:hover {
                cursor: pointer;
                color: grey
            }
        }

    }

    .products-container {
        margin-left: 20px;
        padding-left: 20px;
        width: 100%;
        border-left: 1px solid #dee2e6;

        @media only screen and (max-width:991px) {
            border-left: none;
            padding-left: 0;
            margin-left: 0;
        }
    }

}

.product-list-tab {
    margin-bottom: 30px;

    .MuiButtonBase-root {
        font-family: "Open sans";
        text-transform: none;
        font-weight: normal;

        &.Mui-selected {
            color: #00aae5;
        }


    }

    .MuiTabs-scroller {

        .MuiTabs-indicator {
            background-color: #00aae5;
        }
    }

}