$color-body: #2b2b2b !default;
$color-black-5d: #5d5d5d !default;
$color-menu-hover: #5d5d5d !default;
$font-family-body: 'Open Sans', sans-serif !default;
$font-size-body: 1rem !default;
$font-size-sml: 0.875rem !default;
$font-size-xsml: 0.75rem !default;
$font-size-large: 1.125rem !default;
$font-size--1_25rem: 1.25rem !default;
$font-size--1_5rem: 1.5rem !default;
$font-size-title-large: 2.25rem !default;
$font-size-title-large-mobile: 1.75rem !default;
$color-primary: #00aae5 !default;
$color-primary-hover: #36a1d1 !default;
$backarrowColor: $color-primary !default;
$color-secondary: #ee1b6c !default;
$whatsappColor: #25D366 !default;
$errorColor: #d32f2f !default;
$color-white: #ffffff !default;
$color-red: #f00 !default;
$color-offwhite: #f1f1f1 !default;
$width-full: 100% !default;
$container: 1240px !default;
$container-large: 1320px !default;
$container-xlarge: 1440px !default;
$border-radius-default: 8px !default;
$border-radius-card: 14px !default;
$border-radius__10: 10px !default;
$color-pending: #f7be33 !default;
$color-page-background: #f9fafc !default;
$color-offwhite: #f4f4f4 !default;
$button-cubic-bezier: cubic-bezier(.5, -.75, .7, 2);
$button-transition-timing: 0.15s;
$color-login-input-bg: #f2f2f2 !default;
$color-black-lt: #2d2d2d !default;
$color-black-lt1: #eee !default;
$color-black-lt2: #787878 !default;
$color-purple: #7B29FF !default;




// Breakpoint
$mobile: 767px !default;
$xsmall: 576px !default;
$small: 768px !default;
$medium: 992px !default;
$large: $container !default;
$xlarge: $container-large !default;
$xxlarge: $container-xlarge !default;

// Spacing
$spacing-base: 15px !default;
$spacing-double: $spacing-base * 2 !default;
// $spacing-base: 15px;
// $spacing-double: $spacing-base*2;
// $spacing-triple: $spacing-base*3;
// $spacing-four: $spacing-base*4;
// $spacing-half: $spacing-base/2;
// $spacing-quarter: $spacing-half/2;
// Width
// $width-full: 100% !default;
// $full-height: $width-full;
// Typography
$font-size-body: 16px !default;
$font-size-h1: 48px !default;
$font-size-h2: 48px !default;
$font-size-h3: 48px !default;
$font-size-h4: 48px !default;
$font-size-h5: 48px !default;
$font-size-h6: 48px !default;
// Font Weight
$font-weight-light: 300;
$font-weight-regular: 400;
$font-weight-bold: 600;
$font-weight-medium: 500;
// Colors

// $color-primary: #E8E759 !default;
$color-body: $color-primary !default;
$color-hover: #0F0F6c;
$color-cancel: #f00 !default;
$color-error: #d32f2f !default;
$color-black-light: #5d5d5d !default;


// $color-secondary: #CCCB1E;
// $color-tertiary: #4444E4;
// $color-accent: #4444E4;
// $color-inactive: #8E8EEC;
// $color-link: #1818A5;
// $color-hover: #0F0F6c;
// $color-active: #080840;
// $color-focus: #59E8B3;
// $color-white: #fff;
$color-black: #000;
$color-black-7: rgba(0, 0, 0, 0.3);
$color-black-787878: #787878 !default;
$color-f2f2f2: #f2f2f2 !default;
$color-d2d2d2: #d2d2d2 !default;
$color-838383: #838383 !default;
$color-6a6a6a: #6a6a6a !default;
$color-ededed: #ededed !default;
$color-bcbcbc: #bcbcbc !default;
$color-e8e8e8: #e8e8e8 !default;
$color-e1e1e1: #e1e1e1 !default;
$color-4c4c4c: #4c4c4c !default;
$color-d7d7d7: #d7d7d7 !default;
$color-e8ecf4: #e8ecf4 !default;
$color-5f6573: #5f6573 !default;
$color-bcc2ce: #bcc2ce !default;
$color-f5f8fc: #f5f8fc !default;
$color-3676cc: #3676CC !default;
$color-e2eefe: #E2EEFE !default;
$color-5c5c5c: #5c5c5c !default;
$color-dbe0ea: #DBE0EA !default;
$color-f9fafc: #f9fafc !default;
$color-909AAC: #909AAC !default;
$color-ffcaca: #ffcaca !default;
$color-f6f6f6:#f6f6f6 !default;
$color-dbdbdb: #dbdbdb !default;
$color-caffe6: #caffe6 !default;
$color-c5cddc: #C5CDDC !default;
$color-black-lt1: #eee !default;
$color-black-lt2: #787878 !default;
$color-b5b5b5: #b5b5b5 !default;
// Border Color
$color-border-default: $color-black-light !default;
$color-border-light: #dee2e6 !default;
$border-color-card: #e2e2e2 !default;
$border-creative-card: #BCBCBC !default;
$border-campaign-detail-card : $color-d7d7d7 !default;
$color-8c8c8c: #8C8C8C !default;
$color-e6e6e6: #e6e6e6 !default;
// Menu
$color-menu: $color-primary;
$color-menu--hover: $color-hover;
$color-profile-list-border: #ccc !default;
$color-profile-dropdown-hover: #f8f9fa !default;
$color-sidebar-background: #184359 !default;
$color-sidebar-menu-active-bg-color: #092838 !default;
$font-size-main-menu: $font-size-body;
$font-size-sub-menu: $font-size-body - 2;
// Submenu
$color-submenu-bg: $color-primary;
$color-submenu-bg--hover: $color-primary;
$color-submenu: $color-primary;
$color-submenu--hover: darken($color-primary, 10%);
// Header Color
$header-bg: $color-white;
// Footer Color
$footer-bg-color: $color-primary !default;
$footer-font-size: $font-size-body;
// Button
$button-font-default: $font-size-body;
$button-font-large: $font-size-body+4;
$button-color-text-default: $color-primary;
$button-color-text-primary: $color-primary;
$button-color-text-secondary: $color-secondary;
$button-color-border: $color-primary;
$button-color-border--hover: $color-primary;
// Title Section
// Font Size
$heading-sec-title-font-size: $font-size-body;
$heading-sec-subtitle-font-size: $font-size-body;
$heading-sec-description-font-size: $font-size-body;
$heading-sec-button-font-size: $font-size-body;
// Font Weight
$font-weight-bold: 700 !default;
$font-weight-semibold: 600 !default;
$font-weight-light: 300 !default;
// Color
$heading-sec-title-text-color: $color-primary;
$heading-sec-subtitle-text-color: $color-primary;
$heading-sec-description-text-color: $color-primary;
$heading-sec-button-text-color: $color-primary;
$heading-sec-button-text-color--hover: $color-primary;


// Background color
$dashboard-card-background-color-active: #f6f6f6 !default;
$dashboard-card-background-color-hover: #fafafa !default;
// Banner
// Font Size
$banner-title-font-size: $font-size-h1 !default;
$banner-subtitle-font-size: $font-size-h1 !default;
$banner-description-font-size: $font-size-h1 !default;
$banner-button-font-size: $font-size-h1 !default;
// Color
$banner-title-text-color: $color-primary !default;
$banner-subtitle-text-color: $color-primary !default;
$banner-description-text-color: $color-primary !default;
$banner-button-text-color: $color-primary !default;
$banner-button-bg-color: $color-primary !default;
$banner-button-bg-color--hover: $color-primary !default;
$banner-button-border-color: $color-primary !default;
$banner-button-border-color--hover: $color-primary !default;
// Input
$input-color-text: $color-primary !default;
$input-font-size: $font-size-body !default;
$input-border-color: $color-primary !default;
$input-bgcolor: $color-primary !default;
// Textarea
$textarea-color-text: $color-primary !default;
$textarea-font-size: $font-size-body !default;
$textarea-border-color: $color-primary !default;
$textarea-bgcolor: $color-primary !default;
// SUBMIT
$submit-color-text: $color-primary !default;
$submit-font-size: $font-size-body !default;
$submit-border-color: $color-primary !default;
$submit-border-color--hover: $color-primary !default;
$submit-bgcolor: $color-primary !default;
$submit-bgcolor--hover: $color-primary !default;
// Spacing
// $spacings: (
//     xsm: $spacing-half,
//     sm: $spacing-base,
//     md: $spacing-double,
//     lg: $spacing-triple,
//     xlg:$spacing-four
// );
// Colors
$colourscheme: (
    menu: (default: $color-menu, hover: $color-menu--hover),
    submenu: (default: $color-submenu, hover: $color-submenu--hover,
        /* Submenu Background */

        bgcolor: $color-submenu-bg, bghovercolor: $color-submenu-bg--hover),
    /*Button Text Color */

    buttontext: (default: $button-color-text-default, primary: $button-color-text-primary, secondary: $button-color-text-secondary),
    /* Button Background Color */

    buttonBg: (default: $button-color-text-default, primary: $button-color-text-primary, secondary: $button-color-text-secondary),
    primary:$color-primary,
    secondary: #CCCB1E
);
// FontSize
$fontSizes: (
    large: (h1: $font-size-h1, h2: $font-size-h2, h3: $font-size-h3, h4: $font-size-h4, h5: $font-size-h5, h6: $font-size-h6),
    medium: (h1: $font-size-h1, h2: $font-size-h2, h3: $font-size-h3, h4: $font-size-h4, h5: $font-size-h5, h6: $font-size-h6),
    small: (h1: $font-size-h1, h2: $font-size-h2, h3: $font-size-h3, h4: $font-size-h4, h5: $font-size-h5, h6: $font-size-h6),
    menuSize: (mainMenu: $font-size-main-menu, subMenu: $font-size-sub-menu),
    buttonSize: (default: $button-font-default, large: $button-font-large)
);
// Breakpoints
$breakpoints: (
    mobile:$mobile,
    xsml: $xsmall,
    small: $small,
    medium: $medium,
    large: $large,
    xlarge: $xlarge,
    xxlarge:$xxlarge
);