.clanshop-order-now{
    .order-now-content{
        box-shadow: 0px 0px 5px 5px #dfe7ef;
        width: 400px;
        margin: 25px auto;
        padding: 30px 30px;
        border-radius: 8px;
        .order-now-heading{
            text-align: center;
            border-bottom: 1px solid;
            
        }

    }
   

}