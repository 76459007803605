.uploading-image {
  cursor: not-allowed;
  pointer-events: none;
  opacity: 0.5;
}


.upload-links{
  width: 100%;
  margin-top: 10px;
}


.upload-links{
  // border: 1px solid black;


  .links-ul{
    list-style: none;
    margin-top: 3px;
    li{
      border-bottom: 2px solid lightgray;
      padding: 5px 0px;


      .delete-span{
        border: 1px solid black;
        padding: 5px 2px;
        border-radius: 5px;
        cursor: pointer;
      }

      &:last-child{
        border: none;
      }
    }
  }
}