$activeColor: #00aae5;

.invoice_setting_main {
    .influencer_uppercase {
        ul {
            list-style: none;
            margin: 0px;
            padding: 0px;
            display: inline-flex;

            li {
                margin: 0px 30px 0px 0px;
                cursor: pointer;
            }

            .active {
                color: $activeColor;
            }
        }
    }

    .invoice_container_main {
        ul {
            list-style: none;
            margin: 0px;
            padding: 0px;

            .btn {
                margin-top: 20px;
            }

            .MuiFormControlLabel-label {
                font-size: 14px;
            }
        }
    }

    ._influencer_invoice_save_btn {
        display: inline;
        margin-left: 8%;
    }
}