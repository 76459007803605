.common-popup{
    padding: 20px 30px;
    text-align: center;

    .para2{
        font-size: 13px;

        a{
            color: #00aae5;

            &:hover{
                cursor: pointer;
                color: #00aae5;
            }
        }
    }
}

.contentContaainerDivHeading {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.contentDivBrand {
    display: flex;
    justify-content: space-between;
    border-radius: 10px;
    background-color: white;
    margin-bottom: 5px;
    border: 1px solid rgb(238, 237, 237);

    .contentDivLeft {
        display: flex;
        align-items: center;
        justify-content: space-between;

        .contentDivLeftLeft {
            display: flex;
            align-items: center;

            .contentDivLeftLeftImage {
                width: 100px;
                height: 100px;
                border-radius: 10px;
                object-fit: contain;
            }

            .activate{
                cursor: pointer;
            }
        }

        .contentDivLeftRight {
            padding: 10px 0;
            display: flex;

            .contentDivLeftRightName {
                font-weight: 600;
                padding: 0 50px;
                display: flex;
                align-items: center;
                font-size: 16px;
                width: 250px;

                .contentDivLeftRightNameSpan{
                    cursor: default;
                }

                .activate{
                    cursor: pointer;

                    &:hover {
                        color: #00aae5;
                    }
                }

                @media screen and (max-width: 575px) {
                    width: 150px;
                }
            }

            .inboxAndCampaignDiv {
                display: flex;
                min-width: 180px;

                .contentDivLeftRightCampaigns {
                    padding: 10px 10px;
                    font-size: 14px;
                    display: flex;
                    align-items: center;
                    cursor: default;

                    .contentDivLeftRightCampaignsSpan{
                        padding: 10px;
                    }
                }
                .activate{
                    cursor: pointer;
                    &:hover {
                        color: #00aae5;
                    }
                }
            }

            .contentDivLeftRightPremium {
                padding: 0 10px;
                color: #cd5a0c;
                font-size: 12px;
                display: flex;
                align-items: center;
                font-weight: bolder;

                span{
                cursor: pointer;
                }
            }

            @media only screen and (max-width: 980px) {
                display: flex;
                flex-direction: column;

                .contentDivLeftRightName {
                    padding: 0 10px;
                }
            }
        }
    }

    .contentDivRight {
        display: flex;
        align-items: center;

        .contentDivRightUpdateDelete {
            padding: 10px 1rem;
            display: flex;
            align-items: center;
            gap: 0.5rem;

            span{
            cursor: pointer;
            &:hover {
                color: #00aae5;
            }
            }

            .contentDivRightUpdateDeleteUpdate {
                border-right: 1px solid rgb(238, 237, 237);
                // padding: 0px 10px;
                // margin-right: 0.625rem;
            }

            .contentDivRightUpdateDeleteDelete {
                // padding: 0px 0px;
            }
        }
    }
}

.MuiButtonBase-root {
    padding: 0px;
    margin-right: 10px;
}

.fs-75{
 font-size: 0.75rem;;
}