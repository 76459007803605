$backGroundColor: #E6E6E6;
$activeColor: #00aae5;

.plan_campaigns_main {
    .sidebar {
        ul {
            list-style: none;
            margin: 0rem 0rem 0rem .3125rem;
            padding: 0rem;

            li {
                padding: .3125rem .625rem;
                cursor: pointer;
            }

            .active {
                background-color: $backGroundColor;
                transition: .4s ease-in-out;
            }
        }
    }

    .main-content-sec {
        padding: 0rem;
        min-height: 100vh;



        .header {
            ul {
                list-style: none;
                background-color: $backGroundColor;
                margin: 0rem;
                padding: 1.25rem 0rem;

                li {
                    display: inline-block;
                    margin: 0rem 1.25rem;
                    font-weight: bold;
                    font-size: large;
                    cursor: pointer;

                    .navLink {
                        text-decoration: none;
                        color: black;

                    }

                    .active {
                        color: $activeColor;
                    }
                }
            }
        }

    }
}

.page_title {
    display: flex;
    align-items: flex-start;

    .social-icon {
        margin-right: .5rem;
    }
}

.plan-list-sec {
    display: flex;
    padding-left: 0;
    padding-right: 0;
    gap: 1rem;

    .plan-list {
        width: 100%;

        .MuiFormControl-root {
            width: 100%;
        }
    }

    .plan-list-right,
    .plan-list-left {
        flex: 1;
        display: flex;
    }

    .plan-list-left {

        max-width: 100%;

        @include breakpoint(small) {
            max-width: calc(50% - 1.875rem);
        }
    }

    .plan-list-right {
        max-width: 100%;

        @media (width < 35.9375rem) {
            max-width: calc(100% - 4.375rem);
        }
    }

    .plan-list-right {
        gap: 1rem;

        .inner-scrolling-text-container {
            max-width: 100%;
        }
    }

    .plan-name {
        background: #f2f2f2;
        padding: .625rem 1.25rem;
        border-radius: .875rem;
        position: relative;
        width: 100%;
        border: 1px solid $color-f2f2f2;

        .plan-name-heading {
            position: absolute;
            top: -0.875rem;
            font-size: .875rem;
            background: #f2f2f2;
            padding: .1875rem .9375rem;
            border-radius: .625rem;
        }
    }

    .plan-list-left {
        .scrolling-text-sec {
            margin-right: auto;
            padding-right: .625rem;
            max-width: calc(100% - 6.25rem);
        }
    }

}