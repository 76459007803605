// .active-deliverables {
//   background-color: rgba(127, 244, 143, 0.3);
// }


.inf-camp-detail {

  .camp-description {

    .Accepted {
      &:disabled {
        background-color: #fff;
        color: green;
        font-size: 14px;
        padding: 6px 16px;
      }

    }

    .Rejected {
      &:disabled {
        background-color: #fff;
        color: red;
        font-size: 14px;
        padding: 6px 16px;
      }
    }

    .Applied {
      background-color: #ee1b6c;
      color: #fff;
      border: 1px solid #ee1b6c;
      padding: 6px 16px;
      font-size: 14px;

      &:hover {
        color: #ee1b6c;
        background-color: #fff;
      }
    }

    .Apply {
      background-color: #00aae5;
      color: #fff;
      border: 1px solid #00aae5;
      padding: 6px 16px;
      font-size: 14px;

      &:hover {
        color: #00aae5;
        background-color: #fff;
      }
    }
  }
}

.campaign-detail-title-top {
  padding: 0px 0 0 42px;
}