.request-a-demo-page {
    .send-otp{
        height: 40px !important;
        top: 0px !important;
        right: 0px !important;
    }
    .textfield-message{
        margin: 0px !important;
    }
  .resend-otp {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .resend-otp-container {
      display: flex;
      align-items: center;
      justify-content: space-between;
      .otp-btn {
        margin: 0 0;
        padding: 0 0;
        border: none;
        background: none;
      }
    }
  }
}
