$activeColor: #00aae5;

.inf-camp-detail {
    // padding: 0rem .625rem;
    border-radius: $border-radius__10;
    margin-top: 2.5rem;

    .camp-cover-image {
        height: 11.375rem;
        object-fit: contain;
        width: 11.375rem;
        border: .0625rem solid $border-creative-card;
        padding: 0rem;
        border-radius: $border-radius__10;
        background-color: $color-white;
    }

    .product-image {
        height: 12.5rem;
    }

    .left-side {
        // margin-right: 1.25rem;
    }

}


.card-row {
    display: flex;
    flex-wrap: wrap;
    font-size: $font-size-sml;
    width: $width-full;
    margin-bottom: .625rem;

    span {
        margin-left: auto;
    }

    strong {
        padding-right: .625rem;
    }
}

.product-description {
    font-size: $font-size-sml;
}

.reference-link , .commemt{
    font-size: 14px;
  }