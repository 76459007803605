$font: "Open Sans", sans-serif !important;
$fontColor: "";
$fontSize: "";
$brandHeaderBackground: #ededed;
$headerColor: #000000;
$brandHeaderHoverColor: #00aae5;

.site-header {
  // border: 2px solid black;
  font-family: $font;
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  z-index: 9;
  transition: all 0.35s;
  padding: 12px 0;
  background-color: rgba($color-white, 0.6);
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
  transform: translateY(0);

  ._header-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    // position: relative;

    .header-toggle-button {
      position: absolute;
      right: 15px;
      top: 24px;
      display: none;
      height: 24px;
      width: 30px;
      cursor: pointer;

      span {
        background-color: $color-body;
        height: 4px;
        width: 100%;
        display: block;
        margin-bottom: 4px;
        transition: transform 0.35s cubic-bezier(0.075, 0.82, 0.165, 1);
      }

      &.toggle-active {
        right: 20px;

        span {
          &:nth-child(2) {
            transform: translateX(1000px);
          }

          &:nth-child(1) {
            transform: rotate(45deg) translate(11px);
          }

          &:nth-child(3) {
            transform: rotate(-45deg) translate(11px);
          }
        }
      }
    }

    .logo {
      img {
        max-width: 140px;
        height: 46px;
      }
    }

    .nav-menu {
      background-color: $color-white;

      @include breakpoint(medium) {
        background-color: transparent;
      }

      ul {
        margin: 0px;
        padding: 0px;

        .screen_view {
          display: none;
        }

        li {
          position: relative;
          cursor: pointer;
          transition: width 2s, height 4s;

          a {
            position: relative;
            white-space: nowrap;

            &:before {
              content: none;
              width: 100%;
              position: absolute;
              border-bottom: 1px solid transparent;
              bottom: 0px;

              @include breakpoint(medium) {
                content: "";
              }
            }

            text-decoration: none;
            color: $color-white;
            font-size: $font-size-sml;

            @include breakpoint(medium) {
              color: $color-body;
            }

            &:hover,
            &.active {
              color: $color-primary;

              @include breakpoint(medium) {
                color: $color-menu-hover;
              }

              // &:before {
              //   border-bottom-color: $color-primary;
              // }
            }
          }

          &:hover a:after {}
        }
      }
    }

    ul {
      margin: 0px;
      list-style: none;

      li {
        list-style: none;
        display: block;
        padding: 0px;
        border-bottom: 1px solid rgba(238, 238, 238, 0.05) !important;

        @include breakpoint(medium) {
          border-bottom-color: none;
          padding: 10px;
        }

        a {
          text-decoration: none;
          color: $color-white;
          padding: 10px;
          display: block;
          width: 100%;

          @include breakpoint(medium) {
            color: $color-body;
            padding: 0;
          }

          &.active,
          &:hover {
            background-color: $color-sidebar-menu-active-bg-color;
            color: $color-primary;

            @include breakpoint(medium) {
              background-color: transparent;
            }
          }

          &:hover {
            @include breakpoint(medium) {
              color: $color-primary;
            }

            color: $color-menu-hover;
          }

          &.btn {
            padding: 5px 20px;
            color: #fff;

            @media only screen and (max-width:767px) {
              padding: 5px 10px;
              font-size: 14px;
            }

            &:hover {
              color: #00aae5;
            }
          }
        }
      }
    }

    .header-premium {
      margin-right: 10px;

      li {
        &:first-of-type {
          max-width: 200px;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          display: block;

          // span {
          //   padding: 0;
          // }
        }
      }
    }

    .mobile_view_none {
      li {
        display: inline-block;
      }
    }

    .profile_image_menu {
      padding: 10px;
      margin: 0px;
      list-style: none;
      border-radius: 10px;
      background-color: $color-white;
      position: absolute;
      right: 5px;
      top: 40px;
      border: 1px solid $color-profile-list-border;
      z-index: -1;
      overflow: hidden;


      &.active {
        width: 100%;
        height: auto;
      }

      .header_links {
        text-decoration: none;
        background-color: transparent !important;
        color: $color-body;
        padding: 0;
      }

      li {
        align-items: center;
        border-bottom: 1px solid #ccc !important;
        cursor: pointer;
        display: flex;
        font-size: 14px;
        line-height: 1;
        min-height: 30px;
        padding: 5px 0;

        svg {
          width: 0.8em;
          height: 0.8em;
        }

        &:hover {
          background-color: $color-profile-dropdown-hover;
        }

        // &:last-of-type {
        //   border-bottom: none !important;
        // }
      }

      &:before {
        content: "";
        transform: rotate(45deg);
        right: 14px;
        top: -4px;
        z-index: -1;
        position: absolute;
        height: 15px;
        width: 15px;
        background-color: rgba(215, 213, 214, 1);
        display: none;
      }
    }
  }

  // .active {
  //     color: $color-primary !important;
  // }

  @media only screen and (max-width: 991px) {
    ._header-container {
      display: flex;
      // min-height: 100vh;
      max-width: 100%;

      .header-toggle-button {
        display: block;
      }

      .nav-menu {
        display: none;
      }

      .mobile_view_none {
        display: none;
      }

      .active_header {
        display: block;
        height: 100vh;
        display: block;
        position: absolute;
        top: 70px;

        width: 300px;
        left: 0;
        border-top: 1px solid #eee;
        display: flex;
        flex-direction: column;

        &:after {
          position: absolute;
          content: "";
          width: 1000%;
          height: 100%;
          background: rgba(0, 0, 0, 0.5);
          top: 0;
          left: 0;
          z-index: -1;
        }

        .screen_view {
          display: block !important;
        }
      }

      >.btn {
        margin-right: 45px;
        padding-left: 20px;
        padding-right: 20px;
        font-size: 14px;
        padding-top: 5px;
        padding-bottom: 5px;
      }

      ul {
        display: none;
        display: inline;

        li {
          display: flex;
          border-bottom: 1px solid white;
        }

        &:active {
          display: block;
        }
      }

      .header_right_side {
        display: inline;
      }
    }

    .mobile-menu {
      height: calc(100vh - 130px);
      overflow: auto;
    }

    .mobile-profile-info {
      padding: 8px 16px;
      margin-top: auto;
      margin-bottom: 70px;
      background-color: #2b2b2b;

      li {
        color: #fff;
        border-bottom: none;
      }

    }
  }

  &-switch {
    box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.1);

    ._header-container {
      .btn {
        margin-right: 0;
        margin-left: auto;
      }

    }
  }

  ._header_profile_picture {
    position: relative;

    img {
      height: 60px;
      width: 60px;
      border-radius: 50%;
      // border: 1px solid black;
      cursor: pointer;
      display: block;

      @media only screen and (max-width:767px) {
        height: 45px;
        width: 45px
      }
    }

    .profile_image_toggle {
      padding: 0px;
      margin: 0px;
      list-style: none;
      // border: 1px solid green;
      position: absolute;
      padding: 5px;
      border-radius: 50%;
      background-color: #979797;
      right: 4px;
      bottom: 0px;
      cursor: pointer;

      li {
        padding: 0px;
        margin: 0px;
        width: 10px;
        border-bottom: 1px solid white !important;
        padding: 1px;
      }
    }


  }

  .brand_header_profile_picture {
    position: relative;

    img {
      height: 40px;
      width: 40px;
      border-radius: 50%;
      // border: 1px solid black;
      cursor: pointer;
      display: block;

      @media only screen and (max-width:767px) {
        height: 35px;
        width: 35px
      }
    }

    .profile_image_toggle {
      padding: 0px;
      margin: 0px;
      list-style: none;
      // border: 1px solid green;
      position: absolute;
      padding: 5px;
      border-radius: 50%;
      background-color: #979797;
      right: 4px;
      bottom: 0px;
      cursor: pointer;

      li {
        padding: 0px;
        margin: 0px;
        width: 10px;
        border-bottom: 1px solid white !important;
        padding: 1px;
      }
    }

  }



  .profile_image_menu_disable {
    padding: 10px;
    margin: 0px;
    list-style: none;
    border-radius: 10px;
    background-color: $color-white;
    position: absolute;
    right: 5px;
    top: 50px;
    width: 220px;
    border: 1px solid $color-profile-list-border;
    width: 60px;
    height: 60px;

  }

  .nav-menu {
    .MuiTypography-root a {
      text-decoration: none;
      color: #2b2b2b;
      display: block;
      padding: 8px 16px;

    }

    .MuiButtonBase-root {
      padding: 0;
    }

    .MuiListItemText-root {
      margin: 0;
    }
  }

  &.site-header-switch {
    ._header-container {
      justify-content: flex-start;
      max-width: 1920px;
    }
  }
}


span._header_profile_picture {
  border-radius: 50%;
  padding: 0 !important;
  border: 1px solid $border-color-card;
}

// Innerpage header

.app-header_brand {
  font-family: $font;
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  z-index: 9;
  transition: all 0.35s;
  padding: .75rem 1.25rem;
  background-color: rgba(255, 255, 255, 0.6);
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
  transform: translateY(0);
  width: 100%;

  @media (width < 35.9375rem) {
    padding: .75rem 1rem;
  }

  box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.1);

  ._header-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    // padding: 10px;
    max-width: 1920px;
    margin: 0 auto;
    // height: 80px;
    padding-left: 0;
    padding-right: 0;

    .logo {
      img {
        max-width: 140px;
        height: 46px;
      }
    }

    .app-header_brand_left_side {
      display: flex;
      align-items: center;

      .app_header_ul {
        padding: 0px;
        margin: 0px;
        list-style: none;
        display: inline-flex;
        margin-left: 10px;

        li {
          padding: 0;
          font-size: $font-size-large;
          font-weight: 400;
          line-height: 24.5104px;
          cursor: pointer;
          margin: 0;

          @include breakpoint(medium) {
            padding: 0px 6px;
          }

          @include breakpoint(large) {
            padding: 0px 10px;
          }

          @include breakpoint(xlarge) {
            padding: 0px 15px;
          }
        }
      }
    }

    .app-header-brand_right_side {
      display: flex;
      align-items: flex-end;
      position: relative;

      .header_iconSize {
        font-size: 30px;
      }

      span {
        padding: 0px 10px;
        // cursor: pointer;
        &.brand_header_profile_picture {
          width: 40px;
          height: 40px;
          border-radius: 50%;
          padding: 0;
          border: 1px solid #eee;
          img {
            width: 100%;
            height: 100%;
            object-fit: contain;

          }
        }
      }

      //toggle Button
      .header-toggle-button {
        display: none;
        margin-right: 10px;

        ul {
          margin: 0px;
          padding: 0px;
          list-style: none;
          cursor: pointer;

          li {
            width: 25px;
            border-bottom: 4px solid black;
            margin-top: 3px;
          }
        }
      }
    }
  }

  .header_links {
    text-decoration: none;
    color: black;
  }

  // .active {
  //   color: $brandHeaderHoverColor;
  // }

  @media only screen and (max-width: 991px) {
    .app-header_brand_left_side {
      display: block !important;
    }

    .app_header_ul {
      // display: block !important;

      li {
        margin: 10px 0px;
      }
    }

    .app-header-brand_right_side {
      position: absolute;
      top: auto;
      bottom: auto;
      // right: 10px;
    }

    // .nav-menu{
    //     height: 100vh;
    // }
    .active_header {
      display: none;
      height: 100vh;
    }

    .header-toggle-button {
      display: block !important;
      top: 0;
      right: 0;
      position: static !important;
      margin-left: 10px;
    }
  }
}

.scroll-up {
  .app-header {
    transform: translateY(0px);
  }
}

.scroll-down {
  .app-header {
    transform: translateY(-150px);
  }
}



.header-cart {
  width: 40px;
  height: 40px;
  /*! background: #eee; */
  border-radius: 50%;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;

  .header-cart-count {
    position: absolute;
    top: 0;
    right: -3px;
    padding: 5px;
    background: #00aae5;
    border-radius: 50%;
    width: 22px;
    height: 22px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-decoration: none;
    font-size: 14px;
    color: #fff;
  }

  a {
    color: #2b2b2b;
  }
}


.cart-count {
  width: 26px;
  height: 26px;
  display: inline-block;
  background: #00aae5;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  margin-left: 15px;
  border-radius: 50%;
  color: #fff;
  font-weight: 700;
}



.no-cart {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: calc(100vh - 200px);
  background: #fff;
  margin-top: 20px;
  border-radius: 4px;
  border: 1px solid #dee2e6;
  margin-bottom: 20px;
}

.no-cart h1 {
  margin-bottom: 30px;
  font-size: 24px;
  font-weight: 600;
}

.site-header ._header-container .profile_image_menu li span {
  padding: 0 10px;
}