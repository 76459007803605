.inf-tools {
  height: calc(100vh - 240px);
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1.5rem;
  > .btn {
    height: 80px;
    border-radius: 10px;
    min-width: 326px;
    font-size: $font-size-body;
  }
}
.automation-select {
  margin-bottom: 3.125rem;
  .input-checkbox-box-container {
    display: flex;
    flex-wrap: wrap;
    gap: 1.5rem;
  }
  .input-checkbox-box {
    flex: 1;
    border-radius: 10px;
    border-color: $color-bcbcbc !important;
    cursor: pointer;
    .input-checkbox-box-label {
      color: #000;
      font-weight: normal;
      font-size: $font-size-sml;
      span {
        color: $color-black-787878;
        font-weight: normal;
        margin-left: 0;
        font-size: $font-size-sml;
      }
    }
    &.active {
      border-color: $color-black !important;
      .input-checkbox-box-label {
        color: #000;
      }
    }
  }
  .input-checkbox-box-label {
    color: #000;

    > strong {
      font-weight: 600;
      font-size: $font-size-sml;
    }
  }
}

.new-automation-steps {
  margin-bottom: 1.25rem;
  .material-symbols-rounded {
    font-size: 2.5rem;
    color: $color-838383;
    margin-right: 0.3125rem;
  }
}

.new-automation-step-counter {
  counter-reset: step-counter 0;
  list-style: none;
  padding-left: 0;
  li {
    counter-increment: step-counter 1;
    margin-bottom: 40px;
    
    &:before {
      content: "Step " counter(step-counter) ": ";
      font-weight: bold;
      padding-right: 3px;
    }
  }
}

.specific-post-container {
  max-width: 340px;
  border: 1px solid #000;
  border-radius: 10px;
  padding: 10px;
  span {
    color: $color-black-5d;
  }
  a {
    color: $color-body;
  }
}

.post-image-container {
  display: flex;
  flex-wrap: wrap;
  gap: 9px;
  .post-image {
    width: 100px;
    height: 100px;
    border-radius: 5px;
    border: 1px solid #e2e2e2;
    img {
      border-radius: 5px;
      width: 100px;
      height: 100px;
    }
    &.post-story {
      width: 100px;
      height: 176px;
    }
    
  }
}

.new-automation-chips {
  display: flex;
  gap: 0.5rem;
  flex-wrap: wrap;
  .MuiChip-filled {
    background: $color-f6f6f6;
    border:1px solid $color-dbdbdb;
  }
  .form-control {
    border-radius: 40px;
    height: 32px;
    font-size: $font-size-sml;
  }
}
.new-keyword {
  border-radius: 34px;
  border: 1px solid #000;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 3px 10px;
  font-size: 14px;
  cursor: pointer;
  .material-symbols-rounded {
    color: $color-black;
  }
}


.reply-options {
  flex-direction: column;
  gap: 0.5rem;
  display: inline-flex;
}

.reply-option-active {
  border: 1px solid #000;
  border-radius: 6px;
  padding: 10px;
}

.reply-option-active  > .btn {
  border: none;
  padding: 0;
  padding-bottom: 10px;
  text-align: left;
}

.reply-option-active > .btn:hover {
  border: none;
}
.custom-replies-box-yes {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}
.custom-replies-box-yes .btn {
  
}

.add-reply-btn {
  border: 2px dashed $color-body;
  text-align: center;
  padding: 0.63rem 2.5rem;
  border-radius: 0.5rem;
  cursor: pointer;
  font-size: $font-size-sml;
}
.reply-button {
  min-width: 24.375rem;
}
.product-link-action-btns,
.product-link-input-container {
  display: flex;
  flex-direction: column;
}
.product-link-input-container {
  margin-bottom:100px;
}
.product-link-action-btns {
  gap: 0.5rem;
  margin-bottom: 2.5rem;
  max-width: 24.375rem;
}

.product-link-input {
  max-width: 530px;
  margin-bottom: 2.5rem;
  .btn, .form-control {
    border-radius: .625rem;
  }
  .btn {
    min-width: 7.1875rem;
    background-color: $color-body;
  }
  .form-control {
    font-size: $font-size-sml;
    border-color: $color-body;
    height: 2.625rem;
  }
}



.linked-product-list-container {
  display: flex;
  flex-direction: column;
  margin-bottom: 40px;
}

.linked-product {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  border-bottom: 1px solid $color-838383;
  padding-bottom: 1rem;
  margin-bottom: 1rem;
  &:last-of-type {
    border-bottom: none;
  }
}

.linked-product-img {
  max-width: 115px;
  flex: 0 0 115px;
  border: 1px solid $color-black-787878;
  border-radius: 10px;
  margin-right: 15px;
}

.linked-product-img img {
  max-width: 100%;
  border-radius: 10px;
}

.linked-product-detail {
  flex: 0 0 calc(100% - 130px);
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  strong {
    color:$color-838383;
  }
  h2 {
    font-weight: normal;
    font-size: $font-size-body;
    a {
    color: $color-body;
    text-decoration: none;
    &:hover{
      text-decoration: underline;
    }
  }
  }
}


.linked-product-detail-left {
  max-width: 550px;
  margin-right: auto;
}

.btn.linked-product-delete {
  margin-left: auto;
  padding: 0;
  border-color: #000;
  border-radius: 10px;
  &:hover {
    background-color: #000;
  }
}

.linked-product-delete .material-symbols-rounded {
  font-size: 24px;
  display: flex;
  width: 42px;
  height: 42px;
  justify-content: center;
  align-items: center;
  margin-right: 0;
  color: #E01616;
}

.img-w-btn-input-img-upload {
  width: calc(100% + 18px);
  height: 206px;
  margin-left: -9px;
  margin-top: -9px;
  border-radius: 10px 10px 0 0;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  flex-direction: column;
  border-bottom: 1px solid $color-d7d7d7;
  position: relative;
  .material-symbols-rounded {
    font-size: 24px;
    color:$color-body
  }
  span { 
    font-size: $font-size-sml; 
  }
}

.img-w-btn-input-sec {
  border: 1px solid #000;
  border-radius: 10px;
  padding: 10px;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  max-width: 242px;
}

.upload-image-icon{
  position: absolute !important;
  top: 140px;
  right: -20px;
  background-color: #B6BDC5 !important;
  z-index: 10;
  width: 40px;
  height: 40px;
  line-height: 1;
  
  .material-symbols-rounded {
    color: #fff !important;
    font-size: 26px;
  }
}

.product-item {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  max-width: 242px;
  border: 1px solid #000;
  padding: 10px;
  border-radius: 10px;
  overflow: hidden;
}
.product-item-title {
  display: block;
  padding-top: 10px;
  min-height: 79px;
}
.product-item img {
  width: 242px !important;
  height: 206px !important;
  object-fit: cover !important;
  margin-left: -9px;
  margin-top: -9px;
  border-radius: 10px 10px 0 0;
  border-bottom: 1px solid #d7d7d7;
}
.automation-action-btns {
  display: flex;
  flex-wrap: wrap;
  margin-top: 40px;
}
.product-list {
  display: flex;
    gap: 1.5rem;
    flex-wrap: wrap;
}

.automation-existing-header {
  display: flex;
  padding-left: 40px;
  padding-right: 40px;
  padding-bottom: 10px;
  font-size: 14px;
  color: $color-black-5d;
  &-name {
      width: 600px;
      min-width: 600px;
    }
        &-switch {
          width: 150px;
          min-width: 150px;
        }
        &-sent {
            width: 75px;
              min-width: 75px;
        }
                &-ctr {
                  width: 75px;
                  min-width: 75px;
                }
                                &-modified {
                                  width: 150px;
                                  min-width: 150px;
                                }
                                &-action {
                                  width: 100px;
                                    min-width: 100px;
                                }

}
 

.automation-existing-list {
  display: flex;
  flex-wrap: wrap;
  padding: 20px 40px 30px;
  border: 1px solid #838383;
  border-radius: 10px;
  font-size: 14px;
  align-items: flex-end;
  margin-bottom: 20px;
  &-name {
      width: 600px;
      min-width: 600px;
      strong {
        font-size: 16px;
        font-weight: 600;
      }
    }
  
    &-switch {
      width: 150px;
      min-width: 150px;
    }
  
    &-sent {
      width: 75px;
      min-width: 75px;
    }
  
    &-ctr {
      width: 75px;
      min-width: 75px;
    }
  
    &-modified {
      width: 150px;
      min-width: 150px;
    }
  
    &-action {
      width: 100px;
      min-width: 100px;
      .material-symbols-rounded {color: #E01616;}
    }
}

.automation-existing-list-name {
  display: flex;
  flex-direction: column;
 
}

.automation-existing-list-name img {
  width: 29px;
  height: 29px;
  align-items: center;
  align-self: center;
}


.automation-existing-list-name ul {
  display: inline-flex;
  margin-bottom: 0;
  list-style: none;
  gap: 0.5rem;
  li {
      padding: 5px 10px;
      background: #f6f6f6;
      border-radius: 20px;
      border: 1px solid #dbdbdb;
      line-height: 6px;
      height: 22px;
      font-size: 14px;
      list-style: none;
      
    }
}
.automation-existing-list-detail span {
  color: $color-black-787878;
  font-size: 14px;
}

.new-automation-top-back {
  display: flex;
  align-items: center;
  margin-bottom: 40px;
}


.new-automation-top-back strong a {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  border: 1px solid #838383;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  margin-right: 10px;
}

.new-automation-top-back strong a .material-symbols-rounded {
  font-size: 32px;
  align-self: center;
  color: #000;
}

.new-automation-top-back strong {
  display: flex;
  align-items: center;
}

.automation-existing-page-title {
  font-weight: normal;
  display: block;
  margin-bottom: 40px;
  font-size: 18px;
}


.automation-edit-title-input {
  border: none;
  border-bottom: 1px solid #2b2b2b;
  margin-right: 5px;
  &:focus {

    box-shadow: none;
    outline: none;
  }
}

// .automation-edit-title {
//   border: none;
//   &:hover{
//     background-color: transparent !important;
//   }
// }

.automation-edit-title {
  width: 28px;
  height: 28px;
  .material-symbols-rounded {
    font-size: 18px;
    margin-left: 0;
  }
}

.automation-phone-mockup-user-sec {
  // position: absolute;
  right: 0;
  width: 302px;
  display: flex;
    z-index: 1;
    position: relative;
    padding: 10px 20px 15px;
    margin-top: 40px;
    margin-bottom: 10px;
    border-bottom: 1px solid #85959f;
}

.automation-phone-mockup {
  position: absolute;
  right: 0;
  top: 0;
  width: 302px;
  height: 634px;
  overflow: hidden;
}

.automation-phone-mockup-user-info {
  // position: absolute;
  top: 0;
  z-index: 1;
  color: #fff;
  display: flex;
  flex-direction: column;
  left: 33px;
}

.automation-main-content {
  position: relative;
}

.automation-phone-mockup-img {
  position: absolute;
  right: 0;
  top: 0;
}

.automation-phone-mockup-user-img {
  width: 28px;
  height: 28px;
  border-radius: 50%;
  margin-right: 5px;
}

.automation-phone-mockup-user-info strong {
  font-size: 15px;
  font-weight: normal;
}

.automation-phone-mockup-user-info span {
  font-size: 12px;
  color: #85959f;
}

.automation-phone-mockup-product-detail {
  color: #fff;
  position: relative;
  display: flex;
  flex-direction: column;
  z-index: 11;
    width: 174px;
      border-radius: 10px;
      background: #2F3644;
} 

.automation-phone-mockup-product-img {
  border-radius: 10px 10px 0 0;
  width: 100%;
  
}
.automation-phone-mockup-product-desc{
  
  padding: 10px ;
}
.automation-phone-mockup-product-detail strong {  
  font-size: 13px;
  font-weight: 600;
  line-height: 1.2;
  padding-bottom: 10px;
  display: block;
}
.automation-phone-mockup-product-detail .btn {
  padding: 5px 10px;
  width: 100%;
}

.btn-grey {
  background: #434A58;
  color: #fff;
  &:hover{
    color: #434A58;
    background-color: #fff;
  }
}
.automation-phone-mockup-product-slider {
  .slick-slide  {
    // min-width: 174px;
  }
}
.automation-phone-mockup-product-slider .slick-arrow {
  width: 36px;
  height: 36px;
  border-radius: 50%;
  background-color: #434A58;
  border: 1px solid #434A58;
  position: absolute;
  font-size: 0;
  z-index: 1;
  top: 50%;
  transform: translateY(-50%);
  &:after{
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
    content: "chevron_left";
    font-family: "Material Symbols Rounded";
    font-size: 30px;
    font-weight: 200;
    line-height: 30px;
    color: #fff;
    
  }
  &.slick-disabled{
    pointer-events: none;
    opacity: 0.6;
  }
     &.slick-prev {
      left: 54px;
     }
    &.slick-next {
      right: 54px;
      left: auto;
      &:after{
        content: "chevron_right";
      }
    }
}
.automation-phone-mockup-product-slider .slick-track {
  display: flex;
  gap: 0.75rem;
}


.breadcrumb-automation {
  li {
    color: #2b2b2b;
    a{
      &:hover{
        color: #000;
      }
    }
  }
}

.popup-see-more .popup_container-inner.popup-centered {
  max-width: 32rem;
}
.popup-see-more {
  .popup-content {
    ul {
      list-style: none;
    }
  }
}
.popup-see-more .popup-centered .popup_content_container {
  max-height: 410px;
}

.reply-button.btn.reply-active {
  border: 1px solid #000;
}