.date_range_search {
    margin-bottom: 20px;
    display: flex;

    .btn-div {
        display: flex;
        align-items: flex-end;

        .btn {
            @media screen and (max-width: 575px) {
                width: 100%;
            }
        }

    }
}

.disconnect-feedback-main,
.campaign-details-main {
    margin-left: 64px
}