.clanshop-user-details {
    margin-bottom: 70px;

    .container {
        max-width: 640px;
    }

    .address-card {
        border-radius: 8px;
        padding: 20px 20px;
        box-shadow: 0px 0px 5px 5px #ebf2f7;
        margin-top: 15px;

        h3 {
            margin: 0;
            border: none;
        }
    }

    .selected {
        border-radius: 8px;
        padding: 20px 20px;
        box-shadow: 0px 0px 5px 5px #ebf2f7;
        margin-top: 15px;
        background-color: #b4b5b6;

        h3 {
            margin: 0;
            border: none;
        }
    }

    form {
        margin: 0 auto;
        border-radius: 8px;
        border: 1px solid black;
        padding: 30px 30px;
        width: 600px;



        h3 {
            text-align: center;
            border-bottom: 1px solid black;
        }

        .clanshop_wa {
            display: flex;

            .clanshopwa_send_otp {
                min-width: 125px;
            }
        }

        .preferred-time-label {
            span {
                font-size: 14px !important;
            }

        }
    }
}