.remove-influencer {
    // border: 1px solid black;
    cursor: pointer;
    padding: 5px 10px;
    border-radius: 5%;
    background-color: #9399a4;
    color: white;
    transition: all .6s;

    &:hover {
        background-color: #dd6666;
        transition: all .6s;
    }
}

.sec-add-influencer-verify .btn {
    position: absolute;
    right: 8px;
    top: 7px;
    height: 26px;
    font-size: 14px;
    padding-left: 10px;
    padding-right: 10px;
    color: $color-black-5d;
}

.add-influencer-verify-input input {
    padding-right: 92px;
}

.sec-add-influencer-verify {
    position: relative;
}


.invited-influencer-row {
    display: flex;
    align-items: center;
    gap: 2rem;
    padding: 10px 15px;
    font-size: $font-size-sml;  
    border-top: 1px solid $color-5f6573;
}

.invited-influencer-info {
    display: flex;
    flex-direction: column;
}

.invited-influencer-info {
    min-width: 150px;
}

.invited-influencer-contact-info {
    display: flex;
    flex-direction: column;
    max-width: 200px;
    overflow: hidden;
    min-width: 200px;
}

// .sec-invited-influencer-list {
//     border: 1px solid $color-5f6573;
//     border-radius: 10px;
// }

.invited-influencer-actions {
    display: flex;
    align-items: center;
    gap: 0.625rem;
    margin-left: auto;
    min-width: 100px;
}
  .invited-influencer-phone,
  .invited-influencer-name {
      font-weight: 700;
  }
  .invited-influencer-email,
  .invited-influencer-type {
      color: #6a6a6a;
  }


  .invited-influencer-deliverable ul {
      display: flex;
      gap: 0.625rem;
      padding-left: 0;
  }

  .invited-influencer-deliverable li {
      list-style: none;
      padding: 2px 10px;
      background: #f2f2f2;
      border-radius: 20px;
      font-weight: 600;
  }

  .invited-influencer-deliverable {
      width: 350px;
      display: flex;
      flex-wrap: wrap;
  }

  .custom-drawer.confirm-send-whatsapp.active {
      max-width: 800px;
  }
  .invited-influencer-chat{
    display: inline-flex;
    &.disabled {
        .icon-filled {
            color: #ddd;
        }
    }
  }

  .influencer-checkbox-disabled {
      width: 14px;
      height: 14px;
      border-radius: 2px;
      border: 2px solid #999;
      background: #999;
  }


  .selected-influencer-list-combined {
      border: 1px solid #5f6573;
      border-radius:10px;
  }

  .sec-invited-influencer-table-contact-info {
      max-width: 200px;
      overflow: hidden;
      min-width: 200px;
  }

  .sec-invited-influencer-table-checkbox {
      width: 14px;
      height: 14px;
      display: inline-flex;
  }

  .sec-invited-influencer-table {
      display: flex;
      gap: 2rem;
      display: flex;
      align-items: center;
      gap: 2rem;
      padding: 10px 15px;
      font-size: 0.875rem;
      background-color: #000;
      color: #fff;
      border-radius: 10px 10px 0 0;
  }

  .sec-invited-influencer-table-info {
      min-width: 150px;
  }

  .sec-invited-influencer-table-deliverable {
    min-width: 350px;
  }
  .sec-invited-influencer-table-actions {
      gap: 0.625rem;
      margin-left: auto;
      min-width: 100px;
  }

  .add-influencer #id_email-helper-text{
    font-size: 0.68rem !important;
    margin-top: 0px !important;
  }
    
  .add-influencer #phone-helper-text{
    font-size: 0.68rem !important;
    margin-top: 0px !important;

  }