.userbase-main,
.userbrief-main {
    margin-left: 64px;
}

.date_range_search {
    margin-bottom: 20px;
    display: flex;

    .btn-div {
        display: flex;
        align-items: flex-end;

        .btn {
            @media screen and (max-width: 575px) {
                width: 100%;
            }
        }

    }
}

.date_range_data_table_container {
    border: 1px solid rgba(0, 0, 0, 0.3);
    border-radius: 14px;
    .common-table-body {
        td {
            &:first-of-type {
                border-left: none;
            }
            &:last-of-type {
                border-right: none;
            }
        }
                tr:last-of-type td {
                    border-bottom: none;
                }
                tr:last-of-type td:first-of-type,
                 tr:last-of-type td:last-of-type {
                    border-radius: 0;
                }
    }
}