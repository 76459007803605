// $tableHeadHeight: '20px';
// $backgroundColor: #E6E6E6;
// $color-body: #2b2b2b !default;
// $color-menu-hover: #5d5d5d !default;
// $font-family-body: 'Open Sans', sans-serif !default;
// $font-size-body: 16px !default;
// $font-size-sml: 14px !default;
// $font-size-xsml: 12px !default;
// $color-primary: #00aae5 !default;
// $color-primary-hover: #36a1d1 !default;
// $backarrowColor: $color-primary !default;
// $color-secondary: deeppink !default;
// $whatsappColor: #25D366 !default;
// $errorColor: #d32f2f !default;
// $color-white: #ffffff !default;
// $width-full: 100% !default;

.influencer-detail-card {
    padding-top: 0;
    // padding: 0px;

    .inf-details-main-container {
        // border: 2px solid black;
        // min-height: 500px;

        .inf-list-section {
            border-right: 2px solid lightgray;
            // padding-right: 0px;
            // flex: 0 0 250px;
            // width: 250px;
            width: 100%;

            @include breakpoint(small) {
                width: 20%;
            }



            .search-tool {
                border-bottom: 2px solid lightgray;
                display: flex;
                align-items: center;
            }


        }

        .inf-child-section {
            padding: 0px;
            width: 80%;

            // @include breakpoint(medium) {

            //     width: calc(100% - 250px);
            // }
        }
    }
}

.inf-list {
    position: sticky;
    top: 76px;
    max-height: calc(500px - 120px);
    overflow-y: auto;
    flex-grow: 1;

    img {
        height: 40px;
        width: 40px;
        border-radius: 50%;
        border: 1px solid $color-838383;
        margin-right: .5rem;

        @include breakpoint(large) {
            height: 48px;
            width: 48px;
        }
    }

    ul {
        list-style: none;
        padding: 0px;

        li {
            // border: 2px solid black;
            // margin: 10px 0px;
            padding: 11px 10px;
            font-size: $font-size-body;
            cursor: pointer;
            display: flex;
            align-items: center;


            // span {
            //     white-space: nowrap;
            //     text-overflow: ellipsis;
            //     overflow: hidden;
            // }
            .MuiFormControlLabel-root {
                margin-left: 0;
                margin-right: 0;

                &.Mui-disabled {
                    display: none;
                }
            }
        }

        .active {
            background-color: $color-d2d2d2;
            color: $color-black;
        }
    }

}



// .infl-profile-count {
//     font-weight: bold;
//     width: 90%;
//     white-space: nowrap;
//     overflow: hidden;
//     text-overflow: ellipsis;
//     text-align: center;
// }

// .infl-profile-username {
//     color: $color-black;
//     overflow: hidden;
//     text-overflow: ellipsis;
//     white-space: nowrap;
//     max-width: 170px;
// }

// .infl-profile-name {
//     font-size: $font-size-sml;
//     color: $color-6a6a6a;
//     white-space: nowrap;
//     overflow: hidden;
//     text-overflow: ellipsis;
//     max-width: 120px;

// }

// .infl-profile-img-sec {
//     flex: 0 0 160px;
//     margin-right: 15px;
// }

// .infl-profile-img {
//     width: 48px;
//     height: 48px;
//     border-radius: 50%;
//     border: 1px solid $color-838383;
//     display: inline-flex;
//     margin-right: 10px;

//     img {
//         border-radius: 50%;
//         border: none;
//     }
// }

.selected-inf {
    border-bottom: 2px solid lightgray;
    // border: 2px solid orange;
    display: flex;
    padding: 10px 15px;
    margin-bottom: 16px;
    width: 100%;
    flex-wrap: wrap;
    // flex-direction: column;

    &-top {
        display: flex;

        ul {
            margin-bottom: 0;
            margin-bottom: 0;
            padding-left: 0;
            list-style: none;
        }
    }

    &-btm {
        display: flex;
        margin-left: auto;
        align-self: center;

        ul {
            display: flex;
            margin-bottom: 0;

            li {
                padding-left: 10px;

                span {
                    font-size: 14px;

                    &:last-of-type {
                        font-weight: 600;
                    }
                }
            }
        }
    }


    &-img {
        height: 50px;
        width: 50px;
        border-radius: 50%;
        border: 1px solid #eee;
        font-size: 2px;
        margin-right: 10px;
    }

    img {
        height: 50px;
        width: 50px;
        display: block;
        border-radius: 50%;
        border: 1px solid #dee2e6;
        font-size: 8px;
        margin-right: 5px;
    }

    &-left {
        display: flex;
    }

    &-right {
        margin-top: 15px;
        border-top: 1px solid #e2e2e2;
        padding-top: 15px;
        width: 100%;

        @include breakpoint(large) {
            margin-top: auto;
            margin-left: auto;
            width: auto;
            border-top: none;
            padding-top: 0;
        }

        ul {
            padding-left: 0;
            display: flex;
            flex-wrap: wrap;
            margin-bottom: 0;
            font-size: $font-size-sml;

            li {
                display: inline-flex;
                padding: 0 15px;
                border-right: 1px solid $border-color-card;

                &:last-of-type {
                    border-right: none;
                    padding-right: 0;
                }
            }
        }
    }
}


.inf-detail-selc-main-container {
    display: flex;
    flex-direction: column;

    @include breakpoint(small) {
        flex-wrap: wrap;

        flex-direction: row;
    }

    .selector-tab {
        background-color: $color-f2f2f2;
        border-radius: $border-radius-card 0 0 $border-radius-card;

        @include breakpoint(small) {
            flex: 0 0 172px;

        }

        position: relative;

        ul {
            list-style: none;
            margin: 0px;
            padding: 0px;
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            // align-items: center;
            overflow-x: auto;

            @media only screen and (width < 48rem) {
                display: none;

                &.dropdown-active {
                    display: flex;
                }
            }


            li {
                flex: 1;
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                padding: .5rem .875rem;

                cursor: pointer;
                font-size: $font-size-body;

                &:first-child {
                    .middle-span {
                        border-top: 1px solid $border-color-card;
                        border-bottom: none;
                    }
                }

                &:last-child {
                    margin-right: 0px;
                }

                .middle-span {
                    width: 100%;
                    font-size: ($font-size-sml - .0625rem);
                    color: $color-black-787878;
                }
            }

            .active {
                background-color: $border-campaign-detail-card;
                color: $color-black;

            }

            li {
                &:first-of-type {
                    &.active {
                        border-radius: $border-radius-card 0 0 0;
                    }
                }
            }
        }
    }

    .selector-tab-active {
        display: none;

        @media only screen and (width < 768px) {
            background: $border-campaign-detail-card;
            width: 100%;
            display: flex;
            padding: 12px 20px;
            border-radius: .625rem;
            margin-bottom: 16px;
            cursor: pointer;
            user-select: none;

            &:after {
                position: absolute;
                content: "";
                right: 16px;
                top: 12px;
                border-left: .125rem solid rgba($color-black, 0.3);
                border-bottom: .125rem solid rgba($color-black, 0.3);
                width: .875rem;
                height: .875rem;
                transform: rotate(-45deg);

            }

            &.active {
                &:after {
                    transform: rotate(135deg);
                    top: 18px;
                }
            }
        }
    }

    .selector-container {
        @include breakpoint(small) {
            width: calc(100% - 172px);

        }
    }


}

.inf-info-section {
    font-size: $font-size-sml;
}

.accordion-header {
    border-bottom: 1px solid #d2d2d2;
    min-height: 40px !important;

    .MuiAccordionSummary-content {
        margin: 0 !important;
        min-height: 40px;
    }
}

.search-tool {
    position: sticky;
    top: 16px;
    margin-bottom: 1rem;
    padding-left: 10px;
    padding-right: 10px;

    .MuiInputBase-input {
        padding-top: 10px;
        padding-bottom: 10px;
    }

    .MuiStack-root {
        width: calc(100% - 20px);
        margin: 0px 10px;
    }
}

.inf-details-main-container {
    display: flex;
    flex-wrap: wrap;
    overflow: hidden;
    // overflow-y: auto;
    max-height: 500px;

    padding: 0px 0px 5px 0px;

    @include breakpoint(small) {
        border: 1px solid $color-838383;
        border-radius: 0 $border-radius-card $border-radius-card 0;
    }

    .inf-list-section {
        flex: 0 0 100%;
        border-right: 1px solid $color-838383;
        padding-top: 1rem;
        position: sticky;
        top: 0;
        height: 500px;

        @include breakpoint(small) {
            flex: 0 0 220px;
        }

    }

    .inf-child-section {
        width: 100%;
        flex: 99999;
        position: relative;
        height: 500px;

        @include breakpoint(small) {
            width: calc(100% - 20rem);
        }


    }

    .inf-list {
        .creative-status-indicator {
            display: none;
        }
    }

}


.inf-child-section-middle {
    position: relative;

    &.chat-drawer-active {
        @include breakpoint(small) {
            margin-right: 300px;
            transition: all 0.35s;
        }
    }
}

.inf-list-btm {
    position: sticky;
    bottom: 0;
    top: calc(100% - 50px);
    width: 100%;

    .btn {
        width: 100%;
    }
}

.proposal-detail-container {
    position: relative;
    z-index: 1;
    height: 284px;
    overflow: hidden;
    overflow-y: auto;
    padding-left: 16px;
    padding-right: 16px;
}

.proposal-comment-send-box {
    position: fixed;
    bottom: 0svh;
    background: #fff;
    display: flex;
    padding: 1.5rem 1rem;
    gap: 1rem;
    border-top: 1px solid $color-838383;
    margin-top: 1.75rem;
    z-index: 3;
    left: 0;
    width: 100%;

    @include breakpoint(small) {
        position: sticky;
        bottom: -5px;
        width: auto;
        left: auto;
    }
}

.proposal-comment-send-box-icon {
    cursor: pointer;

    &:hover {
        svg {
            path {
                fill: rgba($color-black, 0.3)
            }
        }
    }
}

.proposal-comment-send-box-icon.chat-bubble {
    margin-left: auto;
}

.proposal-message-container {
    padding: 10px 5px;
}

.proposal-message-sec {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    font-size: 12px;

    .proposal-message {
        padding: 10px;
        margin: 12px 0 2px;
        background-color: $color-ededed;
        position: relative;
        border-radius: 0 10px 10px 10px;
        max-width: 90%;
        min-width: 100px;
        word-break: break-word;

        &:before {
            content: "";
            display: inline-block;
            width: 0;
            height: 0;
            border-style: solid;
            border-width: 16px 0 0 16px;
            border-color: transparent transparent transparent #ededed;
            position: absolute;
            bottom: 100%;
            left: 0;
        }
    }


    &.proposal-message-sec-alternate {
        align-items: flex-end;

        .proposal-message {
            background-color: $color-e8e8e8;
            border-radius: 10px 10px 0 10px;


            &:before {
                bottom: auto;
                top: 100%;
                left: auto;
                right: 0;
                border-style: solid;
                border-width: 0 16px 16px 0;
                border-color: transparent $color-e8e8e8 transparent transparent;
            }


        }

        .proposal-message-date.proposal-message-time {
            align-self: flex-end;
            padding-right: 30px;
        }
    }
}


.proposal-message-date {
    padding: 2px 6px;
    border: 1px solid $border-campaign-detail-card;
    border-radius: 4px;
    align-self: center;
}

.proposal-message-time {
    border: none;
    border-radius: 0;
    align-self: flex-start;
    padding-right: 25px;
}



.proposal-message-container {
    // height: calc(500px - 56px);
    height: calc(100% - 140px);
    overflow-y: scroll;
    margin-bottom: 30px;
    padding: 10px;

    @include breakpoint(small) {
        height: calc(100% - 76px);
    }
}

.proposal-message-send-sec {
    border-top: .0625rem solid $color-838383;
    position: absolute;
    bottom: 0;
    padding: 0;
    background: #fff;
    z-index: 1;
    width: 100%;

    .error {
        position: absolute;
        bottom: 10px;
        left: 10px;
    }
}

.proposal-message-send {
    position: absolute;
    right: 1rem;
    background-color: transparent;
    color: $color-black;
    border: none;
    padding: .5rem;
    top: 1.25rem;
    font-size: $font-size-sml;

    &:hover {
        color: rgba($color-black, 0.7);
    }

}

.proposal-message-input.MuiFormControl-root {
    .MuiInputBase-root {
        &.Mui-focused {
            &.MuiInputBase-root-MuiOutlinedInput-root {
                border: none;

            }
        }
    }
}

.proposal-message-input {
    width: 100%;
    font-size: $font-size-sml;

    &.MuiFormControl-root {
        .MuiInputBase-root {
            &.Mui-focused {
                .MuiOutlinedInput-notchedOutline {
                    border: none;
                }
            }
        }
    }

    input {
        padding: 1.75rem 5rem 1.75rem .875rem;
        font-size: $font-size-sml;
    }

    fieldset {
        border: none;
    }
}

.proposal-message-send-sec form {
    display: flex;
    align-items: center;
    gap: 10px;
}

.proposal-message-attachment {
    padding-left: 10px;
    padding-right: 10px;
}

.proposal-dur-info {
    font-size: 12px;
    margin-bottom: 10px;
    gap: 10px;
    display: flex;
    margin-top: 30px;

    .proposal-badge {
        padding: 4px 8px;
        background: #f2f2f2;
        border-radius: 4px;
        line-height: 1;
        font-weight: normal;
    }
}

.prop-total-cost {
    padding: .625rem .625rem;
    background: #ededed;
    font-size: .875rem;
    border-radius: .5rem;
    min-width: 5.25rem;
    display: inline-block;
    text-align: left;
    font-weight: 600;
    margin-left: -0.625rem;
}

.show-proposal-action-btns {
    position: absolute;
    top: 2px;
    background: #fff;
    left: 42px;
    opacity: 0;
    visibility: hidden;

    &.show {
        opacity: 1;
        visibility: visible;
    }
}

.inf-list-all-checkbox.custom-checkbox {
    margin-left: 0;
    margin-bottom: 0;
    margin-right: 0;
}

.proposal-action-btn.active .material-symbols-rounded {
    font-variation-settings: "FILL" 1, "wght" 300, "GRAD" 0, "opsz" 24;
}



.proposal-comments {
    padding-top: 1.25rem;
    padding-bottom: 1.25rem;

    span {
        font-size: $font-size-sml;
    }
}

.infl-proposal-inner-container .proposal-comments {
    padding-left: 1.25rem;
    padding-right: 1.25rem;
}

.proposal-message-container {
    flex-direction: column-reverse;
    display: flex;
}

.creative-detail .proposal-message-container {
    margin-bottom: 0;
    height: calc(100% - 56px);

}



.brand_creative_main_container {
    .inf-list-section {
        flex: 0 0 300px;
    }

    .inf-list ul li .campaign-legend li {
        margin-left: 8px;
        width: 20px;
        height: 20px;
        padding: 0;
        margin-right: 0;
    }

    .inf-name-info {
        max-width: 125px;
        display: inline-flex !important;
        flex-wrap: wrap;
        overflow: hidden;

        .inner-scrolling-text-container {
            line-height: 1.2;
        }

        .inf-name-username {
            display: none;
        }
    }

    .inf-details-main-container {
        .creative-status-indicator {
            margin-left: auto;
            display: flex;
        }

        .inf-list-all-sec {
            display: none;

        }
    }

    .inf-list ul li .campaign-legend li:after {
        width: 20px;
        height: 20px;
        line-height: 20px;
    }

    .inf-name-info span.inf-name-insta {
        max-width: 100%;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
    }

}


.inf-list-all-sec {
    padding-left: 10px;
}