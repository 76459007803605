.dashboard-infl {
  // padding-top: 1.25rem;

  .page-title {
    span {
      font-size: $font-size-title-large-mobile;
      padding-bottom: 1rem;
      font-weight: 700;
      color: $color-body;
      line-height: 1;

      @include breakpoint(small) {
        font-size: $font-size-title-large;
      }
    }
  }

  &-info-sec {
    padding-left: 0.25rem;
    padding-right: 0.25rem;

    @include breakpoint(small) {
      padding-left: 2.625rem;
      padding-right: 1.875rem;

    }
  }

  .search-sec {
    display: flex;
    justify-content: space-between;
    grid-gap: 0.625rem;
    flex-direction: column;

    @include breakpoint(small) {
      margin-bottom: 1.5rem;
      display: flex;
      justify-content: space-between;
      align-items: center;
      flex-direction: row;
    }

    strong {
      font-size: $font-size--1_25rem;

      @include breakpoint(xsml) {
        font-size: $font-size--1_5rem;
      }
    }

    .search-container {
      position: relative;
      width: 100%;
      justify-content: flex-end;
      display: flex;transition: all 0.35s cubic-bezier(0.075, 0.82, 0.165, 1);

      .search-plan-input {
        // @media (width < 48rem) {
        //   position: absolute;
        //   right: 50px;
        //   width: calc(100% - 50px) !important;
        // }

        width: auto !important;
        position: absolute;
        right: 0;
        top: 0;
        z-index: -1;
        visibility: hidden;
        opacity: 0;
        transition: all 0.35s cubic-bezier(0.075, 0.82, 0.165, 1);
        .MuiOutlinedInput-notchedOutline {
          border-radius: 30px;
        }
      }

      .btn {
        &.active {
          z-index: 1;
          position: relative;
transition: all 0.35s cubic-bezier(0.075, 0.82, 0.165, 1);
          +.search-plan-input {
            width: 100% !important;
            z-index: 0;
            visibility: visible;
            opacity: 1;
            transition: all 0.35s cubic-bezier(0.075, 0.82, 0.165, 1);
            @media (width > 47.94rem) {
              width: 300px !important;
              transition: all 0.35s cubic-bezier(0.075, 0.82, 0.165, 1);
            }

            .MuiInputBase-input {
              padding-right: 85px;
              padding-left: 20px;
            }
          }
        }
      }

      .clear-search-result {
        position: absolute;
        font-size: $font-size-sml;
        color: $color-black !important;
        inset: 100% 0 0 auto;
        min-width: 135px;
      }
    }
  }

  .search-filter {
    display: flex;
    justify-content: flex-end;
    // width: 100%;
    gap: 0.625rem;
    position: relative;

    // @include breakpoint (small) {
    //   grid-template-columns: 1fr 1fr;
    //   display: inline-grid;
    //   justify-content: space-between;
    //   width: auto;
    // }

    .btn {
      height: 40px;
      width: 40px;
      padding-left: 0;
      padding-right: 0;
      min-width: 40px;

      // @include breakpoint (small) {
      //   min-width: 80px;
      //   width: 100%;
      //   padding: 0.38rem 1rem;
      // }

      &.active {
        color: $color-body;
        right: 4px;
        background-color: transparent;
        border-color: transparent;
        width: 36px;
        height: 36px;
        min-width: inherit;
        top: 2px;
        padding: 0;
        display: flex;
        justify-content: center;
        align-items: center;

        &:hover {
          background: rgba($color-black, 0.03);
        }

        &:after {
          content: "\F659";
          font-family: "bootstrap-icons";
          font-size: $font-size-xsml;
          display: none;
        }
      }
    }
  }

  .dashboard-card {
    &:nth-of-type(1) {
      margin-top: 1.25rem;
      @include breakpoint(small) {
        border-top: none;
        margin-top: 0;
      }
    }
        .inf-campaign-card-container {
          .inf-description {
            @media (width < 768px) {
              display: none;
            }
          }
        }
      @media (width < 768px) {
        
        border: 1px solid #d7d7d7;
        padding: 1.25rem;
        border-radius: $border-radius-card;
        box-shadow: 0 2px 5px rgba($color-black, 0.3);

        .dashboard-card-left {
          position: relative;
        }
        .campaign-cate-detail-mobile {
          position: absolute;
          right: 10px;
          top: 0px;

        }
        .badge-primary-outline {
        background-color: rgba($color-white, 0.8);
        }
        

        
      }

     
  }
 @media (width < 768px) {
    .dashboard-card-container  {
      display: flex;
      flex-direction: column;
      gap: 1rem;
  
    }
  }
  .inf-description {
    ol {
      display: flex;
      flex-direction: column;
    }
  }
  .campaign-legend {
    margin-left: 0;
    @include breakpoint(small) {
      margin-left: auto;
    }

  }
}

.dashboard-card-title-redirect-link {
  color: $color-8c8c8c;
  cursor: pointer;

  &:hover {
    color: $color-black;
  }
}

.dashboard-card {
  display: grid;
  grid-template-columns: 1fr;
  padding: 1.5rem 0.25rem;
  grid-row-gap: 1rem;
  // margin-bottom: 25px;
  border-top: 1px solid $border-campaign-detail-card;
  align-items: flex-start;


  @include breakpoint(small) {
    grid-template-columns: 170px 1fr;
    padding: 1.5rem 2.625rem;

  }

  @include breakpoint(small) {
    grid-template-columns: 232px auto;
  }

  &-left {
    border-radius: $border-radius__10;
    height: 11.25rem;
    display: flex;
    justify-content: center;
    align-items: center;
    @include breakpoint(small) {
      margin-right: 1.5rem;
    }

    @include breakpoint(small) {
      margin-right: 3.125rem;
    }

    border: 1px solid $border-campaign-detail-card;
    max-height: 180px;
    overflow: hidden;
    background-color: $color-white;

    > img {
      max-width: 100%;
      // width: 100%;
      // height: 100%;
      max-height: 176px;
      object-fit: contain;
      border-radius: $border-radius__10;
    }
  }

  &-title {
    font-size: $font-size--1_5rem;
    font-weight: 600;
  }


  &-right {
    display: flex;
    justify-content: flex-start;
    grid-gap: .625rem;
    flex-direction: column;

    &-top {
      display: grid;
      grid-template-columns: auto;
      grid-column-gap: 1.25rem;


      @include breakpoint(small) {
        grid-template-columns: 1fr auto;
        align-items: center;
      }

      &-left {
        justify-self: flex-start;
        display: grid;

        .badge {
          justify-self: flex-start;

          // @media (width > 767px) {
          //   visibility: hidden;
          //   opacity: 0;
          //   height: 0;
          //   margin-top: 0 !important;
          //   margin-bottom: 0 !important;
          //   padding: 0 !important;

          // }
        }
      }

      &-right {
        justify-self: flex-start;


        .badge {
          // @media (width < 768px) {
          //   visibility: hidden;
          //   opacity: 0;
          //   height: 0;
          //   margin-top: 0 !important;
          //   margin-bottom: 0 !important;
          //   padding: 0 !important;

          // }
        }
      }
    }
  }

  &:hover {
    cursor: pointer;
  }

  &.active:hover,
  &:hover {
    @media (width > 47.9375rem) {
      background-color: $dashboard-card-background-color-hover;
    }

  }

  &.active {
    background-color: $dashboard-card-background-color-active;
  }
}


.dashboard-card-camp-dur {
  .proposal-dur-info {
    display: grid;
    grid-template-columns: auto;
    grid-auto-flow: column;
    grid-gap: 0.625rem;
    margin-top: 0;

    @include breakpoint(medium) {
      grid-template-columns: 160px 160px auto;
      grid-gap: 1.825rem;
    }


  }

  .infl-det-list {
    display: grid;
    grid-auto-flow: column;
    grid-template-columns: 30px auto;
  }
}


.filter-content-group {
  gap: 1.25rem;
  &.input-checkbox-box-container .input-checkbox-box {
    @media (width < 36rem) {
      min-width: inherit;
        }
  }

  @media (width < 36rem) {
    // border: 1px solid $border-campaign-detail-card;
    // border-radius: 4px;
  }

  &.wo-border {
    .filter-content-outline {
      border: none;
      flex-direction: row;
      justify-content: flex-start;
      padding: 0;
      margin-left: 0;
      margin-right: 0;
      flex: 0 0 calc(50% - 0.625rem);

      &:first-of-type {
        border-left: none;
      }

      span {
        &.Mui-checked:after {
          background: transparent;
        }
      }
    }

    // span.MuiCheckbox-root {
    //   &::before {
    //     content: "";
    //     border: 1px solid #000;
    //     width: 1.5rem;
    //     height: 1.5rem;
    //     border-radius: 4px;
    //     margin-right: 5px;
    //   }

    //   .MuiSvgIcon-root {
    //     opacity: 0;
    //     visibility: hidden;
    //     width: 0;
    //     height: 0;
    //   }

    //   &.Mui-checked {
    //     &:before {
    //       background-color: $color-black;
    //     }

    //     &::after {
    //       content: "";
    //       position: absolute;
    //       content: "";
    //       background: transparent;
    //       width: 14px;
    //       height: 8px;
    //       left: 0;
    //       top: 6px;
    //       z-index: 99;
    //       border-left: 2px solid $color-white;
    //       border-bottom: 2px solid $color-white;
    //       border-radius: 0;
    //       right: 0;
    //       left: 5px;
    //       transform: rotate(-45deg);
    //     }
    //   }
    // }
  }


}



.filter-body {
  display: flex;
  border-bottom: 1px solid #dee2e6;
  flex-direction: column;
  font-family: $font-family-body;
  padding-bottom: 1.5rem;
  margin-bottom: 0rem;


  >span {
    margin-bottom: 1rem;
    display: flex;
    align-self: flex-start;
    margin-top: 1.5rem;
  }

  &:last-of-type {
    margin-bottom: 0;
    border-bottom: none;
  }

  .MuiTouchRipple-root {
    display: none;
  }
}

.drawer-filter {
  .MuiDrawer-backdrop {
    background-color: rgba(0, 0, 0, 0.6);
    backdrop-filter: none;

  }

  &.MuiDrawer-root {
    --Drawer-verticalSize: clamp(auto, 49%, 100%);
  }

  .MuiModalClose-root {
    top: 15px;
  }

  .MuiDrawer-content {
    max-width: 600px;
    margin: 0 auto;
    left: 0;
    right: 0;
    // transform: translateY(-50%);
    transition: transform 0.35s ease;
    margin-bottom: 3rem;
    border-radius: 8px;

    h2.MuiDialogTitle-root {
      font-size: $font-size-sml;

    }
  }
}

.custom-checkbox {
  span.MuiFormControlLabel-label {
    user-select: none;
  }

  span.MuiCheckbox-root {
    // margin-right: 0.25rem;

    &::before {
      content: "";
      border: 1px solid #000;
      width: 1.5rem;
      height: 1.5rem;
      border-radius: 0.25rem;
      margin-right: 0.5rem;
    }

    .MuiSvgIcon-root {
      opacity: 0;
      visibility: hidden;
      width: 0;
      height: 0;
    }

    &.Mui-checked {
      &:before {
        background-color: $color-black;
      }

      &::after {
        content: "";
        position: absolute;
        content: "";
        background: transparent;
        width: 14px;
        height: 8px;
        left: 0;
        top: 6px;
        z-index: 99;
        border-left: 2px solid $color-white;
        border-bottom: 2px solid $color-white;
        border-radius: 0;
        right: 0;
        left: 5px;
        transform: rotate(-45deg);
      }
    }
  }
}



.share-sec-container {
  display: flex;
  gap: 1rem;
  flex-wrap: wrap;
  padding: 2rem 0;

  // @media (width < 35.94em) {
  //   flex-direction: column;
  //   flex-wrap: nowrap;

  // }

  .share-icons {
    flex: 0 0 calc(50% - 0.5rem);
    border: 1px solid $border-campaign-detail-card;
    border-radius: 4px;

    &:hover {
      background-color: #f2f2f2;

      button {
        background-color: #f2f2f2;

      }
    }

    button {
      padding: 1rem !important;
      display: block;
      width: 100%;
      text-align: left;
      background: #fff;
      border: none;
      border-radius: 4px;
    }

    svg {
      margin-right: 8px;
    }
  }
}

.wave {
  animation-name: wave-animation;
  animation-duration: 2.5s;
  animation-iteration-count: 2;
  transform-origin: 70% 70%;
  display: inline-block;
}

@keyframes wave-animation {
  0% {
    transform: rotate(0.0deg)
  }

  10% {
    transform: rotate(14.0deg)
  }

  20% {
    transform: rotate(-8.0deg)
  }

  30% {
    transform: rotate(14.0deg)
  }

  40% {
    transform: rotate(-4.0deg)
  }

  50% {
    transform: rotate(10.0deg)
  }

  60% {
    transform: rotate(0.0deg)
  }

  100% {
    transform: rotate(0.0deg)
  }
}