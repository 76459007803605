.popup_container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    // position: fixed;
    z-index: 99;
    min-height: 100vh;
    top: 0;
    left: 0;
    width: 100%;
    transform: translateY(1000%);
    transition: all 0.35s ease;
    height: 100vh;
    overflow-y: auto;
    margin: 0 auto;
    // background-color: rgba(0, 0, 0, 0.6);
}

.popup_container_content {
    position: relative;
    overflow: hidden;
    overflow-y: auto;
    border-radius: $border-radius__10;
    max-height: clamp(31.25rem, 80vh, 100vh);
    // max-height: 90vh;
    // max-height: 90vh;
}

.popup_content_container {
    // position: 'absolute';
    // top: '50%';
    // left: '50%';
    // transform: 'translate(-50%, -50%)';
    // border: '.125rem solid #000';
    background-color: #fff;
    padding: 2rem 2rem 1rem;
}

.popup_container-inner {
    position: relative;
    background: #fff;
    border-radius: $border-radius__10;
    &.popup-centered {
        text-align: center;
        
    }
}
.popup-centered {
    .popup-close {
        position: absolute;
        right: 1rem;
        top: 0.5rem;
        z-index: 1;
    }
    .popup_content_container {
        padding-top: 3.75rem;
        padding-bottom: 2rem;
        display: flex;
        flex-direction: column;
    }
    .popup-title {
        padding-bottom: 1rem;
        font-size: $font-size-large;
    }
    .popup-content {
        font-size: $font-size-sml;
        > strong {
            font-weight: 600;
        }
        ol {
            margin-bottom: 2rem;
        }    
    }

}

.popup-header {
    position: sticky;
    top: 0;
    z-index: 9;
    background: $color-white;
    width: 100%;
    padding: 16px 32px;
    border-radius: $border-radius__10 $border-radius__10 0 0;
    border-bottom: .0625rem solid $border-campaign-detail-card;
    display: flex;
    align-items: center;
    flex-wrap: wrap;


    // .MuiIconButton-root {
    //     top: 24px;
    //     right: 32px;
    // }
    strong {
        font-size: $font-size-body;
        font-weight: normal;
    }

    .popup-close {
        margin-left: auto;
    }
}

.MuiModal-root {
    .popup_container-inner {
        width: 100%;
    }

    &.modal-large {
        .popup_container-inner {
            max-width: 50rem;
        }
    }

    &.modal-xlarge {
        .popup_container-inner {
            max-width: 75rem;
        }
    }

    &.modal-medium {
        .popup_container-inner {
            max-width: 37.5rem;
        }
    }

    &.modal-small {
        .popup_container-inner {
            max-width: 22.5rem;
        }
    }


}


.popup-footer {
    position: sticky;
    bottom: 0;
    padding: 24px 32px;
    margin-left: -32px;
    width: calc(100% + 64px);
    margin-top: 48px;
    border-top: .0625rem solid $border-campaign-detail-card;
    background: $color-white;
    z-index: 1;
}

.creative-popup {
    position: fixed;

    top: auto !important;

    height: auto;
    // max-height: 90vh;
    max-height: calc(100vh - 25vh);
    overflow: hidden;
    overflow-y: auto;
    background: #fff;
    border-radius: 1rem 1rem 0 0;
    // transform: scale(0) translateY(100%);
    // transition: all 0.5s cubic-bezier(0.68, -0.6, 0.32, 1.6);
    // transform-origin: center bottom;

    // &[aria-hidden="true"] {
    //     transform: scale(1) translateY(0%);
    //     transition: all 0.5s cubic-bezier(0.68, -0.6, 0.32, 1.6);

    // }

    .popup_container {
        justify-content: flex-start;

        background: #fff;
        height: 100%;
        min-height: inherit;
        border-radius: 1rem 1rem 0 0;
        overflow: initial;

    }

    .popup-header {
        border-radius: 1rem 1rem 0 0;
        box-shadow: none;
        padding: 0;

        .popup-close {
            position: absolute;
            right: 2rem;
            top: 1rem;
        }
    }

    .popup_container-inner {
        width: 100%;
        background-color: transparent;

    }

    .modal-content {
        border: 1px solid $color-bcbcbc;
        border-radius: 0;
    }

    .popup_container_content {
        overflow: inherit;
        // max-height: clamp(31.25rem, 90vh, 100vh);
        // min-height: calc(100vh - 100px);
        max-height: inherit;
        min-height: inherit;
    }

    .creative-detail {
        padding-bottom: 2.5rem;

        @media (width < 48rem) {
            display: flex;
            flex-direction: column;
        }

    }

    .modal-dialog-scrollable {
        .modal-body {
            background-color: $color-white;
            // border: .0625rem solid $border-creative-card;
            border-bottom: none;

            @media (width < 48rem) {
                max-height: 30vh;
            }

            @include breakpoint(small) {

                min-height: 20.5rem;
                max-height: 28.125rem;

            }
        }
    }

    .infl-profile-info {
        padding: 0;
        margin-bottom: 0;
        background-color: transparent;
        position: static;
        overflow: inherit;
    }

    .infl-profile-count {
        width: auto;
    }
}


.creative-img-popup {
    .popup_container {
        width: 100%;
        height: 100%;
        overflow-y: auto;
    }

    .popup_container-inner {
        height: 100%;
        width: 100%;
    }

    .popup-header {
        padding: 0;

        .popup-close {
            position: absolute;
            right: 15px;
            top: 15px;
        }
    }

    .selected-creative {
        height: auto;
    }

    .popup_container_content {
        height: auto;
        max-height: inherit;
        border-radius: 0;
    }

    .creative-detail {
        .creative-detail-img {
            img.selected-creative {

                max-height: inherit;
                width: auto;
                max-width: initial;

                @include breakpoint(small) {
                    height: auto;
                }
            }
        }
    }
}

.creative-popup .MuiModal-backdrop {
    background: transparent;

}

.bodyActive {
    position: relative;

    &::after {
        background: rgba(0, 0, 0, 0.5);
        content: "";
        position: absolute;
        width: 100%;
        height: 100%;
        z-index: 999;
        top: 0;
        left: 0;
    }

}

.popup {
    margin: 0 auto;
    max-width: 62.5rem;

    &.popup-sm {
        max-width: 31.25rem;
    }

    .popup_content_container,
    .popup_container-inner {
        border-radius: 4px;
    }
}

.MuiModal-open {
    .popup_container {
        transition: all 0.35s ease;
        transform: translateY(0%);
    }
}


.guide-to-connect-step li {
    display: inline-flex;
    flex-direction: column;
    flex: 0 0 100%;
    margin-bottom: 20px;
    gap: 0;
}

// .guide-to-connect-step li {
//     display: flex;
//     flex: 0 0 calc(50% - 20px);
//     flex-direction: column;
//     gap: 0.35rem;
//     padding-right: 1rem;
// }

.guide-to-connect-step {
    // display: flex;
    /*! flex-direction: column; */
    flex-wrap: wrap;
    padding-left: 0;
}

// .guide-to-connect-step li:nth-of-type(2) {
//     border-right: 1px solid #000;
//     margin-right: 30px;
//     margin-bottom: 0;
//     justify-content: inherit;
// }

// .guide-to-connect-step li:nth-of-type(3) {
//     margin-right: 0;
// }

.guide-to-connect-step {
    h6 {
        font-size: 16px;
        font-weight: 600;
    }

    p {
        font-size: 14px;
        margin-bottom: 0;
    }

    .MuiButtonBase-root.icon-verified {
        position: static;
    }
}

.show-welcome .popup-header{
padding: 0px;
border-bottom:0px;
}
.show-welcome .popup-close{
    position: absolute;
    top: 20px;
    right: 30px;
}
.show-welcome{
   background: url(../../../assets/images/banner-bg.png);

}

.popup-drawer.creative-drawer-popup {
    .MuiPaper-elevation {
        max-width: 420px;
        @media (width < 768px){
            width: 100%;
            max-width: 100%;
            height: 100%;
        }
    }
    .d-flex.p-3 {
        padding: 0 !important;
    }
    .container {
        padding: 0 1.5rem 0rem;
        @media (width > 767px) {
            padding: 0 1.5rem 1.5rem;
        }
    }
}
.popup-talent-verify {
    max-width: 600px;
    margin: 0 auto;
}
.popup-instagram-automation {
    &.modal-small {
        .popup_container-inner {
            max-width: 27.5rem;
        }
    }
}