.creative-status {
  position: absolute;
  top: -8px;
  right: -12px;
  padding: 5px 10px;
  border-radius: 0;
  font-size: 0;
  color: white;
  z-index: 1;
  width: 20px;
  height: 20px;

  &.Rejected {
    background-color: #FFCACA;

  }

  &.Approved {
    background-color: #caffe6;

  }

  &.Pending {
    background-color: #FFEEB2;
    color: $color-body;

  }
}

.creative-card {
  display: flex;
  width: 100%;
  border: 1px solid $border-color-card;
  border-radius: $border-radius-card;
  position: relative;
  aspect-ratio: 1/1;

  &.Rejected {
    border: 2px solid #FFCACA;

  }

  &.Approved {
    border: 2px solid #caffe6;

  }

  &.Pending {
    border: 2px solid #FFEEB2;

  }

  video {
    height: 100%;
    object-fit: contain;
    width: 100%;

    border-radius: $border-radius-card;


  }

  .icon-video {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    font-size: 44px;
    cursor: pointer;
  }

  .creative_size {
    max-width: 100%;
    height: 100%;
    object-fit: contain;
    cursor: pointer;
    border-radius: $border-radius-card;
    width: 100%;
  }

  .creative-date {
    position: absolute;
    top: 10px;
    right: 15px;
    z-index: 1;
  }

}

.selectedCreative {
  &.active {
    z-index: 10;
    width: calc(100% - 0px);

    @include breakpoint(small) {
      width: calc(100% - 244px);
    }

    @include breakpoint(medium) {
      width: calc(35% - 2.5rem);
    }
  }

  .selected-creative-img {
    margin: 10px auto;
    max-width: 250px;
  }

  video {
    margin: 0 auto;
  }
}

.creative-status-indicator {

  .campaign-legend li::after {
    content: attr(data-attr);
    text-align: center;
    line-height: 16px;
    font-size: $font-size-xsml;
  }
}