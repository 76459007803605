$whatsappColor: #25d366 !default;
$font-size-sml: 14px !default;

.__wa_column {
  position: relative;

  .__button-influender {
    right: 12px !important;
  }

  .__button {
    position: absolute;
    width: max-content;
    right: 10px;
    height: 37px;
    z-index: 2;
    background-color: $whatsappColor;
    padding-left: 10px;
    padding-right: 10px;
    text-transform: none;
    font-size: $font-size-sml;
    top: 1px;

    .css-1d6wzja-MuiButton-startIcon {
      margin-right: 4px;
    }
  }
}

.verify-btns {
  .__button-influender {
    right: 1px !important;
  }

  .__button {
    height: 38px;
    box-shadow: none;
  }
}

.add-cancel-btns {
  display: flex;
  justify-content: flex-end;
}
.popup-drawer.drawer-infl-dashboard.drawer-add-infl .infl-signup-profile-card.MuiPaper-elevation{
  width: 100%;
}

.add-influencer .MuiFormHelperText-root {
  margin-top: -2px;
}