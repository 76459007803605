.mostOutterDivBrand {
  width: 100%;

  .talents-data {
    .contentDivLeftLeftImage {
      width: 42px;
      height: 42px;
      border-radius: 50px;
    }

    .talent-icons {
      cursor: pointer;
    }
  }

  // .contentContaainerDivBrand {
  //   max-width: 1280px;
  //   margin: 0 auto;

  //   .contentContaainerDivBrandHeading {
  //     display: flex;
  //     justify-content: space-between;
  //     align-items: center;
  //     padding-bottom: 20px;

  //     .contentContaainerDivBrandHeadingDashboard {
  //       font-weight: 700;
  //       font-size: 22px;
  //     }
  //   }

  //   .contentDivBrand {
  //     display: flex;
  //     justify-content: space-between;
  //     border-radius: 10px;
  //     background-color: white;
  //     margin-bottom: 10px;
  //     border: 1px solid #e6e6e6;

  //     .contentDivLeft {
  //       display: flex;
  //       align-items: center;
  //       justify-content: space-between;

  //       .contentDivLeftLeft {
  //         display: flex;
  //         align-items: center;

  //         .contentDivLeftLeftImage {
  //           width: 42px;
  //           height: 42px;
  //           border-radius: 50px;
  //         }
  //       }

  //       .contentDivLeftRight {
  //         // padding: 10px 0;
  //         padding: 0px;
  //         display: flex;

  //         .contentDivLeftRightName {
  //           font-weight: 600;
  //           padding: 0 50px;
  //           display: flex;
  //           align-items: center;
  //           font-size: 16px;
  //         }

  //         .inboxAndCampaignDiv {
  //           display: flex;

  //           .contentDivLeftRightInbox {
  //             padding: 10px 10px;
  //             border-left: 1px solid rgb(238, 237, 237);
  //             border-right: 1px solid rgb(238, 237, 237);
  //             font-size: 13px;
  //             display: flex;
  //             align-items: center;

  //             .contentDivLeftRightInboxSpan {
  //               padding-left: 10px;
  //             }
  //           }

  //           .contentDivLeftRightCampaigns {
  //             padding: 0 10px;
  //             font-size: 13px;
  //             display: flex;
  //             align-items: center;
  //           }
  //         }

  //         .contentDivLeftRightPremium {
  //           padding: 0 10px;
  //           color: rgb(108, 204, 108);
  //           font-size: 12px;
  //           display: flex;
  //           align-items: center;

  //           .contentDivLeftRightPremiumSpan {
  //             padding-right: 10px;
  //             border-right: 1px solid rgb(108, 204, 108);
  //           }

  //           .contentDivLeftRightPremiumDaysSpan {
  //             padding-left: 10px;
  //           }
  //         }

  //         @media only screen and (max-width: 980px) {
  //           display: flex;
  //           flex-direction: column;

  //           .contentDivLeftRightName {
  //             padding: 0 10px;
  //           }
  //         }
  //       }
  //     }

  //     .contentDivRight {
  //       display: flex;
  //       align-items: center;

  //       .contentDivRightUpdateDelete {
  //         // padding: 10px 0;
  //         display: flex;
  //         align-items: center;
  //         padding: 0px;

  //         .contentDivRightUpdateDeleteUpdate {
  //           border-right: 1px solid rgb(238, 237, 237);
  //           padding: 6px 15px;
  //         }

  //         .contentDivRightUpdateDeleteDelete {
  //           padding: 6px 15px;
  //         }
  //       }
  //     }
  //   }
  // }
}

.MuiButtonBase-root {
  padding: 0px;
  margin-right: 10px;
}