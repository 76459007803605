.crud-page.common-signup-page .main-content-sec {
  margin-top: 100px;
}

.adminDashboardCrudMostOutterDiv {
  width: 100%;

  .adminDashboardCrudContentOutterDiv {
    // max-width: 1280px;
    padding: 10px 0;
    background-color: white;
    padding-left: 20px;
    padding-right: 20px;
    border-radius: 10px;
    // margin: 0 auto;
    margin-left: 64px;

    .adminDashboardCrudContentDiv {
      // .adminDashboardCrudToggleButtonsDiv {
      //   .MuiTab-root.Mui-selected {
      //     color: #00aae5;
      //   }
      //   .MuiTabs-indicator {
      //     background-color: #00aae5;
      //   }
      // }

      .createUserDiv {
        background-image: url("https://app.clanconnect.ai/static/media/banner-bg.d20fbf834af018ccc34c.png");
        display: flex;
        align-items: center;
        justify-content: center;
        height: 80vh;

        // .buttonsSpan {
        //   display: flex;
        //   width: 420px;
        //   justify-content: space-between;

        //   @media (max-width: 440px) {
        //     flex-direction: column;
        //     align-items: center;
        //     width: 250px;
        //     height: 120px;
        //     justify-content: space-between;
        //   }

        //   // .button {
        //   //   border-radius: 14px;
        //   //   width: 180px;
        //   //   text-transform: none;
        //   //   font-size: 22px;
        //   //   background-color: #252525;
        //   //   color: white;
        //   //   border: 1px solid #252525;

        //   //   &:hover {
        //   //     background-color: white;
        //   //     color: #252525;
        //   //   }
        //   // }
        // }
      }

      .deleteUserForm {
        display: flex;
        align-items: center;
      }
    }
  }
}