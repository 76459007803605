$color-primary: #00aae5 !default;

.create_plan_top_buttons{
    display: flex;

    .campaign-legend li.campaign-legend_pending:after{
        background-color: #FFEEB2;
    }

    .campaign-legend li.campaign-legend_rejected::after {
        background-color: rgb(255, 202, 202);
    }

    .campaign-legend li.campaign-legend_approved::after {
        background-color: rgb(202, 255, 202);
    }

    @media only screen and (max-width: 768px) {
        display: block;

        .btn{
            margin-bottom: 10px;
        }
    }
}

.pln_camp_table_body{

    .deleted{
        background-color: rgba(234, 0, 0, 0.4);
    }

    .icons{
        transition: .6;
        &:hover{
            color: $color-primary;
        }
    }

}

.table-plan-title-non-draft{
    cursor: pointer;
    color: #00aae5,
}

.table-plan-title-draft{
    cursor: default;
}