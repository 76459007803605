$color-primary: #00aae5 !default;


.shipping-main-container {

    .add-address-card {
        .add-button {
            border: 1px solid $color-primary;
            display: flex;
            justify-content: center;
            align-items: center;
            height: 200px;
            width: 100%;
            cursor: pointer;
            font-size: larger;
            font-weight: bold;
            color: $color-primary;

            &:hover {
                background-color: $color-primary;
                color: white;
            }



        }
    }

    .save-add-can-buttons {
        .btn {
            margin-right: 10px;
        }
    }

    .inputFieldSp {
        // margin: 10px 5px;
        padding: 0px;
    }


}

// .popup-drawer.shipping-drawer .MuiPaper-elevation {
//     width: 30%;
//     border-radius: 10px;
// }



// ._influencer_shipping_address{
//     border: 1px solid #00aae5;
//     color: #00aae5;
//     // display: inline;
//     li{
//         padding: 15px;
//     }
//     .li:hover{
//         background: #00aae5;
//     }
// }
// .tabs li.active {
//     // background: #00aae5;
//     color: black

// }

// // .li:hover{
// //     background: #00aae5;
// // }
// .tabs {
//     display: flex;
//     justify-content: start;
//     align-items: center;
//     padding-left: 5px;
//     // margin: 0px;
// }


// .tabs li {
//     display: flex;
//     border-radius: 6px;
//     cursor: pointer;
//     width: 230px;
//     height: 230px;
//     justify-content: center;
//     align-items: center;
// }

// .tab_coll{
//     border-top: 1px solid black;
//     padding: 10px;
//     background: #fff;
//     min-height: 190px;
// }

// .pincode{
//     appearance: none;
// }