.accordion {
    background: transparent !important;
    box-shadow: none !important;
    border: 1px solid $border-color-card;
    margin-bottom: 16px;
    border-radius: 8px !important;

    &.Mui-expanded {
        .accordion-title {
            background-color: #252525;
            color: $color-white;
            font-weight: 600;

            &.Mui-expanded {
                min-height: 48px !important;
                border-radius: 8px 8px 0 0;
            }

            .MuiAccordionSummary-content {
                margin: 12px 0;
            }

            .MuiAccordionSummary-expandIconWrapper {
                color: #fff;
            }
        }
    }

    &:before {
        height: 0 !important;
    }

    .accordion-title {
        padding: 0 16px;
    }

    .accordion-content {
        padding: 8px 16px 10px;
    }
}

.accordion-container {
    border: 1px solid $color-5f6573;
    border-radius: 10px;
    position: relative;

    // padding: .875rem 1.5rem;
    // margin-bottom: 10px;
    &.MuiAccordion-root {
        border-radius: 10px;
        box-shadow: none;
        padding: .5rem 2.5rem .5rem 1.5rem;
        margin-bottom: 0.625rem;

        &:first-of-type,
        &:last-of-type {
            border-radius: 10px;
        }

    }


    .MuiAccordionSummary-root {
        &:after {
            content: "expand_more";
            font-family: 'Material Symbols Rounded';
            font-weight: normal;
            font-style: normal;
            position: absolute;
            -moz-font-feature-settings: 'liga';
            top: 8px;
            font-size: 24px;
            right: -26px;

        }

        &.Mui-expanded {
            min-height: inherit;

            &:after {
                content: "expand_less";
            }
        }
    }

    .MuiAccordionDetails-root,
    .MuiAccordionSummary-root {
        padding: 0;
    }

    .MuiAccordionSummary-content {
        margin: 0;

        &.Mui-expanded {
            margin: 0;
        }
    }

    .accordion-summary-container {
        display: flex;
        align-items: center;
        justify-content: space-between;
        cursor: pointer;

        .close-icon {
            cursor: pointer;

            .active {
                transform: rotate(180deg);
                transition: all 0.2s ease-in-out;
            }

            .inactive {
                transition: all 0.2s ease-in-out;
            }
        }

        .accordion-summary {
            width: 100%;
            display: flex;
        }
    }

    .accordion-detail-container {
        // max-height: 0;
        // overflow: hidden;
        transition: 0.4s ease-in-out;

    }

    .accordion-detail-container.active {
        // max-height: inherit;
        margin-top: 50px;
    }
}
.add-influencer .accordion-container .MuiAccordionSummary-root {
    min-height: inherit;
}
.add-influencer .accordion-container .MuiAccordionSummary-root:after{
    right: 0px;
    top: -4px;
}
.add-influencer .accordion-container.MuiAccordion-root {
  
    &:first-of-type{
        margin-bottom: 0.625rem;
    }
}
.add-influencer .MuiAccordion-root.Mui-expanded {
    margin: inherit;
}

.add-influencer .MuiAccordion-root::before {
    background-color: transparent;
}
.add-influencer .accordion-container .MuiAccordionSummary-root.Mui-expanded {
    min-height: inherit;
}

.add-influencer-title {
    padding-bottom: 10px;
}