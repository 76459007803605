.chart-container {
    position: relative;

    .chart-option {
        display: flex;
        justify-content: flex-end;
        margin-bottom: 20px;
        align-items: baseline;

        select{
            background: white;
        }
    }

    .chart-label {
        margin-left: 10px;
    }
}