.notification_email_icon {
    color: #EA4335;
}

.notification_whatsapp_icon {
    color: #25D366;
}

// ._notifications_border{
//     border-bottom: 1px solid black;
// }

.notification_main_container {
    ul {
        list-style: none;
        margin: 0px;
        padding: 0px;
    }
}

.notification-checklist {
    margin-bottom: 8px;
    border-bottom: 1px solid #dee2e6;
    margin-left: 0 !important;

    .MuiCheckbox-root {
        padding: 0;
        padding-right: 10px;
        padding-bottom: 5px;
        padding-top: 5px;
    }

    .MuiFormControlLabel-label {
        font-size: 14px !important;
    }
}