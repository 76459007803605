$color-body: #2b2b2b;

$color-primary: #00aae5;

.products-container {
    flex: 99999;
}

.product-card-container {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    margin-bottom: 80px;

    .card-container {
        // flex: 0 0 calc(33.33% - 20px);
        // margin-right: 20px;
        // margin-bottom: 20px;
        border-radius: 8px;
        border: 1px solid #dee2e6;
        box-shadow: none;
        background: #f2f2f2;
        position: relative;
    }

    .card-switch {
        position: absolute;
        right: 0;
        cursor: pointer;
    }
}

hr {
    width: 97%;
    margin: 20px auto;
    color: rgb(184, 180, 180);
}

.product-card-content {
    h3 {
        font-size: 20px;
        font-weight: 700;

        a {
            text-decoration: none;
            color: $color-body;

            &:hover {
                color: $color-primary;
            }
        }
    }

    p {
        margin-bottom: 0;

        &.product-card-desc {
            padding-bottom: 30px;
            font-size: 14px;
            min-height: 100px;
        }
    }
}

.product-card-image.MuiCardMedia-root {
    height: 220px;
    border-radius: 0 0 8px 8px;
}

.card-name-span{
    &:hover{
    cursor: pointer;
    color:#00aae5;
    }
}

.product-card-price-info {
    display: flex;
    flex-wrap: wrap;
    padding-bottom: 0px;

    .price-info-discount {
        margin-left: auto;
    }
}

.product-action-btn {
    display: flex;

    .btn {
        flex: 1;
    }
}