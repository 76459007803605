    $activeColor: #00AAE5;
    $color-body: #2b2b2b;
    $color-border-tab: #dee2e6;

    .main-plan-container {
        // max-width: 900px;
        margin: 0 auto;

        .camp-title {
            span {
                text-transform: capitalize;
            }
        }

        .plans_tabs {
            margin-bottom: 16px;
            display: flex;

            // .nav.sub-tabs {
            //     align-items: center;
            //     position: relative;
            //     padding-left: 5px;
            //     padding-right: 5px;
            //     width: 100%;

            //     &:before,
            //     &::after {
            //         position: absolute;
            //         content: "";
            //         right: 0;
            //         width: 70px;
            //         height: 27px;
            //         background: linear-gradient(to left, rgb(255, 255, 255) 56.77%, rgba(255, 255, 255, 0) 94.47%) !important;
            //         z-index: 0;
            //     }

            //     &:before {
            //         right: auto;
            //         left: -10px;
            //         background: linear-gradient(to right, rgb(255, 255, 255) 56.77%, rgba(255, 255, 255, 0) 94.47%) !important;
            //         z-index: 1;
            //     }
            // }

            .nav-link {
                // margin-right: 0 !important;
                // margin-left: 0 !important;
                // text-align: center;

                .camp-title {
                    margin: 0 auto;
                    text-align: center;
                    font-size: 14px;
                    font-weight: normal;
                }
            }

            &--left {
                display: flex;
                align-items: center;
                // border-right: 1px solid #dee2e6;
                margin-right: 5px;
                padding-right: 15px;
                position: relative;
                flex: 0 0 auto;

                strong {
                    font-weight: normal;
                }


                &:after {
                    content: "";
                    position: absolute;
                    right: 0;
                    width: 1px;
                    height: 20px;
                    background: #dee2e6;
                }

            }

            .title-secondary {
                font-size: 14px;

            }
        }

        .tab-underline {
            margin-bottom: 0;
            padding-left: 5px;
            padding-right: 5px;
        }

        .title-secondary {
            flex: 0 0 auto;
            margin-bottom: 0;
            margin-right: 5px;
        }

        .profile-menu-mobile {
            margin-bottom: 0;
        }
    }

    ._plan_main_tabs {
        ._plan_main_tabs_inner {
            background-color: transparent;
            box-shadow: none;
        }

        .tabs {
            color: $color-body;
            display: inherit;
            // margin-bottom: 20px;

            .MuiTabs-flexContainer {
                // display: inherit;

                .MuiTab-root {
                    background-color: #eee;
                }
            }

            .MuiTab-root:focus,
            .MuiTab-root:hover {
                color: $color-body;
            }
        }

        position: relative;
        width: 100%;
        padding: 0px;
        z-index: 0;
    }

    .internal_external_row {
        margin-bottom: 20px;
        border-bottom: 1px solid $color-border-tab;

        .btn {
            cursor: pointer;
            color: $activeColor;
            padding: 0.5rem 1rem;
            border-radius: 4px 4px 0 0;
        }

        .active {
            color: $activeColor;
            // color: #fff;
        }
    }

    // .active {
    //     // background-color: $activeColor;
    //     // color: #fff;

    //     .camp-title {
    //         color: #000;
    //     }
    // }

    .plan-details {
        display: flex;
        align-items: center;


        >span {
            min-width: 100px;
        }

        strong {
            font-size: 20px;
            font-weight: 600;
        }

        >span {
            font-size: 12px;
            border-bottom: 1px solid #000;
            padding-bottom: 0px;
        }

        .scrolling-text-sec {
            margin-right: 10px;
            max-width: 100%;

        }




        .inner-scrolling-text-container {
            max-width: 100%;

            // @media (width > 47.9375rem) {
            //     max-width: 22rem;
            // }

            // @media (width > 62rem) {
            //     max-width: 30rem;
            // }
        }
    }


    .plans_tabs--left .btn-outline-primary {
        border-color: #000;
    }