@media only screen and (min-width:769px) {
  .topmenuslide .dropdown-menu.show {
    display: block !important;
  }

  .subs-plan-sec .plan-info,
  .pricing-tab .sub-tabs,
  .scroller,
  .scroller.scroller-right.active,
  .scroller.scroller-left.active {
    display: none;
  }

  .pricing-tab .subs-plan-sec.main-tab .plan-info {
    display: flex;
    flex-direction: row;
    box-shadow: none;
    border-radius: 0;
  }

  .pricing-tab .subs-plan-sec.main-tab .plan-info li {
    padding: 0;
  }

  .subs-sec {
    margin-left: 15px;
  }

  .infl-text.infl-text-list.loginabx {
    display: none;
  }
}


@media only screen and (max-width:768px) {
  .page-pricing .subs-plan-sec {
    flex-wrap: nowrap;
  }

  .pricing-tab .plan-heading-sec h3 {
    display: none;
  }

  .pricing-tab .subscription .box h2 {
    font-size: 24px;
  }

  .pricing-tab ul.sub-tabs li a {
    font-size: 15px;
  }

  .pricing-tab .subscription .box h2 span {
    font-size: 18px;
  }

  .pricing-tab .nav-tabs .nav-item {
    padding: 0;
  }

  .pricing-tab .subs-plan-sec ul li.nav-item::after {
    content: none;
  }

}


@media only screen and (max-width:767px) {
  .pricing-page-banner h2 {
    font-size: 60px;
    padding-bottom: 20px;
  }
  .pricing-page-banner h4 {
    font-size: 20px;
    display: flex;
    flex-direction: column;
  }
  .pricing-page-banner {
    padding: 30px 20px;
  }
  .subs-sec.infl-mob.only-premium .subs-plan-sec,
  #influencer .subs-plan-sec #inf-premium {
    flex: 0 0 100%;
    max-width: 100%;
    margin: 0;
  }
  .pricing-sec-infl{
    padding-top: 20px;
    padding-bottom: 60px;
  }
  .pricing-plan-sec {
    flex-direction: column;
  }
  .pricing-plan {
    flex: 0 0 100%;
    margin-right: 0;
    margin-bottom: 50px;
  }
  .pricing-plan-detail-sec .btn {
    opacity: 1;
    visibility: visible;
  }
  .pricing-tab h3 {
    font-size: 20px;
  }
}

@media only screen and (max-width:575px) {

}

@media only screen and (max-width:530px) {
  
}