$font-size-sml: 14px !default;
$border-color-card: #dee2e6 !default;
$border-radius-card: 14px !default;
$color-white: #ffffff !default;

.inf-creative-container {

    .creative-upload{
        display: flex;
        align-items: center;

        @media (max-width: 720px) {        
            flex-direction: column;  
            align-items: flex-start;
          }
    }

    .creative_size {
        // max-height: 300px;
        // min-height: 200px;
        // margin: 20px;
        // cursor: pointer;
    }

    .creative-list {
        ul {
            list-style: none;
            margin: 0px;
            padding: 0px;

            li {
                // border: 2px solid black;
                display: flex;

                .Rejected {
                    // border: 2px solid black;
                    display: flex;
                    margin-top: 20px;


                    font-weight: bold;
                }

                .Approved {
                    // display: flex;
                    // margin-top: 20px;
                    // padding: 5px 10px;
                    // border-radius: 5px;

                    // font-weight: bold;
                }

                .Pending {
                    // display: flex;
                    // margin-top: 20px;
                    // padding: 5px 10px;
                    // border-radius: 5px;

                    // font-weight: bold;
                }

            }
        }

        &.active {
            // @include breakpoint(medium) {
            //     width: 60%;
            // }
        }
    }

    .comments-section {
        width: 100%;

        ul {
            margin: 0px;
            padding: 0px;
            list-style: none;

            li {
                font-size: $font-size-sml;
                padding: 6px 0px;
                border-bottom: 1px solid lightgray;
                display: flex;
                justify-content: space-between;
                align-items: center;

                span {
                    font-size: small;
                    font-style: italic;
                }

                &:last-child {
                    border: none;
                }

            }
        }
    }

    .selected-creative {
        max-height: 300px;
        min-height: 200px;
    }

    .selectedCreative {
        border: 1px solid $border-color-card;
        border-radius: $border-radius-card;
        padding: 10px 20px;
        position: absolute;
        right: 0px;
        top: 0%;
        transform: translateY(-50%);
        left: 0;
        margin: 0 auto;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-end;
        background-color: $color-white;

        @include breakpoint(small) {
            top: 50%;
            transform: translateY(-50%);
        }

        .send-comment {
            width: 100%;
            display: flex;
            align-items: center;
        }

        .icon {
            cursor: pointer;
        }

    }


    .error {
        height: 70vh;
        // border: 2px solid black;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    &.overlay-creative {



        position: relative;

        &:after {
            position: fixed;
            content: "";
            top: 0;
            left: -15px;
            width: calc(100% + 30px);
            height: 100%;
            background: rgba(0, 0, 0, 0.8);
            z-index: 9;
        }

        @include breakpoint(small) {
            position: static;
        }
    }

    .creative-card-list .creative-list {
        display: flex;
        flex-wrap: wrap;
        gap: 1.25rem;

        .creative-card {
            flex: 0 0 calc(50% - 1rem);

            @include breakpoint(xsml) {
                flex: 0 0 calc(33.333% - 1rem);
            }

            @include breakpoint(small) {
                flex: 0 0 calc(25% - 1rem);
            }

            @include breakpoint(medium) {
                flex: 0 0 calc(20% - 1rem);
            }
        }
    }

}

.creative-image-preview-sec {
    position: relative;
    width: 200px;
    height: 200px;
    border: 1px solid #e2e2e2;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 10px;

    .close-preview {
        position: absolute;
        right: -12px;
        top: -12px;
        cursor: pointer;
    }

    .creative-image-preview {
        width: auto;
        object-fit: contain;
        height: auto;
        max-width: 12.375rem;
        max-height: 12.375rem;
        border-radius: $border-radius__10;

    }

    >.upload-file-btn {
        position: absolute;
        min-width: inherit;
        width: 48px;
        height: 48px;
        border-radius: 50%;
        z-index: 1;
    }

    .uploaded-pdf {
        text-align: center;
    }
}

.inf-creative-container {
    .creative-status {
        position: absolute;
        top: auto;
        right: -0.75rem;
        color: #2b2b2b;
        z-index: 1;
        left: 0;
        display: inline-table;
        padding: .25rem 1rem;
        font-size: .875rem;
        bottom: 1rem;
        border-radius: 0 .25rem .25rem 0;
    }
}