.upload-file {
    &-sec {
        width: auto;
        position: relative;
        height: auto;
        z-index: 1;
        border: .0625rem solid #000;
        background: rgba(0, 0, 0, 0.8);
        margin-right: 0;
        color: #fff;
        transition: all .35s ease;
        padding: .625rem 2.5rem;
        cursor: pointer;


        &:after {
            content: "Upload";
            width: 100%;
            padding-left: 0;
            position: relative;
            top: 0;
            display: flex;
            justify-content: center;
            align-items: center;
        }

        &:before {
            // content: "\F603";
            // font-family: "bootstrap-icons";
            // display: flex;
            // justify-content: center;
            // align-items: center;
            // padding-top: .625rem;
            // font-size: 1.875rem;
            // display: inline-block;
        }

        &:hover {
            background: #fff;
            color: #2b2b2b;
            box-shadow: .125rem .125rem .5rem rgba(0, 0, 0, 0.2);


        }
    }

    &-input {
        position: absolute;
        width: 100%;
        display: flex;
        justify-content: center;
        text-align: center;
        opacity: 0;
        z-index: 2;
        height: 100%;
        cursor: pointer;
        top: 0;
        left: 0;
    }


}

.upload-file-btn.MuiButton-root {
    position: relative;
    z-index: 9;
    color: #fff;
    font-family: "Open Sans";
    text-transform: none;
    width: 7.875rem;
    height: 2.8125rem;
    letter-spacing: normal;
    font-weight: normal;
    background: rgba(0, 0, 0, 0.8);

}

.img-upload-sec {
    width: 12.5rem;
    border: .0625rem solid #e2e2e2;
    height: 6.25rem;
    position: relative;
    z-index: 0;
    padding: 0;
    border-radius: .5rem;
    display: block;


    &:after {
        position: absolute;
        content: "UPLOAD";
        top: .625rem;
        left: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 100%;
        z-index: -1;
    }

    &:before {
        position: absolute;
        font-family: "bootstrap-icons";
        content: "\f603";
        top: -0.625rem;
        left: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 100%;
        z-index: -1;
    }

    .img-upload {
        opacity: 0;
        font-size: .625rem;
        height: 100%;
        width: 100%;
        cursor: pointer;
        display: block;
    }

    .MuiSvgIcon-root {
        font-size: $font-family-body;
        margin-right: .3125rem;
    }

    &:hover {
        background-color: $color-page-background;
    }
}


.upload-doc-sec {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding-bottom: 3.125rem;
    padding-top: 3.125rem;

    .upload-btn {
        display: flex;
        flex-direction: column;
        align-items: center;
        position: relative;
        z-index: 0;
        width: 18.375rem;
        text-align: center;
        cursor: pointer;
        padding: 1.625rem;
        border: .0625rem solid $border-campaign-detail-card;
        margin-bottom: .625rem;
        border-radius: .625rem;

        input[type="file"] {
            opacity: 0;
            cursor: pointer;
            width: 100%;
            margin: 0 auto;
            text-align: center;
            height: 100%;
            position: absolute;
            top: 0;
        }
    }

    span.material-symbols-rounded {
        // position: relative;
        // left: 0;
        // width: 100%;
        // // height: 30px;
        // z-index: -1;
        // font-size: 36px;
        cursor: pointer;
    }

    .uploaded-pdf {
        span {
            color: #2b2b2b;
            z-index: 1;
        }
    }

}