.detailed-analytics {
  padding: .625rem;

  .view-details-analytics {
    margin-bottom: 1.25rem;
    display: flex;
    justify-content: center;
    align-items: center;

    span {
      color: #00aae5;
      cursor: pointer;
    }
  }

  .upper-grid {

    // padding-bottom: 1.25rem;
    padding-top: 1rem;

    .upper-grid-div {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      background-color: #fff;
      border: .0625rem solid #dadada;
      border-radius: .3125rem;
      padding: 1.875rem 0rem;
      min-height: 6.875rem;
    }
  }

  .Most-Engaging-Posts {

    padding-bottom: 2.5rem;

    .grid-div {
      // border: .0625rem solid #dadada;
      border-radius: .3125rem;
      background-color: #fff;
      // padding: .9375rem 1.25rem 1.25rem 1.25rem;
      display: flex;
      flex-direction: column;

      // align-items: center;
      >span {
        font-size: $font-size-sml;
      }

      .grid-image {
        height: 10rem;
        object-fit: cover;
        border-radius: .875rem;
        width: 100%;
        margin-bottom: 10px;
        border: 1px solid $border-creative-card;
      }
    }
  }

  .Most-Engaging-Influencers {

    border: .0625rem solid #dadada;
    padding: 1.25rem;
    border-radius: .625rem;
    background-color: #fff;
    margin-bottom: 1.875rem;

    .Most-Engaging-Influencers-content {
      display: flex;

      @media screen and (max-width: 61.9375rem) {
        flex-direction: column;
      }

      .image-div {

        margin-right: 1.25rem;
        display: flex;
        flex-direction: column;
        align-items: center;

        @media screen and (max-width: 47.9375rem) {
          margin: .625rem auto;
        }

        .image {
          height: 15.625rem;
          width: 15.625rem;
        }
      }

      .right-content-div-top {
        display: flex;
        padding: 1.25rem;
        justify-content: space-between;
      }

      .right-content-div-middle,
      .right-content-div-bottom {
        display: flex;
        padding: .625rem;

        @media screen and (max-width: 47.9375rem) {
          flex-direction: column;
        }

        .middle-divs,
        .bottom-divs {
          display: flex;
          flex-direction: column;
          justify-content: center;
          padding: .625rem 1.875rem .625rem .9375rem;
          border-radius: .3125rem;
          border: .0625rem solid #dadada;
          margin-right: .625rem;

          @media screen and (max-width: 47.9375rem) {
            margin-bottom: .625rem;
            align-items: center;
          }
        }
      }

    }
  }

  .brand-section {
    .brand-title-sec {
      display: flex;
      justify-content: space-between;

      .date span {
        font-style: italic;
        font-weight: 100;
      }
    }
  }

  .campaign-title-sec {
    display: flex;
    justify-content: space-between;

    .campaign-type {
      display: flex;
      column-gap: 1.25rem;
    }

    .camp-title h2 {
      font-size: 1.25rem;
      font-weight: bold;
      margin: 0rem;
    }
  }

  .hr-divider-campaign {
    width: 100%;
    height: .0625rem;
    margin-bottom: 1.0625rem;
    background-color: #dadada;
  }

  .content {
    margin: .3125rem 0rem 1.5625rem;
    text-align: center;
    background: #fff;
    padding: .9375rem 0rem;
    border-radius: .25rem;
  }

  .content div {
    font-size: 1.5rem;
    font-weight: 800;
    color: #4f4f4f;
  }

  .c-border {
    border: .0625rem solid #ccc;
  }

  h2 {
    font-size: 1.25rem;
    font-weight: bold;
    margin: 1.25rem 0;
  }

  .posts-table {
    padding-left: 3.125rem;
    padding-right: 3.125rem;
    margin-top: 1.25rem;

    .posts-table-data td {
      padding: .625rem .75rem;
    }
  }
}
.grid-div-post .btn {
  font-size: 13px;
  color: #5d5d5d;
  border-bottom: 1px solid #5d5d5d;
}

.grid-div-post .btn:hover {
  color: #000;
  border-bottom-color: #000;
}

.grid-div-post img {
  width: 5rem;
  height: 5rem;
  object-fit: cover;
}


.detailed-analytics {
  .nav.sub-tabs {
    .material-symbols-rounded {
      font-size: 30px;
    }

    .MuiTabPanel-root {
      padding-left: 0;
      padding-right: 0;
    }

    .MuiTabs-indicator {
      display: none;
    }

    .nav-link {
      flex: 0 0 auto;

      &.Mui-selected {
        color: $color-black;
        border-color: $color-black;
      }
    }
  }

  .highcharts-title {
    font-family: "open sans";
    font-size: 1rem !important;
    font-weight: normal !important;
    opacity: 0;
  }
}

.sec-stockchart-w-tab {
  border: 1px solid $border-color-card;
  border-radius: $border-radius-card;
  margin-bottom: 30px;

  .nav-link {
    background-color: $color-f2f2f2;

    &:first-of-type {
      border-radius: $border-radius-card 0 0 0;
    }

    &:last-of-type {
      border-radius: 0 $border-radius-card 0 0;
    }

    &.Mui-selected {
      .material-symbols-rounded {
        font-variation-settings: "FILL" 1, "wght" 300, "GRAD" 0, "opsz" 24;
      }
    }
  }
}

.add-post-link-sec {
  .MuiInputBase-root {
    .MuiSelect-select {
      padding: 10px 14px;
    }
  }

  .add-post-link-url {
    margin-bottom: 0;

    .MuiInputBase-input {
      padding: 10px 14px;
    }
  }
}

.roi-generated-value {
  padding-bottom: 20px;
  p {
    margin-bottom: 0.5rem;
    span {
      display: inline-flex;
      min-width: 170px;
    }
  }    
}
.most-engaging-post-title {
 display: -webkit-box;
 -webkit-line-clamp: 1;
 -webkit-box-orient: vertical;
 overflow: hidden;
 line-height: 1.4;
}
.post-links {
  padding-left: 0;
  li {
      font-size: 14px;
      list-style: none;
      padding-bottom: 5px;
      a {
          color: #000;
        }
    }
}

.post-links iframe {
  width: 100% !important;
  min-width: inherit !important;
}

.post-links iframe .Header{
  min-height: 60px;
}

.analytic-share-option {
  &-container {
    display: flex;
    flex-wrap: wrap;
    gap: 1rem;
    flex: 1;
  }
  &-title {
    flex:  0 0 100px;
  }
  .btn {
    padding-left: 1rem;
    padding-right: 1rem;  
    &.react-share-btn {
      padding: 0
    }
    .react-share__ShareButton {
      display: flex;
      align-items: center;
        padding: 0.63rem 1rem !important;
      svg {
        width: 24px;
        height: 24px;
        border-radius: 50%;
        margin-right: 5px;
      }
    }
    span {
      margin-right: 5px;
    }
  }
}

.share-analytics .upper-grid {
  padding-left: 16px;
}


.popup-add-post-link.modal-medium .popup_container-inner {
  max-width: 55rem;
}