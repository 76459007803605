._brand-container{
    .__signup_form{
        .__wa-column{
            .__button{
                position: absolute;
                width: 125px;
                right: 11px;
                height: 39px;
            }
        }
    }
}