.agreement-section {
  // .MuiInputBase-input {
  //   color: #b01313;
  // }

  // .MuiInput-input {
  //   min-width: 47px;
  //   max-width: 203px;
  //   width: auto;
  //   color: #b01313;
  // }

  .agreement-format {
    padding: 25px;

    .agreement {
      .terms-conditions {
        .deliverables {
          border: 1px solid rgba(0, 0, 0, 0.3);
          border-radius: .875rem;
          max-width: 34.375rem;
          margin-left: auto;
          margin-right: auto;
          padding: .625rem 1.875rem .625rem 1.25rem;
        }

        .camp-dates .MuiInputBase-input {
          padding: 0.5px 7px;
        }

        .agreement-services>li {
          list-style: none;
        }

        .gst-amount .MuiInput-input {
          max-width: 85px;
        }
      }
    }

    .modified-agreement {
      .terms-conditions {


        ::before {
          border-bottom: 0px solid
        }




        .deliverables {
          // border: 1px solid #000000;
          max-width: 500px;
          margin-left: auto;
          margin-right: auto;
        }

        .camp-dates .MuiInputBase-input {
          padding: 0.5px 7px;
          width: 85px;
          font-weight: 600;
          color: #b01313;
        }

        .MuiInputBase-root-MuiOutlinedInput-root {
          padding-right: 0px;
        }

        .MuiOutlinedInput-notchedOutline {
          border-style: none;
        }

        .agreement-services>li {
          list-style: none;
        }

        .gst-amount .MuiInput-input {
          max-width: 85px;
        }

        .advance {
          display: none;
        }
      }
    }
  }

  .agreement {
    margin: 0 auto;
    // max-width: 834px;
    text-align: left;
    border: 1px solid rgb(199, 194, 194);
    border-radius: 5px;
    padding: 10px;
    // margin-left: 8px;
  }

  .MuiInput-input {
    padding: 0px 0 0px;
  }

  .event-title {
    margin-bottom: 15px;
    font-weight: 500;
  }
}

.agreement-table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 20px;
}

.agreement-table th, .agreement-table td {
  border: 1px solid #000; /* Adds a border around each cell */
  padding: 8px; /* Adjust padding as needed */
  text-align: left; /* Align text to the left */
}

.agreement-table th {
  background-color: #f5f5f5; /* Optional: Adds a background color to the header cells */
}
  

.influencer-agreement-main-section {
  .agreement-button {
    padding: 7px 24px;
    // max-width: 238px;
    // min-width: 174px;
    // margin-left: 24px;
    width: 100%;
  }

  .MuiStack-root {

    padding-top: 0;
  }

  .ag-first-sec {
    .MuiStack-root>.MuiTextField-root {
      // min-width: 202px;

      min-width: 260px;

      .MuiOutlinedInput-root {
        letter-spacing: 0.000138em;
      }
    }
  }

  .search-div {
    // padding-left: 7px;
  }


}

.delete-link {
  color: red;
}

.picker {
  display: flex;
  justify-content: space-between;
  column-gap: 20px;
}

.agreement-heading {
  .MuiStack-root>.MuiTextField-root {
    min-width: inherit;
  }
}


.terms-conditions ol li {
  line-height: 26px;
}

.input-style-date.border-btm-only {
  margin-left: 5px;
  margin-right: 5px;

  .MuiOutlinedInput-notchedOutline {
    border: none;
    border-bottom: 1px solid currentColor;
    border-radius: 0;
  }

  .MuiOutlinedInput-input {
    padding-top: 2px;
    padding-bottom: 2px;
    padding-left: 0;
    font-size: 14px;
  }

  .MuiOutlinedInput-root {
    &.Mui-focused {
      .MuiOutlinedInput-notchedOutline {
        border-top: none;
        border-right: none;
        border-left: none;
      }
    }
  }
}