
// .MuiInputBase-input {
//     &.MuiOutlinedInput-input{
//     font-size: $font-size-sml;
//     }
// }
.MuiInputBase-root.MuiOutlinedInput-root {
    font-size: $font-size-sml;
    min-height: 40px;
    height: 40px;
}
.MuiInputBase-root.MuiInputBase-multiline {
    min-height: 80px;
    height: auto;
}
.MuiInputBase-input.MuiInputBase-inputMultiline {
    padding-top: 15px;
}
.MuiButtonBase-root.MuiMenuItem-root,
.MuiInputBase-input.MuiOutlinedInput-input,
.MuiSelect-select.MuiInputBase-input,
.MuiFormLabel-root.MuiInputLabel-root {
    font-size: 14px;
}
.input-style {

    .MuiInputBase-input,
    label {
        font-size: $font-size-sml;
        min-height: 1.4375rem;
        height: auto;
    }

}

.input-w-helper {
    &-text {
        padding-left: 20px;
    }
}

.input-style {
    height: 40px;

    &.input-select-small {
        .MuiSelect-select {
            padding-top: 4px;

            padding-bottom: 4px;

        }
    }

    &-date {
        .MuiInputBase-input {
            padding: 8.5px 14px;
        }
    }

}

.MuiFormControl-root {
    &.error {

        .MuiOutlinedInput-notchedOutline,
        .MuiOutlinedInput-notchedOutline:hover {
            border-color: #d32f2f;
        }
    }
}

.MuiRadio-root {
    &.Mui-checked {
        color: $color-black;
    }
}

.MuiButtonBase-root {
    &.MuiCheckbox-root {
        &.Mui-checked {
            color: $color-black;
        }
    }
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Firefox */
input[type=number] {
    -moz-appearance: textfield;
}

.MuiFormControl-root {
    .MuiInputBase-root {
        &.Mui-focused {
            .MuiOutlinedInput-notchedOutline {
                border: 1px solid rgba($color-black, 1);
            }
        }
    }

    .MuiFormLabel-root {
        &.Mui-focused {
            color: $color-body;
        }

        &.MuiFormLabel-filled {
            +.MuiInputBase-root {
                .MuiOutlinedInput-notchedOutline {
                    border: 1px solid $color-border-default;
                }
            }
        }
    }
}

.MuiSelect-multiple .MuiCheckbox-root {
    display: none;
}