$activeColor: #00aae5;
.influencer_dashboard{

    .campaign-header-main{
        // border: 1px solid black;





        .campaign-title{
            color: $activeColor;
            cursor: pointer;
        }


        .campaign-info{
            ul{
                list-style: none;
                display: flex;
                justify-content: flex-end;
                align-items: center;
                margin: 0px;
                padding: 0px;
                li{
                    // border: 1px solid black;
                    padding: 8px 10px;
                    margin: 5px 10px;
                    border-radius: 10px;
                    background-color: lightgrey;
                    cursor: pointer;

                    &:last-child{
                        background-color: $activeColor;
                        color: white;
                    }
                }
            }
        }
    }
}