$activeColor: #00aae5;

.invoice_setting_company_trade_details_main {

    .tabs_soll li {
        display: flex;
        border-radius: 6px;
        cursor: pointer;
        width: 105%;
        justify-content: center;
        align-items: center;
    }

    ._companydetails_main_company {
        align-items: flex-end;
        justify-content: start;
        border-bottom: 1px solid #5d5d5d;
    }

    .showupload {
        display: flex;
        flex-direction: column;
    }

    .influencer_company_trade_details_uppercase {
        border-bottom: 1px solid;

        ul {
            list-style: none;
            margin: 0px;
            padding: 0px;
            display: inline-flex;


            li {
                margin: 0px 30px 0px 0px;
                cursor: pointer;
            }

            .active {
                color: $activeColor;
            }
        }
    }

    .influencer_company_trade_details_tabs {
        ul {
            list-style: none;
            margin: 0px;
            padding: 0px;

            .btn {
                margin-top: 20px;
            }
        }
    }


}

.contact_number input::-webkit-outer-spin-button,
.contact_number input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

.contact_number input[type=number] {
    -moz-appearance: textfield;
}