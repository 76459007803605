.order-details-page {
  width: 80%;
  margin: 0 auto;
  border-radius: 8px;
  padding: 20px 20px;
  box-shadow: 0px 0px 5px 5px #ebf2f7;

  h3 {
    color: #008577;
  }

  .order-deliver-details {
    display: flex;
    align-items: baseline;
    justify-content: space-between;
    width: 80%;
    margin-top: 20px;
  }

  .cancel-order-link{
    text-decoration: none;
    color: #008577;
    margin-top: 20px;

    &:hover{
        background-color: rgb(230, 227, 227);
    }
  }
}
