.subscriber-count-sec {
    position: relative;
    overflow: hidden;
    background: url("../../assets/images/banner-bg.png");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    width: 100%;

    &:before {
        content: "";
        position: absolute;
       background: url("../../assets/images/homepage-banner-outline-icon.png");
        background-repeat: no-repeat;
        background-size: 84%;
        background-position: right bottom;
        right: -17%;
        top: 0;
        width: 70%;
        height: 105%;
        z-index: 1;
      }

    .container{
        position: relative;
        z-index: 2;
        padding-top: 30px;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;

        .total-user-and-active-subscription{
           display: flex;
           justify-content: space-around;
           min-width: 100%;
           align-items: center;
           padding-bottom: 10px;

           @media (max-width: 576px) {
               flex-direction: column;
           }
        }

        .subscriber-count-total {
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            font-size: 26px;
            font-weight: 600;

            @media (max-width: 767px) {
               padding-bottom: 30px;
              }

            strong {
                font-size: 100px;
                line-height: 1;
                font-weight: 700;

                @media (max-width: 767px) {
                    font-size: 70px;
                   }

              }
          }

          .title-w-bdr{
            padding-bottom: 10px;
            margin-bottom: 10px;
            color: #5d5d5d;
          }

          .other-signup-data {
            flex: 0 0 calc(25% - 10px);
            display: flex;
            flex-direction: column;
            background: #fff;
            border: 1px solid #5d5d5d;
            padding: 20px;
            border-radius: 10px;
            margin-right: 10px;
            font-size: 18px;

            @media (max-width: 991px) {
                flex: 0 0 calc(50% - 10px);
                margin-right: 10px;
                margin-bottom: 10px;
              }

              @media (max-width: 767px) {
                flex: 0 0 100%;
               }
 

            .other-signup-data-main {
                display: flex;
                justify-content: space-between;
                align-items: center;
                margin-bottom: 12px;
              }

              .other-signup-data-split {
                display: flex;
                width: 100%;

                span {
                    flex: 1;
                    display: flex;
                    flex-direction: column;
                    margin-right: 5px;
                    padding-right: 5px;
                    font-size: 16px;
                    color: #5d5d5d;
                    font-weight: 300;
                  }

                  > span:first-of-type {
                    border-right: 1px solid #ccc;
                  }

                  >span:last-of-type {
                    text-align: right;
                    margin-right: 0;
                    padding-right: 0;
                  }

                  > span strong {
                    font-size: 18px;
                    font-weight: 600;
                  }
              
              }

            strong {
                font-size: 22px;
              }
          }
    }
  }


