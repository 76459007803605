// .__login_container {
//     .__login_row {
//         display: flex;
//         justify-content: center;
//         align-items: center;
//         min-height: 100vh;
//         padding: 10px;

//         .__login_box {
//             padding: 20px;
//             border: 1px solid $color-body;
//             border-radius: 10px;
//             display: flex;
//             justify-content: center;
//             align-items: center;
//             flex-direction: column;
//             position: relative;

//             .__button {
//                 position: absolute;
//                 right: 22px;
//                 top: 72px;
//                 background-color: #25D366;
//                 z-index: 9999;
//             }

//             .__login_auth_span {
//                 // border: 1px solid black;
//                 padding: 0px 10px;
//                 margin: 5px;
//                 border-radius: 10px;
//                 font-weight: bold;
//                 cursor: pointer;
//                 width: 100%;
//                 display: flex;
//                 justify-content: center;
//                 align-items: center;
//             }

//             .__login_text {
//                 font-size: 20px;
//                 padding-bottom: 20px;
//                 font-weight: bold;
//             }


//             .login_remember{
//                 margin: 20px 0px 0px 0px;
//             }



//             .login_buttons {
//                 margin: 20px 0px 0px 0px;
//                 .__login_button {
//                     margin-top: 20px;
//                     margin: 0px 10px;
//                 }
//             }
//         }

//     }

// }
.small-size {
  font-size: 12px;

  span {
    color: #000;
  }


  .disabled-link {
    color: #ccc;
    cursor: not-allowed;
    pointer-events: none
  }

  .show-cursor {
    cursor: pointer;
  }
}

.text-black-link {
  span {
    &:hover {
      text-decoration: underline;
    }
  }
}