.nav {
    &.sub-tabs {
        // margin-bottom: 1.5625rem;
        min-height: 2.875rem;
        // margin-top: .625rem;
        flex-wrap: nowrap;
        align-items: center;
        position: relative;

        &:after,
        &:before {
            position: absolute;
            content: "";
            right: 0;
            width: 2.1875rem;
            height: 1.6875rem;
            background: linear-gradient(to left, rgb(255, 255, 255) 56.77%, rgba(255, 255, 255, 0) 94.47%) !important;
            z-index: 0;
            display: none;
        }

        &:before {
            right: auto;
            left: -0.625rem;
            background: linear-gradient(to right, rgb(255, 255, 255) 56.77%, rgba(255, 255, 255, 0) 94.47%) !important;
            z-index: 1;
        }

        .nav-link {
            border-bottom: .0625rem solid transparent;
            // align-items: flex-start;

            &.active {
                border-bottom: .0625rem solid $color-black;
                color: $color-black;
            }

            &:hover {
                color: $color-black;
                border-color: $color-black-7;
            }
        }

        .MuiTabs-scrollButtons.MuiTabScrollButton-root {
            margin-right: .0625rem;
            margin-left: .0625rem;
            border: .0625rem solid $color-black;
            z-index: 1;
            width: 1.5rem;
            height: 1.5rem;
            border-radius: 50%;
            transition: box-shadow 0.3s, transform cubic-bezier(0.2, 0, 0, 1);
            background-color: $color-white;

            &.Mui-disabled {
                opacity: 0;
            }

            &:hover {
                box-shadow: 0 0 .3125rem #ddd;
                transform: scale(1.04);
                transition: box-shadow 0.3s, transform cubic-bezier(0.2, 0, 0, 1);
            }
        }

        &.nav-rounded {
            .nav-link {
                padding: .3125rem 1rem;
                margin-left: .3125rem;
                margin-right: .3125rem;
                line-height: 1;
                min-height: inherit;
                border: .0625rem solid rgba($color-black, 0.3);
                border-radius: 3.125rem;

                &.Mui-selected,
                &.active {
                    background-color: $color-black;
                    color: $color-white;
                }

                &:hover {
                    border-color: $color-black;
                }
            }
        }

    }

    &.no-scrollbar {

        &:before,
        &:after {
            display: none;
        }
    }

    &.tab-rounded {
        .nav-link {
            border-radius: 20px;
            padding: 4px 16px;
            border-bottom: none;
            align-items: center;
            margin-right: 10.08px;
            margin-left: 10.08px;

            &.active,
            &.Mui-selected,
            &:hover {
                color: $color-white;
                // font-weight: 600;
                // border-bottom: .0625rem solid $color-black;
                opacity: 1;
                background-color: $color-black;
            }
        }
    }

    &.w-bg {
        .nav-link {
            background-color: #ECECEC;
            flex: 1 0 auto;

            &:hover {
                color: $color-white;
            }
        }
    }
}


.nav {
    .nav-link {
        color: $color-body;
        border-bottom: .0625rem solid transparent;

        &:hover,
        &.active {
            // color: $color-primary;
            // border-bottom-color: $color-primary;

        }


    }

    ul {
        padding-left: 0;
        margin-bottom: 0;
    }

    &.sub-tabs {
        ul {
            display: flex;
            // border-bottom: .0625rem solid $border-color-card;
            width: 100%;
            list-style: none;


        }
    }
}

.tab-underline {
    // border-bottom: .0625rem solid $border-color-card;
    margin-bottom: 15.04px;
    align-items: center;

    .MuiTabs-flexContainer {
        .tab-underline-menu {
            // margin-bottom: -0.125rem;
            font-family: $font-family-body;
            text-transform: none;
            font-size: $font-size-sml;
            letter-spacing: 0;
            line-height: 1.25rem;
            opacity: 0.6;

            &.active,
            &.Mui-selected {
                color: $color-black;
                font-weight: 600;
                border-bottom: .0625rem solid $color-black;
                opacity: 1;
            }

            // &:first-child {
            //     padding-left: 0;
            // }
        }
    }

    .tab-underline-menu {
        // margin-bottom: -0.125rem;
        font-family: $font-family-body;
        text-transform: none;
        font-size: $font-size-body;
        letter-spacing: 0;

        &.Mui-selected {
            color: $color-primary;
            font-weight: 700;
        }

        // &:first-child {
        //     padding-left: 0;
        // }
        &:hover {
            border-bottom: .0625rem solid rgba(0, 0, 0, 0.7);
            color: #000;
            opacity: 1;
        }
    }

    .MuiTabs-indicator {
        background-color: $color-primary;
        bottom: -0.0625rem;
    }
}

.common-tab-sec {
    button {
        font-family: "Open sans";
        text-transform: none;

        &.Mui-selected {
            color: #00aae5;
        }

        .MuiTabs-indicator {
            background: #00aae5;
        }
    }
}

.MuiTabs-root.tab-underline {
    .MuiButtonBase-root-MuiTabScrollButton-root {
        border-radius: 1.25rem;
        height: 1.875rem;
        border: .0625rem solid #000;
        width: 1.875rem;

        &:hover {
            background-color: #000;
        }
    }
}