@import "https://cdn.jsdelivr.net/npm/bootstrap@5.1.0/dist/css/bootstrap.min.css";

// @import '/node_modules/bootstrap/scss/bootstrap-grid.scss';
// @import '/node_modules/bootstrap/scss/bootstrap-utilities.scss';
@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;600;700&display=swap");
@import url("https://cdn.jsdelivr.net/npm/bootstrap-icons@1.5.0/font/bootstrap-icons.css");
@import url("https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick.min.css");
// /* @import url("https://unpkg.com/bootstrap-table@1.18.3/dist/bootstrap-table.min.css"); */
@import './helpers/helpers_dir';
@import './layout/layout_dir';
@import './components/components_dir';
@import './auth/auth_dir';
@import './pages/pages_dir';