.common-table {
    font-family: $font-family-body;
    padding-left: 1px;
    border-radius: $border-radius-card;

    table {
        border-collapse: inherit;
    }

    &-header {
        tr {

            th {
                color: $color-white;
                padding-top: .38rem;
                padding-bottom: .38rem;
                font-size: $font-size-sml;
                text-align: left;
                border-bottom-color: $color-black-7;
                background-color: $color-black;
                font-family: $font-family-body;

                .MuiTableSortLabel-root {

                    &:hover,
                    &.Mui-active {
                        color: $color-white;
                    }
                }

                &:first-of-type {
                    border-radius: $border-radius-card 0 0 0;
                }

                &:last-of-type {
                    border-radius: 0 $border-radius-card 0 0;
                }

                // border-bottom: none;
            }
        }
        
        .brief-tr{

            th {
                color: $color-white;
                padding-top: .38rem;
                padding-bottom: .38rem;
                font-size: $font-size-sml;
                text-align: center;
                border-bottom-color: $color-black-7;
                background-color: $color-black;
                font-family: $font-family-body;

                .MuiTableSortLabel-root {

                    &:hover,
                    &.Mui-active {
                        color: $color-white;
                    }
                }

                &:first-of-type {
                    border-radius: $border-radius-card 0 0 0;
                }

                &:last-of-type {
                    border-radius: 0 $border-radius-card 0 0;
                }

                // border-bottom: none;
            }

        }
        .brief-tr-inner{

            th {
                color: $color-white;
                padding-top: .38rem;
                padding-bottom: .38rem;
                font-size: $font-size-xsml;
                text-align: center;
                border-bottom-color: $color-black-7;
                background-color: $color-black-5d;
                font-family: $font-family-body;

                .MuiTableSortLabel-root {

                    &:hover,
                    &.Mui-active {
                        color: $color-white;
                    }
                }

                &:first-of-type {
                     border-radius: 0;
                }

                &:last-of-type {
                     border-radius: 0;
                }

                // border-bottom: none;
            }

        }
    }

    &-body {

        font-family: $font-family-body;

        .MuiTableCell-root {
            padding-top: .5rem;
            padding-bottom: .5rem;
            border-bottom-color: $color-black-7;
        }

        .MuiTableCell-body {
            .Mui-disabled {
                background: #ededed;
                border-radius: .5rem;

                .MuiOutlinedInput-notchedOutline {
                    border-color: #ededed !important;
                }
            }

            .MuiInputBase-root {
                border-radius: .5rem;

            }

            .MuiOutlinedInput-notchedOutline {
                border-color: #6a6a6a !important;
            }
        }

        tr {
            td {
                border-collapse: inherit;
                font-family: $font-family-body;

                &:last-of-type {
                    border-right: 1px solid $color-black-7;
                }

                &:first-of-type {
                    border-left: 1px solid $color-black-7;
                }
            }

            &:last-of-type {
                td {
                    border-bottom: 1px solid $color-black-7;

                    &:first-of-type {
                        border-radius: 0 0 0 $border-radius-card;
                        // &:not(:only-of-type) {
                        //     border-radius: 0 0 0 $border-radius-card;
                        // }
                    }

                    &:last-of-type {
                        border-radius: 0 0 $border-radius-card 0;
                        // &:not(:only-of-type) {
                        //     border-radius: 0 0 0 $border-radius-card;
                        // }
                    }
                    
                }
            }
        }

        .table_cell {
            // display: flex;
            // min-height: 45px;
            // align-items: center;
            border-left: 1px solid $color-black-7;
            font-family: $font-family-body;
        }

        &:last-of-type {
            .table_cell {
                border-left: 1px solid $color-black-7;
                border-bottom: 1px solid $color-black-7;
            }

        }

        tr {
            // td {
            //     font-family: $font-family-body;

            //     &:last-of-type {
            //         border-right: 1px solid $color-black-7;
            //     }
            // }

            // &:last-of-type {
            //     td {
            //         border-bottom: 1px solid $color-black-7
            //     }
            // }
        }
    }

    // thead th {
    //     background-color: #e1e5ec;
    //     color: #9399a4;
    //     border-color: #eaecf0;
    //     padding-top: 10px;
    //     // max-width: 80px;
    //     padding-bottom: 10px;
    //     line-height: normal;
    //     font-size: 14px;
    //     text-align: left;
    // }

    tbody td {
        background-color: #fff;
    }

    &.common-table-densed {
        .MuiTableCell-root {
            padding: .38rem;
        }
    }

    &.wo-bdr {
        .common-table-body {
            td {
                border-bottom: none;
            }

            tr {
                td {
                    &:first-of-type {
                        border-left: none;
                    }
                }

                td {
                    &:last-of-type {
                        border-right: none;
                    }
                }
            }

            .exclusivity-blank-row {
                td {
                    padding-left: 0;
                    padding-right: 0;

                    hr {
                        margin-top: 0;
                        margin-bottom: 0;
                    }
                }
            }
        }
    }


}


.table-deliverable {
    box-shadow: none;
    border: 1px solid $border-color-card ;

    td {
        ul {
            margin-bottom: 0;
            padding-left: 1.06rem;

            li {
                padding-bottom: .63rem;
            }
        }
    }

    th {
        font-family: Open sans !important;
    }

    tbody {
        th {
            width: 210px;
            vertical-align: top;
            border-right: 1px solid $border-color-card !important;
        }
    }
}

.common-pagination {

    .MuiTablePagination-selectLabel,
    .MuiTablePagination-displayedRows,
    .MuiIconButton-root,
    .MuiTablePagination-select {
        color: $color-body;
    }
}

// .table-negotiation .common-table-header tr th {
//     min-width: 140px;
// }

// .table-negotiation .common-table-header tr th.blank-col,
// .table-negotiation .common-table-body tr td.blank-col {
//     width: 20px;
//     background: #fff;
//     font-size: 0;
//     border-bottom: none;
//     min-width: 20px;
//     padding-left: 0;
//     padding-right: 0;
// }

// .table-negotiation .common-table-header tr th.blank-col {
//     width: 0;
//     padding: 0;
//     min-width: 0;
// }
.table-negotiation {
    .common-table-header {
        tr {
            th {
                min-width: 140px;
                width: 140px;
                background-color: $color-8c8c8c;
                border-bottom-color: $color-8c8c8c;

                &.column-alternate {
                    background: $color-black;
                    border-bottom-color: $color-black;
                }
            }
        }

    }

    .common-table-header tr th.blank-col,
    .common-table-body tr td.blank-col {
        width: 20px;
        background: #fff;
        font-size: 0;
        border-bottom: none;
        min-width: 20px;
        padding-left: 0;
        padding-right: 0;
    }

    .common-table-body {
        .MuiTableCell-root {
            padding-top: 1rem;
            padding-bottom: 1rem;
            border-bottom: none;
            border-left: none;
            border-right: none;
            min-height: 5rem;

            @include breakpoint(small) {
                padding-top: 1.25rem;
                padding-bottom: 1.25rem;
            }

            &.proposal-column-alternate {
                background: #f2f2f2;
            }
        }

        .table-negotiation-blank-row {
            td.blank-col {
                border-top: none;
            }

            td {
                border-top: 1px solid #d7d7d7;
                padding-top: 25px;
            }
        }

        .table-negotiation-blank-row-last {
            td {
                border-bottom: 1px solid #d7d7d7;
                padding-bottom: 25px;
            }
        }

        .table-negotiate-input-sec {
            position: absolute;

            left: 0;
            background-color: $color-white;
            display: flex;

            form {
                display: flex;
            }

        }
    }


    .table-negotiation-action-btns {
        position: relative;
        width: 10.625rem;
        min-width: 10.625rem;
        display: flex;
        gap: 10px;
        align-items: center;

        span {
            cursor: pointer;

            &:hover {
                svg {
                    path {
                        opacity: 0.7;
                    }
                }
            }

            &.negotiation-dis {
                button {
                    cursor: auto;
                    padding: 0;

                    &:hover {
                        background-color: transparent;
                    }
                }



                svg path {
                    fill: #838383;
                }
            }

            &.negotiation-disabled {
                pointer-events: none;
                cursor: default;

                button {
                    cursor: default;
                    // cursor: none;    
                    pointer-events: none;
                }

                svg path {
                    fill: #838383;
                }
            }
        }


    }

}

.table-negotiation-action-btns #id_nego {
    margin-top: -6px;
}


.common-table-footer {
    td {
        background-color: $color-white;
        font-size: $font-size-sml;
    }
}

.prop-total-cost.column-alternate {
    background: $color-black-5d;
    color: $color-white;
}

// .table-negotiation .common-table-body td.proposal-column-alternate {
//     background: #f2f2f2;
// }


.common-table.common-table-proposal.wo-bdr {
    .common-table-header {
        tr {
            th {

                background-color: $color-8c8c8c;
                border-bottom-color: $color-8c8c8c;
                min-width: 5rem;

                @include breakpoint(small) {
                    min-width: 8.75rem;
                }

            }
        }

        &.column-alternate {
            th {
                background: $color-black;
                border-bottom-color: $color-black;
            }

        }

    }

}

.common-table.common-table-proposal {
    margin-bottom: 10px;
    border-radius: 7px;
    border-radius: 7px;
    border: 1px solid $color-d2d2d2;
    padding-left: 0;

    tr {
        th {
            &:last-of-type {
                border-radius: 0 7px 0 0;
            }

            &:first-of-type {
                border-radius: 7px 0 0 0;
            }

        }
    }

}

.common-table {
    &.common-table-fixed-column {
        width: auto;

        tr {
            th {
                &:first-of-type {
                    border-radius: 0;
                }
            }

            &:last-of-type {
                td {
                    &:first-of-type {
                        border-radius: 0;
                    }
                }
            }
        }
    }
}


.inf-child-section.flex-column.chat-drawer-active {
    position: absolute;
    left: 0;
    background: #fff;
}

// .common-table.common-table-proposal.wo-bdr .common-table-header tr th:first-of-type {
//     min-width: 190px;
//     max-width: 190px;
//     width: 190px;
// }

// .common-table.common-table-proposal.wo-bdr .common-table-header tr th:nth-of-type(2) {
//     min-width: 60px;
//     max-width: 60px;
//     width: 60px;
//     text-align: center;
//     font-size: 0;
// }

// .common-table.common-table-proposal.wo-bdr .common-table-header tr th:nth-of-type(2):after {
//     content: "Qty";
//     font-size: .875rem;
// }

// .common-table.common-table-proposal.wo-bdr .common-table-body tr td:nth-of-type(2) {
//     text-align: center;
// }

.common-table .common-table-header .proposal-table-dur-info th {
    background: #fff;
    padding: 0;
    line-height: 0;
    min-height: 0;
    border-bottom: none;
}

.common-table .common-table-header .proposal-table-dur-info th .proposal-dur-info {
    margin-top: 0;
    color: $color-black;
}



.table-proposal {
    border: 1px solid $color-bcc2ce;
    border-radius: $border-radius__10;
    // padding-bottom: 3.75rem;
}

.table-proposal-header {
    &-row {
        background-color: $color-dbe0ea;


    }

    th {
        font-weight: 700;
        padding: 0.4375rem 0.625rem;
        line-height: 1.2;
        font-family: "Open Sans", sans-serif;

        &:first-of-type {
            font-weight: normal;

            width: 150px;
            min-width: 150px;
        }

        span {
            display: block;
        }

        &:not(:first-of-type) {
            width: calc((100% - 150px) / 5)
        }
    }
}



.table-proposal-body {
    background-color: $color-f9fafc;

    .table-proposal-body-inner-header-cell {
        width: 150px;
        max-width: 150px;
        min-width: 150px;
        padding: 0;
        border-bottom-color: transparent;

        td {
            padding: 0.25rem 0.625rem;
            border-bottom: 1px solid #bcc2ce;
            font-family: "Open Sans", sans-serif;
        }
    }

    &-inner-cell,
    &-row {
        td {
            &:first-of-type {
                // width: 150px;
                border-right: none;
                border-top: none;
            }

            font-family: "Open Sans",
            sans-serif;

            &:not(:first-of-type) {
                width: calc(100% / 5);
            }
        }

        &:last-of-type {
            td {
                border-right-color: $color-bcc2ce;
            }
        }
    }

    &-inner-cell // tr {
    //     &:last-of-type {

    //         td,
    //         th {
    //             border-bottom: none;
    //         }
    //     }
    // }

    td,
    th {
        border: 1px solid $color-bcc2ce;
        padding: 0.25rem 0.625rem;
        // &:last-of-type {
        //     border-bottom: none;
        // }
    }

    th {
        .material-symbols-rounded {
            font-size: 15px;
            vertical-align: -2px;
        }
    }

    .table-proposal-body-inner-cell {
        padding: 0;
        border: none;
        width: calc((100% - 150px) / 5);
    }
}



.table-proposal-total-cost-row {
    background-color: $color-f9fafc;

    td {
        padding: 0.25rem 0.625rem;
        font-family: "Open sans", sans-serif;
        border-bottom-color: #BCC2CE;

    }

    &.extra-space {
        height: 45px;
        display: flex;
    }

    // th,
    // td {
    //     border-right: none;
    //     border-left: none;
    // }

    th {
        .material-symbols-rounded {
            font-size: 15px;
            vertical-align: -2px;
        }
    }

}


.input-fields {
    position: relative;
}

.icons-section {
    position: absolute;
    top: 2px;
    right: 2px;

    .lockIcon {
        background: #E7ECF6;
        border-radius: 4px;
        font-size: 20px;
        padding: 4px;
        color: #000;
    }

    .closeIcon {
        position: absolute;
        right: 30px;
        top: 6px;
        font-size: 16px;
        cursor: pointer;
    }
}

.negotiate-input {
    border-radius: 7px;
    border: 1px solid #BCC2CE;
    padding-left: 10px;

    padding-right: 50px;
    font-size: 14px;

}

.proposal-negotiation-detail.inner-content .negotiate-response-table .table-proposal {
    border: none;

    td {
        border: none;
    }
}

.negotiate-sec-input td {
    width: calc((100% - 150px) / 5);
    border: none;
    padding-bottom: 4px !important;
}

// .table-negotiation-body-content-cell {
//     width: calc((100% - 150px) / 5);
// }


// .table-negotiation-container .table-proposal-body-inner-cell {
//     width: 150px;
//     max-width: 150px;
//     min-width: 150px;
// }
.proposal-negotiation-detail.negotiate-response-table {
    .table-negotiation-container.table-proposal {
        border-radius: 00;
        border: none;

        >table {
            border: none;
        }
    }
}

.table-negotiation-container {
    .table-proposal-body {
        background-color: transparent;

        .table-negotiation-body-heading-cell {
            padding: 0;
            width: 150px;
            max-width: 150px;
            min-width: 150px;
            border: none;
            vertical-align: top;

            td {
                padding: 0;
                border: none;
                padding-bottom: 4px !important;

                span {
                    border: 1px solid #BCC2CE;
                    border-radius: 7px;
                    background-color: #F9FAFC;
                    display: block;
                    padding: 0.25rem 0.625rem;
                    height: 29px;
                }
            }
        }
    }
}

.proposal-negotiation-detail.negotiate-response-table.inner-content {
    margin-top: 1.5rem;
    margin-bottom: 2.5rem;
    flex-direction: column;

    .proposal-negotiation-step {
        flex: 0 0 7.25rem;

        .inf-proposal-main & {
            flex: 0 0 12.5rem;
        }
    }
}

.inf-proposal-main {
    .table-proposal-header-row {
        // margin-top: 20px;
        margin-bottom: -4px;
    }
}

.proposal-negotiate-action-btn {
    display: flex;
    justify-content: flex-end;
    gap: 0.5rem;
}

.proposal-action-btn {
    display: flex;
    justify-content: flex-end;
    gap: 0.5rem;

    margin-bottom: 2rem;
    margin-right: 1rem;
    margin-top: 1rem;
}

.buttons-field {
    display: flex;
    justify-content: flex-end;
    gap: 0.25rem;
    margin-top: 0.375rem;

    .buttons {
        padding: 2px 10px !important;
        border-radius: 7px;
        &[disabled] {
            background-color: rgba(0, 0, 0, 0.1);
            color: rgba(0, 0, 0, 0.15);
            -webkit-text-fill-color: rgba(0, 0, 0, 0.35);
            cursor: default;
            border: 1px solid #ccc;
        }
    }
}

.proposal-negotiation-detail.inner-content {
    .table-proposal>table {
        border: 1px solid #BCC2CE;
    }
}



// NEW TABLE STRUCTURE WITH DIV
.proposal-negotiation-sec {
    flex-direction: column;
}

.table-proposal-header-row {
    display: flex;
    width: 100%;
    border-radius: 10px 10px 0 0;
    margin-bottom: -24px;
    height: 48px;
}

.table-proposal-header-first-col {
    flex: 0 0 126px;
    font-size: 14px;
    display: flex;
    flex-direction: column;
    padding: 0.25rem 0.625rem;
}

.table-proposal-header-rest-col-container {
    display: flex;
    flex: 1;
}

.table-proposal-header-rest-col {
    flex: 1;
    padding: 0.25rem 0.625rem;
    font-size: 14px;
    font-weight: 700;
    // height: 47px;
}

.table-proposal-header-first-col span {
    padding: 0.25rem 0.625rem;
    border-bottom: 1px solid #BCC2CE;
}

.table-proposal-header-first-col span:last-of-type {
    border-bottom: none;
}



// TABLE BODY


.table-proposal-body {
    display: flex;
    border: 1px solid #BCC2CE;
}

.table-proposal-body-first-col {
    display: flex;
    flex-direction: column;
    flex: 0 0 126px;

    &.extra-space {
        justify-content: center;
        height: 45px;
    }
}

.table-proposal-body-first-col>span {
    height: 29px;
    padding: 0.25rem 0.625rem;
    font-size: 14px;
    border-bottom: 1px solid #BCC2CE;
}

.table-proposal-body-first-col>span:last-of-type {
    border-bottom: none;
}

.table-proposal-body-rest-col-container {
    display: flex;
    flex: 1;

    &.extra-space {
        .table-proposal-body-rest-col {
            display: flex;
            align-items: center;
        }
    }
}

// .table-proposal-body-rest-col-container .table-proposal-body-rest-col-row:last-of-type span {
//     border-bottom: none;
// }

.table-proposal-body-rest-col {
    height: 29px;
    flex: 1;
    padding: 0.25rem 0.625rem;
    border-bottom: 1px solid #BCC2CE;
    border-left: 1px solid #BCC2CE;
    line-height: 20px;
    font-size: 14px;
}

.table-proposal-body-rest-col-row {
    flex: 1;
    display: flex;
    flex-direction: column;

    &.rejected {
        background-color: #FFCACA;
    }

    &.accepted {
        background-color: #CEFFE7;
    }
}

.table-proposal-body-rest-col-row span:last-of-type {
    border-bottom: none;
}

// .table-proposal-body-first-col>span:last-of-type {
//     border-bottom: navajowhite;
// }

.table-proposal-body-rest-col-row span:last-of-type {}

// .table-proposal-body-rest-col-row:last-of-type span {
//     border-bottom: navajowhite;
// }

.table-proposal-total-cost-row {
    padding: 0.25rem 0.625rem;
    border: 1px solid #BCC2CE;
    border-top: none;
}



// NEGOTIATION TABLE 

.proposal-negotiation-table-row {
    flex: 99999;
    display: flex;
    gap: 0.25rem;
}

.table-negotiation-body-first-col {
    flex: 0 0 126px;
    display: flex;
    flex-direction: column;
    gap: 0.25rem;
}

.table-negotiation-body-rest-col-container {
    flex: 1;
    display: flex;
    gap: 0.25rem;
}

.table-negotiation-body-first-col {
    span {
    height: 32px;
    border-radius: 7px;
    background: #F9FAFC;
    padding: 0 10px;
    border: 1px solid #bcc2ce;
    line-height: 26px;
    font-size: 14px;
    &.table-negotiation-body-empty {
            min-height: 50px;
            background: transparent;
            border: none;
        }
}
}
.input-section {
    display: flex;
    flex-direction: column;
    gap: 0.25rem;
}

.nego-input-container {
    display: flex;
    flex-direction: column;
    flex: 1;
}

.table-negotiation-body-first-col span:last-of-type {
    background: transparent;
    border: none;
}



.proposal-negotiation-sec-mobile {
    .table-proposal-header-row {
        span {
            flex: 1;    
            font-size: 14px;
            &:first-of-type {
                flex: 2;
            }
        }

    }
    .table-proposal-body-other-column-container {
        flex: 1;
        .table-proposal-body-rest-col-row {
            flex: 1;
            flex-direction: row;
            height: 48px;
            border-bottom: 1px solid $color-bcc2ce;
            .table-proposal-body-rest-col {
                height: 48px;
            }
            &:last-of-type {
                border-bottom: none;
            }
        }
    }
    .table-proposal-body-deliverable-name {
        display: flex;
        flex: 1;
        flex-direction: column;
        span {
            flex: 0 0 auto;
            padding: 0.25rem 0.625rem;
            border-bottom: 1px solid $color-bcc2ce;
            height: 48px;
            justify-content: center;
            font-weight: bold;
            font-size: $font-size-sml;
            &:last-of-type {
                border-bottom: 1px solid $color-bcc2ce;
                border-radius: 0;
            }
        }
    }
    .table-proposal-body-rest-col-row   {
        > span {
            &:last-of-type {
                border-bottom: 1px solid $color-bcc2ce;
            }
        }
    }
        .table-proposal-body-rest-col {
            display: flex;
            align-items: center;
        }
        .table-proposal-total-cost-row {
                border-radius: 0 0 10px 10px;
                border: none;
        }
        .proposal-negotiation-detail:last-of-type {
            border-bottom-left-radius: 0.625rem;
            border-bottom-right-radius: 0.625rem;
        }
        .table-proposal-body {
            border: none;
            border-radius: 0  0 .875rem .875rem;
        }
        .table-proposal-body-first-col {
            flex: 0 0 auto;
            border-radius:0;
            background-color: $color-white; 
          
        }
        .table-negotiation-body-first-col {
            flex: 1;
            &.package {
                gap: 0;
                .table-proposal-body-first-col {
                    border: 1px solid #bcc2ce;
                    background-color: #fff;
                    border-top:none;
                }
            }
        }
        .proposal-negotiation-table-row {
            gap: 0;
        }
        .table-proposal {
            border: none;
            border-radius: 0;
        }
        .negotiate-input {
            border-radius: 0;
        }
        .table-proposal-header-row {
            margin-bottom: 0;
            border-radius: 0;
            height: 33px;
            align-items: center;
        }
        .table-negotiation-body-rest-col-container {
            flex-direction: column;
                section {
                    display: flex;
                    flex: 1;
                    span {
                        flex: 1;
                    }
                }
                &.package {
                    gap: 0;
                    .table-negotiation-body-first-col {
                        gap: 0;
                        span:last-of-type {
                            border-radius:0;
                            border: 1px solid #bcc2ce;
                            border-top: none;
                            border-left: none;
                        }        
                    }
                    .negotiate-input {
                        border-left: none;
                        border-top: none;
                    }
                }
            
        }
        .buttons-field {
            margin-top: 0.625rem;
            padding-right: 0.625rem;
            padding-bottom: 0.625rem;
            .buttons {
                    padding: 5px 30px !important;
                    border-radius: 7px;
                }
        }   
}
.proposal-negotiation-table-container {
    border: 1px solid $color-909AAC;
    border-radius: 10px;
    background-color: $color-f9fafc;
}


.proposal-negotiation-resp-header {
    display: flex;
    flex-direction: column;
    padding: 6px 20px 10px 6px;
    width: 100%;
    .stepper-sec li {
        min-height: 3rem;
        background-color: $color-white;
    }
    &-top {
        display: flex;
        align-items: center;
    }
    
}
span.resp-date {
    margin-left: auto;
    font-style: italic;
    font-size: 14px;
    color: #90A0B6;
}

.icons-section.disabled .lockIcon {
    opacity: .5;
    pointer-events: none;
  }

.removeCloseIcon {
    visibility: none;
  }
  
 .mystyle + .icons-section .closeIcon {
    display: none;
}
.mystyle + .icons-section .closeIcon {
    color: white;
}
.new-class{
    color: white;
}


.table-negotiation{
    
    &.rejected {
        background-color: #FFCACA;
    }

    &.accepted {
        background-color: #CEFFE7;
    }
}
.input-section-disable-class.table-negotiation.accepted .icons-section{
    display: none;
}
.input-section-disable-class.table-negotiation.rejected .icons-section{
    display: none;
}
section.input-section-disable-class.accepted span * {
    background: #CEFFE7;
}


section.input-section-disable-class.accepted span .icons-section.disabled {
    display: none;
}
section.input-section-disable-class.rejected span * {
    background: #FFCACA;
}


section.input-section-disable-class.rejected span .icons-section.disabled {
    display: none;
}

.border-right-white{
    border-right: 1px solid #fff !important;
}

.nested-tablecell{
    border-bottom: 0px !important;
    padding-top: 0px !important;
    padding-bottom: 0px !important;
    min-width: 100px !important;
    text-align: center !important;
}

.social-nested-tablecell{
    border-bottom: 0px !important;
    // padding-top: 0px !important;
    // padding-bottom: 0px !important;
    padding: 0px !important;
    min-width: 60px !important;
    text-align: center !important;
}

.nested-tablecell-total{
    border-bottom: 0px !important;
    padding-top: 0px !important;
    padding-bottom: 0px !important;
    min-width: 120px !important;
    text-align: center !important;
}

.left-border-col{
    border-left: 1px solid rgba(224, 224, 224, 1) !important;
    padding: 0px !important;
}
.left-border-nested-col{
    border-left: 1px solid rgba(0, 0, 0, 0.3);
}
.discover-dots{
.discover_subscription_dot {
    width: 12px;
    height: 12px;
    border-radius: 50%;
    // position: absolute;
    // left: 10px;
    // top: 5px;
    border: 1px solid #c4c4c4;
    display: flex;
    margin-right: 8px;
}
.discover_subscription_dot_non_subscribed{
    background-color: #F23434;
    }
.discover_subscription_dot_active{
    background-color: #5FC996;
    }
.discover_subscription_dot_non_active{
   background-color: #FFD645;
   ;
    }
}

.userbrief-highlighted-row{
    background-color: #eee;
}