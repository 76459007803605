$activeColor: #00AAE5;
$border-color-card: #dee2e6;


.camp_inf_main_container {
    padding: 0px !important;
    margin-top: 10px;
    padding-top: 10px;
    border-top: 1px solid $border-color-card;

    .row {
        margin-top: 10px;
    }
}