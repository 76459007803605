.submit-proposal-card {
    padding-left: 0;
    padding-right: 0;
    padding-bottom: 1rem;
    border-bottom: 1px solid $border-campaign-detail-card;
    margin-bottom: 1rem;

    @include breakpoint(small) {
        padding-left: 1.875rem;
        padding-right: 1.875rem;
        padding-bottom: 1.875rem;
        margin-bottom: 1.875rem;
    }
    .MuiSwitch-thumb {
        background-color: #fff;
        width: 30px;
        @media (width < 768px){
        width: 26px;
        }
        height: 22px;
        margin: 0;
        border-radius: 28px;
    }
    .MuiSwitch-root {
        @media (width > 767px){
            width: 80px;
        }
        width: 70px;
        height: 40px;
        .MuiSwitch-switchBase.Mui-checked {
            transform: translateX(32px);
            @media (width < 768px){
                transform: translateX(26px);
            }
            + .MuiSwitch-track {
                background-color: #000;
                    border: 1px solid #000;
                    opacity: 1;
            }
        }
    }
    .MuiSwitch-track {
        opacity: 1;
        &:before, &:after{
            @media (width < 768px){
                top: 19px !important;
            }

        }
    }

    .common-table table {
        border-radius: $border-radius-card;
        border: 1px solid rgba(0, 0, 0, 0.3);
    }
    .switch-cell {
        padding-left: 6px;
        padding-right: 6px;
    }

    &.wo-bdr {
        border-bottom: none;
        margin-bottom: 0;
    }

    .submit-proposal-camp-title {
        font-size: $font-size-large;
        font-weight: bold;
    }

    h3 {
        font-size: $font-size--1_25rem;
        @media (width < 768px) {
            font-size: 1rem;
            font-weight: normal;
            color: $color-black-5d;
        }
    }

    .common-table-body tr td {
        border: none;
        vertical-align: top;
        @media (width < 768px) {
            font-size: .75rem;
            vertical-align: middle;
        }
        hr {
            width: 100%;
        }
    }

    .MuiInputBase-multiline {
        border-radius: 0.5rem;
    }

}

.submit-proposal-initial {
    @media (width < 768px){
        margin-top: -1.25rem;
    }
    .submit-proposal-title {
        margin-bottom: 2rem;
        display: block;
    }

    &-detail {
        display: grid;
        grid-template-columns: auto;
        gap: 1.25rem;
        border-bottom: 1px solid $border-campaign-detail-card;
        @media (width > 767px) {
            padding-bottom: 1.875rem;
        }

        // margin-bottom: 1.5rem;
        // padding-left: 1.875rem;
        // padding-right: 1.875rem;
        @include breakpoint(small) {

            grid-template-columns: 1fr 200px;
        }

        &__left__top {
            display: grid;
            grid-template-columns: 1fr auto;
        }

        &__left {
            display: grid;
            @media (width > 767px) {
                grid-gap: 0.5rem;
            }


        }

        &__right {
            @include breakpoint(small) {
                border-left: 1px solid $border-campaign-detail-card;
                padding-left: 1.25rem;
            }

            .dashboard-card-camp-dur {
                .proposal-dur-info {
                    grid-template-columns: auto;
                    grid-auto-flow: column;
                    @include breakpoint(small) {
                        grid-auto-flow: row;
                    }
                }
            }

        }
    }

    .camp-description {
        margin-bottom: 1.5rem;
    }
}



.inf-proposal-main {

    .table-icon {
        // border: 1px solid black;
        margin-right: 10px;
        cursor: pointer;
    }

    .error-heading {
        min-height: 70vh;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .terms_conditions {
        display: flex;
        align-items: center;

        @media (width < 768px) {
            margin-bottom: 1rem;

        }
    }

    .total_amount {
        display: flex;
        align-items: center;
        margin-top: 10px;

        span {
            margin-right: 20px;
        }
    }

    .deliverables {
        span {
            margin-top: 20px;
            font-size: $font-size-large;

            &::after {
                display: none;
            }
        }
    }

    .inf-details-main-container {
        border-radius: $border-radius-card;
    }
}

.table-proposal-negotiation {
    table {
        border-collapse: separate;
    }

    .table-fixed-column {
        td {
            background-color: #fff;

            @include breakpoint(small) {

                &:first-of-type {
                    left: 0;
                    position: sticky;
                    z-index: 3;
                }

                &:nth-of-type(2) {
                    left: 175px;
                    position: sticky;
                    z-index: 3;
                }

                &:nth-of-type(3) {
                    left: 235px;
                    position: sticky;
                    z-index: 3;
                }

                &:nth-of-type(4) {
                    left: 345px;
                    z-index: 3;
                    position: sticky;
                }

            }
        }
    }

    thead {
        tr {
            @include breakpoint(small) {
                th:first-of-type {
                    left: 0;
                    position: sticky;
                    z-index: 3;
                }

                th:nth-of-type(2) {
                    left: 175px;
                    z-index: 3;
                    position: sticky;
                }

                &.table-proposal-negotiate-header-column {
                    th {
                        &:first-of-type {
                            left: 175px;
                            position: sticky;
                            z-index: 3;
                        }

                        &:nth-of-type(2) {
                            left: 235px;
                            position: sticky;
                            z-index: 3;
                        }

                        &:nth-of-type(3) {
                            left: 345px;
                            position: sticky;
                            z-index: 3;
                        }
                    }

                }
            }
        }
    }
}

.comment-section {
    ul {
        margin: 0px;
        padding: 0px 10px;
        list-style: none;

        li {
            border-bottom: 1px solid $border-color-card;
            padding: 5px 0;
            display: flex;
            justify-content: space-between;
            align-items: center;

            &:last-child {
                border: none;
            }

            span {
                font-size: small;
                font-style: italic;
            }

        }
    }
}

// .infl-proposal-detail-container {
//     display: flex;
//     flex-wrap: wrap;
//     overflow: hidden;
//     max-height: 31.25rem;
//     border: 0.0625rem solid #d2d2d2;
//     border-radius: 0.625rem;
//     padding: 0rem 0.625rem 0.3125rem 0.625rem;
//     overflow-y: auto;
//     width: 100%;
// }

// .infl-proposal-inner-container {
//     width: 100%;
// }
.inf-proposal-main .infl-proposal-inner-container {
    padding-left: 16px;
}

.inf-proposal-main .proposal-detail-container,
.inf-proposal-main .infl-proposal-detail-container {
    height: calc(500px - 20px);
    padding-left: 0;

    @include breakpoint(small) {

        height: calc(500px - 5rem);
    }
}

.submit-proposal-card {
    .common-table {
        @media (width < 768px) {
            margin-left: -1rem;
            width: calc(100% + 2rem);
            table  {
                border: none;

            }
        }
        &-header{
            @media (width < 768px) {
                th {
                    line-height: 1;
                    font-size: .75rem;
                }
            }
        }
        
    }
    .common.table {
        &.table-proposal-negotiation {
            border-radius: 0;

            th:first-of-type {
                border-radius: 0;
            }

            th:last-of-type {
                border-radius: 0;
            }
        }
    }
}