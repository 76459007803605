.brand-dashboard-card {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  margin-bottom: 2.5rem;

  .icon {
    cursor: pointer;

    &:hover {
      color: $color-primary;
    }
  }
}

.dashboard-insight {
  display: flex;
  flex-wrap: wrap;
  width: 100%;

  .MuiGrid-root {
    @media only screen and (max-width:575px) {
      --Grid-columnSpacing: 6px;
      --Grid-rowSpacing: 0px;
      margin-left: 0;
      margin-right: 0
    }
  }

  .MuiGrid-item {

    @media only screen and (max-width:992px) {
      max-width: 33.333%;
      flex: 0 0 33.333%;

    }

    @media only screen and (max-width:575px) {
      max-width: 100%;
      flex: 0 0 100%;
      padding-left: 0 !important;
    }

  }

  &-card {
    flex: 0 0 calc(20% - 12px);
    border: 1px solid $color-black-5d;
    padding: 10px 20px;
    border-radius: $border-radius-card;
    height: 100px;
    background-color: #fff;
    font-size: 18px;
    align-self: center;
    display: flex;
    flex-direction: column;
    justify-content: center;

    &:nth-child(5n) {
      margin-right: 0;
    }

    span {
      &:first-of-type {
        font-size: 1.25rem;
        font-weight: 600;
      }
    }
  }
}

.camp-duration-data {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 20px;

  @media (width < 53.125rem) {
    flex-direction: column;
  }
}

.camp-duration-date {
  .MuiGrid-root {
    @media (width < 48rem) {
      flex: 1;
    }
  }

  .MuiFormControl-root {

    @media (width < 48rem) {
      width: 100%;
    }
  }
}

.ag-format-container {
  width: 100%;

  @media (width > 47.9375rem) {
    margin-top: 1.25rem;
  }
}

.ag-courses_box {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  padding: 0px 0 1.25rem;
  gap: 1.25rem;
}

.ag-courses_item {
  flex-basis: calc(25% - 16px);
  overflow: hidden;
  border-radius: 15px;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
}

.ag-courses-item_link {
  display: block;
  padding: 1.25rem 1.25rem;
  background-color: #ffffff;
  text-decoration: none;
  overflow: hidden;
  position: relative;
}

.ag-courses-item_link:hover,
.ag-courses-item_link:hover .ag-courses-item_date {
  text-decoration: none;
  color: #fff;
}

.ag-courses-item_link:hover .ag-courses-item_bg {
  -webkit-transform: scale(10);
  -ms-transform: scale(10);
  transform: scale(10);
}

.ag-courses-item_title {
  margin: 0 0 1.25rem;

  overflow: hidden;

  font-weight: bold;
  font-size: 24px;
  color: #000000;

  z-index: 2;
  position: relative;
}

.ag-courses_item:hover {
  box-shadow: rgba(0, 0, 0, 0.55) 0px 5px 15px;
}

.ag-courses-item_link:hover .ag-courses-item_title {
  color: #f9f8f8;
}

.ag-courses-item_date-box {
  font-size: 18px;
  color: #fff;

  z-index: 2;
  position: relative;
}

.ag-courses-item_date {
  font-weight: bold;
  color: #f9b234;

  -webkit-transition: color 0.5s ease;
  -o-transition: color 0.5s ease;
  transition: color 0.5s ease;
}

.ag-courses-item_bg {
  height: 128px;
  width: 128px;
  background-color: #00aae5;

  z-index: 1;
  position: absolute;
  top: -75px;
  right: -75px;

  border-radius: 50%;

  -webkit-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
  transition: all 0.5s ease;
}

.ag-courses_item:nth-child(2n) .ag-courses-item_bg {
  background-color: #00aae5;
}

.ag-courses_item:nth-child(3n) .ag-courses-item_bg {
  background-color: #00aae5;
}

.ag-courses_item:nth-child(4n) .ag-courses-item_bg {
  background-color: #00aae5;
}

.ag-courses_item:nth-child(5n) .ag-courses-item_bg {
  background-color: #00aae5;
}

.ag-courses_item:nth-child(6n) .ag-courses-item_bg {
  background-color: #00aae5;
}

@media only screen and (max-width: 979px) {
  .ag-courses_item {
    flex-basis: calc(50% - 10px);
  }

}


@media only screen and (max-width: 639px) {
  .ag-courses_item {
    -ms-flex-preferred-size: 100%;
    flex-basis: 100%;
  }

  .ag-courses-item_title {
    min-height: 72px;
    line-height: 1;


  }

  .ag-courses-item_date-box {
    font-size: 16px;
  }
}


.run-camp-card {
  justify-content: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: $color-f2f2f2;
  padding: 10px;
  border-radius: $border-radius-card;

  p {
    text-transform: capitalize;
  }

  .icon {
    cursor: pointer;

    &:hover {
      color: $color-primary;
    }
  }
  span {
    font-weight: bold;
  }
}

.imageNameDiv {
  display: flex;

  .imageDiv {
    width: 40px;
    height: 40px;
    border: 1px solid #dee2e6;
    border-radius: 50%;
  }

  .instaNameDiv {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-left: 20px;
  }
}

.camp-duration-range {
  margin-right: 10px;

  @media (width < 53.125rem) {
    margin-right: 0;
    margin-bottom: 1.25rem;
  }

  button {
    padding: .5rem .625rem;
  }
}