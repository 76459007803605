.__pagenotFound_container_fluid {
    background-color: white;

    .__pagenotFound_main_col {
        height: calc(100vh - 200px);
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;

        ._pagenotFound_undefined {
            font-size: 150px;
            font-weight: bold;
        }

        .__pagenotFound {
            font-size: xx-large;
            font-weight: bold;
        }
    }

    .image-404 {
        max-width: 40rem;
        width: 100%;
    }
}