.app-header_brand_left_side{
    img{
    height: 46px;
    max-width: 140px;
    }
}

.__thanku_container {
    min-height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
}

.sec-thanks-left {
    h3 {
        padding-bottom: 1.875rem;
    }

    ul {
        padding-left: 0;

        li {
            padding-bottom: .625rem;
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            position: relative;

            a {
                font-size: .875rem;
                color: #2b2b2b;
                font-weight: 600;

                &:hover {
                    text-decoration: none;
                }
            }
        }
    }
}

.common-thankyou-page {
    position: relative;
    background: url(../../../assets/images/banner-bg.png);
    min-height: 100vh;
    overflow-x: hidden;

    .main-content-sec {
        margin-top: 0;
        position: relative;
        overflow-x: hidden;
        padding-bottom: 0;

        &:after {
            content: "";
            position: absolute;
            background: url(../../../assets/images/homepage-banner-outline-icon.png);
            background-repeat: repeat;
            background-size: auto;
            height: 100%;
            width: 100%;
            background-repeat: no-repeat;
            background-position: right bottom;
            background-size: 60% auto;
            z-index: -1;
            top: 0;
            right: -12%;
        }
    }

    .sec-thanks {
        min-height: calc(100vh - 0rem);
        padding-bottom: 6.25rem;
        padding-top: 6.25rem;
        width: 100%;
        display: flex;
        align-items: center;

        &-left {
            ul {
                padding-left: 2.8125rem;
            }
        }
    }
}

.triangle-border {
    position: absolute;
    width: 0;
    border-color: transparent transparent transparent #00aae5;
    border-width: .8125rem 0 .8125rem 1.625rem;
    border-style: solid;
    left: -2.25rem;

    &.triangle-border-inner {
        &:after {
            position: absolute;
            top: -0.625rem;
            left: -1.5rem;
            content: "";
            width: 0;
            border-color: transparent transparent transparent #fff;
            border-width: .625rem 0 .625rem 1.25rem;
            border-style: solid;
        }

        &:before {
            position: absolute;
            top: -0.625rem;
            left: -2rem;
            content: "";
            width: 0;
            border-color: transparent transparent transparent #00aae5;
            border-width: .625rem 0 .625rem 1.25rem;
            border-style: solid;
            z-index: 1;
        }
    }
}