.main-container-campaign {
  background-image: url("../../../assets/images/banner-bg.png");
  background-position: center;

  .campaign_inputs {
    max-width: 850px;
    background-color: white;
    padding: 36px 48px 48px 44px;
    border-radius: 13px;
    border: 1px solid #2b2b2b;
    width: 100%;
    text-align: left;
  }

  .campaign_inputs_title {
    font-size: 30px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #2b2b2b;
    padding-bottom: 30px;
    margin: 0;
  }
}

.main-container-campaign .campaign_inputs .config-input .config-input-label {
  font-weight: 600;
  font-size: 16px;
  min-width: 180px;
  text-align: left;
}

.main-container-campaign .campaign_inputs .conf-infl-type label {
  min-width: 150px;
}

.main-container-campaign .padding-b {
  padding-bottom: 5px;
}

.camp-object input[type="radio"] + label,
.camp-object input[type="checkbox"] + label {
  display: inline-block;
  cursor: pointer;
  position: relative;
  padding-left: 30px;
  margin-right: 15px;
}

.main-container-campaign .campaign_inputs div label {
  font-size: 14px;
  font-weight: 300;
}

#platform_edit .radio-button-secs {
  gap: 14px;
}
.radio-button-secs {
  display: flex;
  gap: 44px;
  padding-bottom: 5px;
}

label {
  margin-bottom: 0;
  display: inline-block;
}

.config-input {
  display: flex;
  margin-bottom: 25px;
}

.config-input-detail {
  display: flex;
  align-items: flex-start;
  flex: 1;
}

.main-container-campaign .campaign_inputs .influencer_type_checkbox-sec {
  margin-bottom: 25px;
  align-items: flex-start;
  flex-direction: column;
  margin-right: 15px;
}

.main-container-campaign
  .campaign_inputs
  .influencer_type_checkbox-sec
  .budget-box {
  flex: 9999;
}

.budget-box .input-box .form-control {
  width: 96px;
  margin-left: 25px;
}

.main-container-campaign .campaign_inputs div label img {
  max-height: 60px;
  margin-right: 5px;
}
.main-container-campaign .btn-center {
  text-align: center;
}

.category-input {
  .MuiFormControl-root {
    max-width: 500px;
    width: 100%;
  }
}

@media only screen and (max-width: 991px) {
  .main-container-campaign .campaign_inputs .config-input .config-input-label {
    min-width: 150px;
  }
}

@media only screen and (max-width: 575px) {
  .config-input {
    flex-direction: column;
  }
}
