.adminDashboardSignupMostOutterDiv {
  width: 100%;
  // font-weight: 600;
  // background-color: white;

  .adminDashboardSignupContentOutterDiv {
    // max-width: 1280px;
    // margin: 0 auto;
    margin-left: 65px;

    .adminDashboardSignupContentDivShortInfo {
      margin: 0 0 20px 0;
      font-size: 16px;

      .adminDashboardSignupContentDivShortInfoTop {
        display: flex;
        margin-bottom: 10px;
        flex-wrap: wrap;

        .adminDashboardSignupContentDivShortInfoTopSpan {
          padding-right: 6px;
          margin-right: 15px;
          border-right: 1px solid #2b2b2b;

          .adminDashboardSignupContentDivShortInfoTopAnchorKey {
            text-decoration: none;
            font-weight: 400;
            color: #00aae5;
          }

          .adminDashboardSignupContentDivShortInfoTopSpanValue {
            margin-left: 5px;
          }
        }
      }

      .adminDashboardSignupContentDivShortInfoBottom {
        display: flex;
        margin-bottom: 20px;
        font-size: 16px;
        flex-wrap: wrap;

        .adminDashboardSignupContentDivShortInfoBottomSpan {
          padding-right: 6px;
          margin-right: 15px;
          border-right: 1px solid #2b2b2b;

          .adminDashboardSignupContentDivShortInfoBottomAnchorKey {
            text-decoration: none;
            font-weight: 400;
            color: #00aae5;
          }

          .adminDashboardSignupContentDivShortInfoBottomSpanValue {
            margin-left: 5px;
          }
        }
      }
    }

    .formDiv {
      margin-bottom: 20px;

      form {
        display: flex;
        flex-direction: column;

        .MuiInputBase-input {
          font-size: 14px;
        }

        .searchButton {
          text-transform: none;
          background-color: #00aae5;
        }

        .formInput {
          display: flex;
          justify-content: space-between;
          margin-bottom: 20px;

          column-gap: 15px;

          .formInputDiv {
            display: flex;
            flex-direction: column;
            // margin-right: 10px;

            .MuiStack-root {
              padding-top: 0px;
            }

            label {
              font-weight: 400;
              font-size: 14px;
            }
          }
        }
      }
    }

    .tableCell {
      .instagramLink {
        cursor: pointer;
        text-decoration: underline;
        color: #00aae5;
      }

      .tableDownloadButton {
        text-transform: none;
        color: #00aae5;
      }
    }

    .paginationDiv {
      margin-top: 15px;
      width: 100%;
      display: flex;
      justify-content: right;
    }
  }
}

// .table_plan tr th {
//   background-color: #e1e5ec;
//   color: #9399a4;
//   border-color: #eaecf0;
//   padding-top: 10px;
//   max-width: 80px;
//   padding-top: 0px;
//   padding-bottom: 0px;
//   line-height: normal;
//   font-size: 14px;
//   text-align: left;
// }

// .adminDashboardSignupContentDivTable .MuiTableCell-root {
//   padding-top: 4px;
//   padding-bottom: 4px;
// }

// .inf-main-data {
//   .MuiTableCell-root {
//     padding: 0;
//   }

//   thead th {
//     padding-top: 0;
//     padding-bottom: 0;
//   }

//   td {
//     padding: 8px 15px;
//   }
// }

// .inf-data .MuiTableCell-root {
//   padding-left: 11px;
// }

// .inf-data {
//   thead th {
//     max-width: 125px;
//     padding-left: 12px;
//     padding-right: 0;
//   }

//   td {
//     padding: 8px 14px;
//   }
// }