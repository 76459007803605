.invoice-info-sec{

    .invoice-upload-generate{
        display: flex;
        align-items: center;
        @media (max-width: 967px) {        
            flex-direction: column;  
            align-items: flex-start;
          }
    }
}