.brands-data-container {
    .brands-data-main {
        .run-camp-card {
            box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;
        }

        .brand-chart {
            font-size: 1.25rem;
            font-weight: 400;
        }

        .brand-card {
            font-size: 1.25rem;
            font-weight: 400;
            color: #4c4c4c;
        }

        .brand-name {
            font-size: 1.25rem;
            // background: -webkit-linear-gradient(45deg, #405de6, #5851db, #833ab4, #c13584, #e1306c, #fd1d1d);
            // -webkit-background-clip: text;
            // -webkit-text-fill-color: transparent;
            // background-clip: text;
        }
    }
}

.brand-insight-profile {
    background-color: #F1F8FB;
    padding: 1.25rem 6.25rem;
    display: flex;
    flex-wrap: wrap;
    border-radius: $border-radius__10;
    gap: 1.25rem;
    margin-top: 0;
    margin-bottom: 1rem;

    &-right {
        max-width: 40rem;
        flex: 0 0 40rem;
    }
}

.brand-insight-profile-image {
    width: 18rem;
    height: 18rem;
    border-radius: 50%;
    border: 2px solid #BED8E2;
    margin-top: -6.875rem;
}

.brand-insight-follower-info {
    display: flex;
    gap: 1rem;
    padding-left: 0;
    margin-top: 1rem;

    li {
        display: flex;
        flex-direction: column;
        text-align: center;
        padding: 10px;
        border: 1px solid #BED8E2;
        min-width: 155px;
        border-radius: 8px;
        color: #3B5763;

        strong {
            font-size: 1.625rem;
            font-weight: 800;
        }

        span {
            font-size: .9375rem;
        }
    }
}

.brand-insight-info {
    background-color: rgba(107, 197, 250, 0.05);
    border: 1px solid rgba(107, 197, 250, 1);
    color: #1C1B1F;
    border-radius: .375rem;
    padding: 0.375rem;
    font-size: 0.75rem;
    display: flex;
    align-items: center;
    gap: .375rem;

    .material-symbols-rounded {
        color: #56B4EC;
    }
}


.brand-insight-highlight ul {
    display: flex;
    padding-left: 0;
    list-style: none;
    gap: 0.625rem;
}

.brand-insight-highlight ul li {
    border: 1px solid #83A5B0;
    padding: 0.625rem;
    border-radius: .625rem;
    flex: 2;
    flex-direction: column;
    display: flex;
}

.brand-insight-highlight-value-sec {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
}

.brand-insight-highlight-value {
    display: flex;
    flex-direction: column;
    flex: 1;
}


.brand-insight-highlight-trend {
    font-size: .625rem;
}

.brand-insight-highlight-value-sec {
    padding-bottom: 1rem;
}

.brand-insight-highlight-value strong {
    font-size: 1.75rem;
    color: #3B5763;
    font-weight: 800;
    line-height: 1;
}

.brand-insight-highlight-value span {
    font-size: .875rem;
}

.brand-insight-highlight ul li:nth-child(3),
.brand-insight-highlight ul li:nth-child(2) {
    flex: 1;
}

.brand-insight-highlight-title {
    padding-bottom: .625rem;
    display: block;
}

.brand-insight-highchart-subtitle {
    font-size: 12px;
    line-height: 0.7;
    font-weight: 400;
}

.brand-insight-highchart-subtitle,
.brand-insight-highlight-value span,
.brand-insight-highlight-title,
.brand-insight-highlight-trend {
    color: #3B5763
}

.brand-insight-highlight-trend {
    display: flex;
    gap: 0.25rem;
    align-items: center;
    margin-top: auto;
}

.brand-insight-highchart-er .highcharts-title {display: none;}