@media only screen and (max-width: 1400px) {
    ._influencer-container {
        ._influencer-signup {
            .__backArrow {
                width: 100% !important;
            }

            .text-end {
                width: 100% !important;
            }
        }
    }


}


