// .gstin_verified{
//     border-radius: 5px;
// }

.barter-popup {
    .popup_container {
        .popup_container-inner {
            .popup_container_content {
                .popup_content_container {
                    background-color: #fff;
                    padding: 16px 32px 0;

                    .MuiTypography-root {
                        margin-top: 0px;
                    }
                }
            }
        }
    }
}