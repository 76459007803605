$color-primary: #00aae5 !default;
$accepted: lightgreen;
$errorColor: #d32f2f !default;

.terms_conditions {
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start !important;
}

.table-icon {
    // border: 2px solid black;
    font-size: 30px;
    margin: 0px 5px;
    padding: 2px;
    cursor: pointer;
    transition: .2s;


    &:hover {
        color: $color-primary;
    }

}

.generate_invoice {
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

// .accepted {
//     background-color: rgba(83, 251, 85, 0.2) !important;
// }

// .negotiating {
//     background-color: rgba(83, 251, 85, 1) !important;
// }

// .rejected {
//     background-color: rgba(225, 33, 20, 0.2) !important;
// }

.icon-cell {
    width: 200px;
}

.input-cell {
    width: 200px;
}

.updateBarterProduct-container {

    .deactivefield {
        cursor: not-allowed;
        pointer-events: none;
        opacity: .5;
    }

    .shipping_receipt_url {
        // height: 200px;
        width: 200px;
    }

    .shipping_receipt_section {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
    }

    .save-add-can-buttons {
        .btn {
            margin-right: 10px;
        }
    }

    .inputFieldSp {
        margin: 10px;
        padding-left: 0;
        padding-right: 0;
    }



    .product-image-section {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
    }

    .shipping_receipt_section {
        ul {
            list-style: none;

            li {
                display: flex;
                flex-direction: column;
                padding-bottom: 1rem;

                strong {
                    display: block;
                    padding-bottom: 2px;
                }
            }
        }
    }


}

.image-style {
    height: auto;
    max-width: 200px;
}

.barter-shipping-detail .creative-detail.inner {
    height: auto;
    .creative-detail-img {
        border-right: none;
    }
}

.uploadproduct .MuiInputBase-input {
    padding-top: 0;
}