$border: 4px solid lightgrey;


.border-left {
    border-left: $border;
    padding: 0px 0px 0px 30px;
}

.horizontal-border {
    border-bottom: $border;
    padding: 0 0 20px 0;
}

.contract-details {
    list-style: none;
    margin: 0px;
    padding: 0px;
}

.content-width {
    width: 100px;
}

.item-middle {
    display: flex;
    justify-content: center;
    align-items: flex-end;
    flex-direction: column;
}

.non-border {
    border: none !important;
}


.invoice-main-container {
    // border: 3px solid yellow;
    display: flex;
    justify-content: center;
}

.invoice-container {
    width: 100%;
    max-width: 900px;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    padding-top: 45px;
    .invoice-close {
    z-index: 2;
    width: 100%;
    padding: 16px 23px 16px 15px;
    border-radius: 10px 10px 0 0;
    border-bottom: 1px solid rgb(215, 215, 215);
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    margin-bottom: 20px;
    position: fixed;
    top: 86px;
    left: 0;
    width: 100%;
    background: #fff;

        strong {
            margin-right: auto;
        }
        .material-symbols-rounded {
            width: 30px;
            height: 30px;
            border-radius: 50%;
            background-color: #000;
            color: #fff;
            display: flex;
            justify-content: center;
            align-items: center;
            
        }
    }
    .border-buttom {
        border-bottom: $border;
    }

    .rupee-icon {
        font-size: 15px;
    }

    .cell-total {
        text-align: right;
    }

    .total-row {
        background-color: #eee;
        margin-top: 20px;
    }

    .contract_logo {
        height: 100px;
        width: 100px;
        border-radius: 50%;
        margin: 10px;
    }


    .MuiOutlinedInput-notchedOutline {
        border-color: var(--mui-palette-error-main);
        border-style: none;
    }

    .invoice-date {
        .MuiOutlinedInput-root {
            padding-right: 0px;
        }

        .MuiInputBase-input {
            padding: 0.5px 0px;
        }

        .MuiOutlinedInput-input {
            margin-right: -83px;
        }
    }

    .invoice-number-field {
        .MuiInput-input {
            margin-right: -100px;
        }
    }

    .invoice-edit-icon {
        cursor: pointer;
        font-size: 18px;
        padding: 4px;
        border: 1px solid rgba(0, 0, 0, 0.3);
        border-radius: 50%;
        color: #fff;
        background-color: #000;
        margin-left: 4px;
        width: 28px;
        height: 28px;
        display: flex;
        justify-content: center;
        align-items: center;
    }
}

.invoice-container.add-padding {
    padding: 30px;
}

// #invoice-main {
//   width: 90%;

//   .contract_logo {
//     height: 100px;
//     width: 100px;
//     border-radius: 50%;
//     margin: 10px;
//   }

//   .invoice-send {
//     display: none;
//   }

//   .invoice-edit-icon {
//     color: #fff;
//   }
// }

.download-invoice {
    padding-top: 40px;

    button {
        display: none;
    }

    .invoice-edit-icon {
        color: #fff;
        border: 1px solid #fff;
    }

    .contract_logo {
        display: none;
    }
}

.sending-invoice-text {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-image: linear-gradient(-225deg,
            #231557 0%,
            #44107a 29%,
            #ff1361 67%,
            #fff800 100%);
    background-size: auto auto;
    background-clip: border-box;
    background-size: 200% auto;
    color: #fff;
    background-clip: text;
    text-fill-color: transparent;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    animation: textclip 2s linear infinite;
    display: inline-block;
    font-size: 50px;
}

@keyframes textclip {
    to {
        background-position: 200% center;
    }
}




// .campaign-invoice{
//     .table-plan{
//         margin: 10px 0px;
//     }

// .rupee-icon{
//     font-size: 15px;
// }

//     .date-invoice{
//         display: flex;
//     }

//     .tax-invoice-row{
//         background-color: gray;
//         padding: 10px 0px;
//         margin: 10px 0px;
//         display: flex;
//         align-items: center;
//         color: white;

//         .tax-invoice-div{
//             font-size: x-large;
//             font-weight: 600;
//         }

//         .date{
//             margin-right: 10px;
//             padding-right: 10px;
//             border-right: 2px solid white;
//         }
//     }

//     .gst-ul{
//         border-left: 6px solid lightgray;
//         padding-left: 10px;
//     }

//     ul{
//         list-style: none;
//         margin: 0px;
//         padding: 0px;
//     }

//     ol{
//         margin-left: 30px;
//         li{
//          text-align: justify;
//         }
//     }

//     .custom-cell{
//         height: 200px;
//         ul{
//             width: 500px;
//             li{
//                 border: 1px solid lightgray;
//                 padding: 10px 0px;
//                 &:last-child{
//                     background-color: lightgray;
//                 }
//             }
//         }
//     }

// }

// .subs-invoice {
//   padding: 10px 20px;
//   background: #fff;
//   border-radius: 8px;
//   border: 1px solid #dee2e6;
//   margin-bottom: 100px;

//   .table-plan {
//     margin: 10px 0px;
//   }

//   .date-invoice {
//     display: flex;
//   }

//   .tax-invoice-row {
//     background-color: gray;
//     padding: 10px 0px;
//     margin: 10px 0px;
//     display: flex;
//     align-items: center;
//     color: white;

//     .tax-invoice-div {
//       font-size: 20px;
//       font-weight: 600;
//     }

//     .date {
//       margin-right: 10px;
//       padding-right: 10px;
//       border-right: 2px solid white;
//     }
//   }

//   .gst-ul {
//     border-left: 6px solid lightgray;
//     padding-left: 10px;
//   }

//   ul {
//     list-style: none;
//     margin: 0px;
//     padding: 0px;
//   }

//   ol {
//     margin-left: 30px;

//     li {
//       text-align: justify;
//     }
//   }

//   .custom-cell {
//     height: 200px;

//     ul {
//       // width: 400px;

//       li {
//         border: 1px solid lightgray;
//         padding: 10px 20px;
//         width: 400px;

//         &:last-child {
//           background-color: lightgray;
//         }
//       }
//     }
//   }

//   .icon-rupee {
//     font-size: 16px;
//     vertical-align: top;
//     display: inline-flex;
//   }
// }

// .payment-detail .card-content ul li {
//   display: flex;
//   flex-wrap: wrap;
// }


// .inv-terms {
//   font-size: 14px;
// }



// New css for subscription invoice page

/* Invoice Preview modal */
.form-row .list-w-edit-icon .form-check-label {
    display: inline;
}

.prev-inv-info {
    display: flex;
    flex-wrap: wrap;
    margin-top: 10px;
    background-color: #f9fafc;
    padding: 10px 20px;
}

.prev-inv-info-left {
    width: 70%;
}

.prev-inv-info h2 {
    font-size: 16px;
}

.prev-inv-info ul,
.po-info {
    padding-left: 0;
    list-style: none;
    font-size: 14px;
}

.prev-inv-info ul li {
    padding-bottom: 3px;
}

.prev-inv-info-right,
.prev-camp-info-right {
    margin-left: auto;
}

.po-info li span {
    width: 100px;
    display: inline-block;
}

.po-info li strong {
    margin-left: 3px;
}

.prev-inv-info.biller {
    display: block;
}

.prev-camp-info,
.prev-inv-info ol {
    font-size: 14px;
}

.prev-camp-info strong {
    margin-left: 3px;
}


#previewModal.modal-filter .modal-header {
    padding: 40px 15px 0 !important
}

#previewModal.modal-filter .modal-header .close {
    position: absolute;
    top: 10px;
    right: 15px;
}

.prev-inv-info ol {
    padding-left: 15px;
}

.sidebar-dropdown-menu {
    opacity: 0;
    height: 0;
    visibility: hidden;
    transform: scaleY(0);

}

.sidebar-active .sidebar-dropdown .sidebar-dropdown-menu.sidebar-dropdown-active {
    opacity: 1;
    height: auto;
    visibility: visible;
    transform: scaleY(1);

}

/* #sidebar-grey .sidebar-dropdown:hover .sidebar-dropdown-menu a {
  font-size: 14px !important;
} */
#sidebar-grey .brandrightmenu .sidebar-dropdown .sidebar-dropdown-menu a {
    text-align: left !important;
    padding-left: 50px !important;
    font-size: 14px !important;
    height: auto;
    border: none !important;
    line-height: 20px;
    padding-top: 5px !important;
    padding-bottom: 5px !important;
}

#sidebar-grey .sidebar-dropdown {
    position: relative;
}

#sidebar-grey .dropdown-icon {
    position: absolute;
    right: 5px;
    top: 4px;
    opacity: 0;
}

.sidebar-active #sidebar-grey .dropdown-icon {
    opacity: 1;
}

#sidebar-grey .dropdown-icon.icon-active {
    top: 15px;
}

#sidebar-grey .dropdown-icon i {
    font-size: 16px !important;
}

.sidebar-active .dropdown-icon {
    display: inline-block !important;
    position: absolute;
}

.sidebar-active .dropdown-icon.icon-active i:before {
    content: "\f286";
}

/* Invoice New PDF CSS */
.pdf-container {
    // padding: 30px;
    background-color: #fff;
    font-size: 14px;
    float: left;
    width: 100%;
    margin-bottom: 100px;
    @media only screen and (width < 768px) {
        margin-bottom: 20px;
    }
}

.pdf-header {
display: flex;
align-items: center;
width: 100%;
flex-wrap: wrap;
    padding-left: 30px;
    padding-right: 30px;
    margin-bottom: 15px;
    @media only screen and (width < 576px) {
            flex-direction: column;
            flex-wrap: nowrap;
            align-items: flex-start;
            padding-right: 15px;
            padding-left: 15px;
        }
}

.pdf-header-left {
}

.pdf-header-right {
 margin-left: auto;
 align-self: flex-end;
    border-left: 4px solid #8c8c8c;
    padding-left: 10px;
    font-size: 14px;
    @media only screen and (width < 576px) {
        margin-left: 0;
        align-self: flex-start;
        border-left: none;
        padding-left: 0;
    }
}



.pdf-header-right-list {
    padding-left: 0;
    list-style: none;
}

.pdf-header-left-btm {
    font-size: 14px;
}

.pdf-header-left-btm strong {
    font-size: 14px;
}

.contact-info {
    list-style: none;
    padding-left: 0;
}

.pdf-header-right-list li i {
    margin-right: 5px;
}

.invoice-det {
    padding: 15px 30px;
    background-color: #8c8c8c;
    display: flex;
    align-items: center;
    width: 100%;
    color: #fff;
    margin-bottom: 15px;
    @media only screen and (width < 576px) {
        align-items: center;
        padding: 15px;
    }
}

.invoice-det-left {
    text-transform: uppercase;
    font-size: 22px;
    font-weight: bold;
    float: left;
    margin-top: 5px;
    @media only screen and (width < 576px) {
        float: none;
        font-size: 16px;
    }

}

.invoice-det-right {
    margin-left: auto;
    display: flex;
    @media only screen and (width < 576px) {
        flex-direction: column;
        align-items: flex-end;
    }

}

.invoice-det-right-btm-left {
    display: flex;
    margin-right: 15px;
    border-right: 1px solid #e6e6e6;
    padding-right: 15px;
    align-items: center;
    @media only screen and (width < 576px) {
            
            border-right: none;
            padding-right: 0;
            margin-right: 0;
        }
}

.invoice-det-right-btm-right {
    display: flex;
    align-items: center;
    
}

.invoice-det-right-btm-right ul {
    margin-bottom: 0;
    padding-left: 0;
    list-style: none;
}

.invoice-det-right-btm-right ul span {
    
    text-align: right;
    padding-right: 10px;
    @media only screen and (width < 576px) {
        padding-right: 0;
    }
}
.invoice-det-right-btm-right ul li {
    display: flex;
    align-items: center;
    @media only screen and (width < 576px) {
            
            align-items: center;
        }
}
.invoice-biller-info {
    float: left;
    width: 100%;
    padding-left: 5px;
    padding-right: 5px;
    margin-bottom: 25px;
    @media only screen and (width < 576px) {
        padding-left: 15px;
            padding-right: 15px;
    }
}

.invoice-biller-info-left {
    display: flex;
    flex-wrap: wrap;
    width: 50%;
    border-right: 1px solid #eee;
    @media only screen and (width < 576px) {
        width: 100%;
        border-right: none;
        flex-direction: column;
    }


}
.table-package-costing tbody td:nth-of-type(3),
.table-package-costing th:nth-of-type(3),

.table-individual-costing tbody td:nth-of-type(5),
.table-individual-costing tbody td:nth-of-type(4),
.table-individual-costing th:nth-of-type(5),
.table-individual-costing th:nth-of-type(4) {
    @media only screen and (width < 768px) {
    display: none;
    }
}
.prev-inv-table .table-package-costing tbody td,
.prev-inv-table .table-individual-costing tbody td {
    @media only screen and (width < 768px) {
             border-bottom: 1px solid #eee;
        }

}
.biller-right {
    margin-left: 10px;
    @media only screen and (width < 576px) {
        margin-left: 0;
    }

}
.summary-table {
    padding-top: 10px;
    border-top: 1px solid #000;
    margin-top: 30px;
    @media only screen and (width < 768px) {
        border-top: none;
            margin-top: 0px;
    }

}
.summary-table tr {
    border: none;
    td{
        &:last-of-type {
            @media only screen and (width < 768px) {
                padding-right: 15px;
            }
        }
    }
}

.invoice-biller-info-right {
    float: right;
}

.invoice-biller-info-right strong {
    text-align: right;
    width: 150px;
    float: left;
    margin-right: 5px;
}
.prev-inv-table {
    @media only screen and (width < 768px) {
        margin-bottom: 0;
    }
}
.prev-inv-table.table thead,
.prev-inv-table.table .sub-table tfoot {
    box-shadow: none;
    border: none;
    background-color: #eee;
    padding-left: 10px;
    padding-right: 10px;
}
.prev-inv-table tr th:last-of-type {
    text-align: right;
}

.prev-inv-table.table thead th {
    border-bottom: none;
    border-top: none;
    padding: .5rem;
    font-size: 14px;
}

.prev-inv-table.table thead th:first-of-type,
.prev-inv-table.table tbody td:first-of-type,
.prev-inv-table.table .sub-table tfoot td:first-of-type,
.prev-inv-table.table .inv-table-footer td:first-of-type {
    padding-left: 30px;
    @media only screen and (width < 768px) {
        padding-left: 15px;
    }
}
.prev-inv-table.table .table-package-costing thead th:last-of-type,
.prev-inv-table.table .table-package-costing tbody td:last-of-type,
.prev-inv-table.table .table-package-costing .sub-table tfoot td:last-of-type,
.prev-inv-table.table .table-package-costing .inv-table-footer td:last-of-type,
.prev-inv-table.table .table-individual-costing thead th:last-of-type,
.prev-inv-table.table .table-individual-costing tbody td:last-of-type,
.prev-inv-table.table .summary-table tr td:last-of-type,
.prev-inv-table.table .table-individual-costing .inv-table-footer td:last-of-type {
    padding-right: 30px;
    @media only screen and (width < 768px) {
            padding-right: 15px;
        }
}


.prev-inv-table.table td,
.prev-inv-table.table tbody th {

    padding: .5rem;
    font-size: 14px;
}
.prev-inv-table th,
.prev-inv-table td {
    font-family: $font-family-body;
}

.prev-inv-table.table td {
    padding: .25rem .5rem;
    border: none;
}

.prev-inv-table.table .sub-table td:first-of-type {
    text-align: right;
    padding-left: 0;
}

.prev-inv-table.table .sub-table {
    border-top: 1px solid #eee;
}

.prev-inv-table.table .sub-table td:last-of-type {
    padding-left: 15px;
    padding-right: 0px;
}

.inv-table-footer {
    border-bottom: 1px solid #979797;
}

.inv-sign {
    float: right;
}

.invoice-biller-info-right {
    width: 50%
}

.invoice-biller-info-right ul {
    display: flex;
    list-style: none;
    flex-wrap: wrap;
    padding-left: 0
}

.invoice-biller-info-right ul li {
    float: left;
    width: 50%;
    border: 1px solid #eee;
    padding: 5px 20px;
    border-bottom: none;
}

.invoice-biller-info-right ul li:nth-child(2n) {
    padding-right: 0;
    border-left: none
}

.invoice-biller-info-right ul li:first-of-type,
.invoice-biller-info-right ul li:nth-of-type(2) {
    border-top: none
}

.invoice-biller-info-right ul li:last-of-type {
    width: 100%;
    min-height: 140px
}

.table-bg-color td {
    background-color: #eee;
}

.inv-footer {
    float: left;
}


// To show download invoice icon 
// .invoice-send {
//   display: flex;
//   justify-content: flex-end;
//   padding: 8px 0px;
//   position: sticky;
//   bottom: 0;
//   background: #fff;
//   width: 100%;
//   z-index: 99;
// }

.invoice-send {
    display: flex;
    justify-content: flex-end;
    padding: 12px 15px;
    position: fixed;
    bottom: 0;
    background: #fff;
    width: 100%;
    z-index: 99;
    right: 0px;
}

.invoice-container {
    @media only print {
        padding-bottom: 20mm;
        /* Adjust as needed */
        margin-bottom: 0;
    }
    /* Ensure no margin collapse */
}

/* Add space at the start of each new page */
.page-start {
    padding-top: 20mm;
    /* Adjust as needed */
}

/* Avoid page breaks inside specific elements */
.avoid-page-break {
    page-break-inside: avoid;
}

.break-before {
    page-break-before: always;
}

.break-after {
    page-break-after: always;
}

.break-avoid {
    page-break-inside: avoid;
}

// .fullpage {
//     height: 792pt;
// }'


// .pdf .invoice-container {
//     min-width: 900pt;
//     max-width: inherit;
// }


.invoice-expandable {
    cursor: pointer;
    color: $color-body;
    padding-bottom: 2px;
    border-bottom: 1px solid #000;
    position: relative;
    &:hover{
border-bottom: 1px solid #666;
color: #666;

    }

}
.invoice-expandable.active::after {
position: absolute;
    top: calc(100% - 6px);
    width: 0px;
    height: 0px;
    display: block;
    content: "";
    left: 22px;
    border-width: 10px;
    border-style: solid;
    border-color: transparent transparent #ddd transparent;
}
.expandable {
    display: flex;
    flex-wrap: wrap;
    width: 47%;
    justify-content: flex-end;
    margin-left: auto;
    padding: 0 !important;
    visibility: hidden;
    height: 0;
    border-bottom: none;
    margin-top: 2px;
    &.active {
        height: auto;
        visibility: visible;
    }
}


.invoice-detail-table-header {
    background: #eee;
    th {
        font-family: $font-family-body; 
        font-size: 14px;
        line-height: 1;
    }
}


.invoice-detail-table {
    border: 1px solid rgba(0, 0, 0, 0.3);
    border-top: none;
}
.invoice-detail-table-body {
    

    td {
        font-family: $font-family-body;
        font-size: 14px;
        line-height: 1;
    }
}

.common-table-body .invoice-detail-table .invoice-detail-table-header th,
.common-table-body .invoice-detail-table .invoice-detail-table-body td {
    border-bottom: 1px solid rgba(0, 0, 0, 0.3);
    border-left: 1px solid rgba(0, 0, 0, 0.3);
}