.bank-detail-card {
  padding: 0px;

  .default-selected {
    background-color: #e3e3e3;
    padding: 0px;
    font-weight: bold;
    z-index: 0;


    .radio-button {
      padding: 0px;
      margin: 0px;
    }


  }

  .bankDetails {
    padding: 10px 0px;
    font-size: $font-size-sml;

    .row {
      margin-left: 0;
      margin-right: 0;
      padding-bottom: 5px;
      border-bottom: 1px solid rgba(0, 0, 0, 0.1);
      margin-bottom: 5px;

      &:last-of-type {
        border-bottom: none;
      }


    }
  }
}


.bankDetails {}

// .influencer_payment_tabs  {

//     display: flex;
//     justify-content: center;
//     align-items: center;
//     margin: 10px;
//     margin-right: 35px;
// }

._influencer_main_account {
  display: flex;
  border: 1px solid #5d5d5d;
  border-radius: 10px;
  align-items: center;
  text-align: center;
  justify-content: center;
  width: 300px;
  height: 100px;

  // width: 100%;

  // border: 1px solid #5d5d5d;
  // min-height: 135px;
  // display: flex;
  // align-items: center;
  // border-radius: 8px;
  // padding: 20px;
  // flex-direction: column;
}

.influencer_payment_tabs li {
  display: flex;
  background: #fff;
  border-radius: 6px;
  cursor: pointer;
  width: 300px;
  // height: 120px;
  justify-content: center;
  align-items: center;
}

.influencer_payment_tabs li.active {
  background: #002ae5;
  width: 100px;
  // height: 100px;
  color: white;
}

.tab_content {
  /* padding: 10px; */
  background: #fff;
  /* border-radius: 5px; */
  min-height: 180px;
  // margin-top: 25px;
  padding: 0;

  .payment-cancel {
    cursor: pointer
  }
}

.account.active {
  align-items: center;
}

.account_details .account_details_main_row .col-12 {
  // margin-top: 10px;
}

.influencer_uppercase {
  border-bottom: 1px solid rgb(218, 211, 211);
}

.shipping-drawer.popup-drawer .MuiDrawer-paper,
.payment-drawer.popup-drawer .MuiDrawer-paper {
  width: 100%;
  border-radius: 10px 0px 0 10px;

  @include breakpoint(xsmall) {
    width: 60%;
  }

  @include breakpoint(medium) {
    // width: 30%;
    width: clamp(400px, 40%, 400px);
  }
}

.agreement-drawer.popup-drawer .MuiPaper-elevation {
  width: 100%;
  border-radius: 10px 0px 0 10px;

  @include breakpoint(xsmall) {
    width: 60%;
  }

  @include breakpoint(medium) {
    width: 36%;
  }
}

// @media only screen and (max-width: 479px)