body.no-scroll {
  overflow: hidden;
}

.camcon_result_container {
  background-color: #f9fafc;
  font-family: "Open Sans", sans-serif;
  color: #2b2b2b;
  // padding-top: 100px;
  display: flex;
  justify-content: center;

  .alert {
    position: relative;
    padding: 0.75rem 1.25rem;
    margin-bottom: 1rem;
    border: 1px solid transparent;
    border-radius: 0.25rem;
  }

  .alert-blue {
    color: #fff;
    background-color: #36a1d1;
    border-color: #36a1d1;
  }

  .btn-1 {
    width: 208px;
    padding: 10px 36px 10px 38px;
    border-radius: 10px;
    border: solid 1px;
    margin-bottom: 15px;
  }

  .config-list {
    gap: 0;
    padding-bottom: 10px;
    margin-left: auto !important;
  }

  .config-list.action-list span {
    color: inherit;
    line-height: 1.5;
    padding-bottom: 0;
  }
}

.camcon_result_container_left {
  padding-left: 15px;
  padding-right: 15px;
  height: calc(100vh - 110px);
  overflow-y: auto;
  padding-bottom: 30px;
}

.btn-link {
  font-weight: 400;
  // padding: 22px 0.75rem;
  // color: #007bff;
  text-decoration: none;
}

.action-list span {
  font-size: 14px;
  line-height: 19px;
}

.action-list .bootstrap-select>.dropdown-toggle {
  border: 1px solid #aaa;
}

.bootstrap-select:not(.input-group-btn),
.bootstrap-select[class*="col-"] {
  float: none;
  display: inline-block;
  margin-left: 0;
  margin-top: 10px;
}

// .bootstrap-select > select {
//   position: absolute !important;
//   bottom: 0;
//   left: 50%;
//   display: block !important;
//   width: 0.5px !important;
//   height: 100% !important;
//   padding: 0 !important;
//   opacity: 0 !important;
//   border: none;
// }

.action-list .bootstrap-select>.dropdown-toggle {
  border: 1px solid #aaa;
}

.bootstrap-select>.dropdown-toggle.bs-placeholder,
.bootstrap-select>.dropdown-toggle.bs-placeholder:active,
.bootstrap-select>.dropdown-toggle.bs-placeholder:focus,
.bootstrap-select>.dropdown-toggle.bs-placeholder:hover {
  color: #999;
}

.bootstrap-select>.dropdown-toggle {
  position: relative;
  min-width: 100px;
  z-index: 1;
  text-align: right;
  white-space: nowrap;
  font-size: 20px;
  color: #5d5d5d;
  font-weight: 300;
}

.bootstrap-select .dropdown-toggle .filter-option {
  position: absolute;
  top: 0;
  left: 0;
  padding-top: inherit;
  padding-right: inherit;
  padding-bottom: inherit;
  padding-left: inherit;
  height: 100%;
  width: 100%;
  text-align: left;
}

.influencers-summary-tab-content .infl-type-card img {
  max-height: 54px;
  max-width: inherit;
  margin-right: 8px;
}

.influencers-summary-tab-content .infl-type-card {
  border-color: #061b26;
  margin-bottom: 15px;
}

.camcon_card_container {
  flex: 1;
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
  max-width: 1017px;
  margin: 0 -10px;
}

.config_card {
  flex: 0 0 calc(33.33% - 20px);
  padding: 19px 31px 24px 28px;
  border-radius: 18px;
  background-color: #fff;
  border: 1px solid #aaa;
  margin-left: 10px;
  margin-right: 10px;
  width: calc(33.33% - 20px);
  margin-bottom: 20px;
  position: relative;
}

.config_card .recomm-ricon {
  position: absolute;
  right: 15px;
  top: -6px;
}

.checkboxcontainer {
  display: block;
  position: relative;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 22px;
  user-select: none;
}

.camcon_card_container.ScrollStyle {
  flex: inherit;
  padding-top: 5px;
}

.config_card_info {
  display: flex;
  justify-content: space-between;
}

.config_card .shape {
  width: 22px;
  height: 22px;
  margin-top: 5px;
}

.config_card .influencer_type_img {
  width: 36px;
  height: 54px;
  margin: 0 7px 4px 0;
  object-fit: contain;
}

.config_card_info .config_card_influencer_img {
  width: 54px;
  height: 54px;
  border-radius: 100%;
}

.infulencer-name {
  width: 114px;
  height: 22px;
  font-family: "Open sans", sans-serif;
  font-size: 16px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #2b2b2b;
}

.camcon_result_container .infulencer-name {
  width: 100%;
  height: auto;
  padding-bottom: 30px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.vl-card {
  width: 1px;
  height: 42px;
  border: solid 1px #bcbcbc;
}

.config_card_analytics .vl-card {
  border: none;
  border-right: solid 1px #bcbcbc;
}

.config_card_analytics span {
  font-size: 16px;
  font-weight: bold;
}

///

.camcon_info_container {
  flex: 3;
  background-color: #092838;
  color: white;
  max-width: 400px;
}

.tab-sec {
  display: flex;
  flex-direction: column;
}

.camcon_result_container .tab-btns {
  display: flex;
  align-items: center;
  justify-content: space-around;
  border: none;
  cursor: pointer;
  transition: 0.3s;
  font-size: 18px;
  flex: 1;
  z-index: 1;
  margin-bottom: -1px;
  background-color: inherit;
  border-bottom: 1px solid #ccc;
}

.camcon_result_container button.active {
  outline: none;
  opacity: 1;
  color: #00aae5;
  font-weight: 700;
}

.tablinks {
  background-color: transparent;
  border: none;
  color: #fff;
}

.camcon_result_container .tablinks {
  flex: 1;
  padding: 17px;
}

#Overview.tabcontent {
  height: calc(100vh - 130px);
  overflow-y: auto;
  padding-bottom: 30px !important;
}

.camcon_result_container .tabcontent {
  display: none;
  border-top: none;
}

.camcon_result_container .total-influencer {
  padding-top: 24px;
  padding-bottom: 32px;
}

.total-influencer {
  max-width: 100%;
  padding-bottom: 42px;
}

.total-influencer-row1 span {
  font-size: 18px;
  color: white;
  font-weight: 600;
  line-height: 1;
}

.total-influencer-row2 {
  padding-top: 21px;
}

.campaignConfo-common {
  padding-top: 23px;
  padding-bottom: 17px;
}

.campaignConfo-common label {
  font-size: 14px;
  line-height: 19px;
}

.campaignConfo-common span {
  font-size: 18px;
  line-height: 19px;
}

.campaign_confo_hr {
  margin-top: 0px;
  margin-bottom: 0px;
  margin-left: -16px;
  margin-right: -18px;
  border: solid 1px #fff;
  opacity: 0.2;
}

.analysis-rate {
  gap: 11px;
  padding-top: 23px;
  padding-bottom: 37px;
}

.analysis-rate span {
  font-size: 18px;
  line-height: 1;
}

.analysis-rate label {
  font-size: 14px;
  line-height: 1;
}

.common-campco {
  padding-top: 19px;
  padding-bottom: 7px;
}

// .row {
//   display: flex;
//   flex-wrap: wrap;
//   margin-right: -15px;
//   margin-left: -15px;
// }

.heading {
  margin-top: 20px;
}

.custom-container {
  max-width: 1280px;
}

.container-configuration {
  padding: 20px;
  background: #fff;
  margin-bottom: 30px;
  border: 1px solid #aaa;
  border-radius: 13px;
}

.infl-type-card {
  display: flex;
  flex-wrap: wrap;
  font-size: 14px;
  margin-right: 8px;
  border-right: 1px solid #eee;
  padding-right: 8px;
  min-width: 140px;
}

.infl-type-card img {
  max-width: 50px;
  margin-right: 10px;
}

.microplus {
  height: 72px;
}

.infl-type-card-content {
  display: flex;
  flex-direction: column;
}

.infl-type-card-content strong {
  padding-right: 10px;
  line-height: 1;
  padding-bottom: 2px;
  font-weight: 600;
}

.infl-type-card-content span {
  color: #777;
  font-size: 12px;
}

.infl-type-card-perc {
  font-weight: 600;
  font-size: 16px;
}

.highchartmain {
  height: 163;
  width: 153px;
}

.card-project-configurator {
  margin: 0 0px 15px 0;
  border-radius: 13px;
  border-style: solid !important;
  border-width: 1px !important;
  border-image-slice: 1 !important;
  width: 100% !important;
  padding: 24px 16px;
  border: 1px solid #aaa;

  .infl-type-card {
    margin-bottom: 15px;
  }

  .card-title {
    font-weight: 600 !important;
    font-size: 24px;
    padding-bottom: 10px;
  }
}

.conf-list-detail {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
}

.conf-list-detail-left .infl-type-card img {
  max-width: 35px;

  .microplus2 {
    height: 72px;
  }
}

.infl-search-res-value {
  padding-top: 15px;
}

// .card-content div span {
//   font-size: 14px;
//   font-weight: normal;
// }

.card-content div span.infl-search-res-value-count {
  font-size: 18px;
  font-weight: 600;
}

.checkboxcontainer input:checked~.checkmark {
  background-color: #00aae5 !important;
}

.config_card .recomm-ricon .checkmark {
  border-radius: 4px;
  height: 22px;
  width: 22px;
}

.checkboxcontainer {
  display: block;
  position: relative;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }

  .checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 25px;
    width: 25px;
    background-color: #fff;
    border: 1px solid #00aae5;
    border-radius: 50%;
  }
}

.checkboxcontainer input:checked~.checkmark:after {
  display: block;
}

.checkboxcontainer .checkmark::after {
  left: 7px;
  top: 3px;
}

.checkboxcontainer .checkmark:after {
  // left: 9px;
  // top: 4px;
  width: 6px;
  height: 12px;
  border: solid white;
  border-width: 0px 2px 2px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

.config_card .recomm-ricon .checkmark {
  border-radius: 4px;
  height: 22px;
  width: 22px;
}

.highcharts-axis-labels>text {
  color: rgb(51, 51, 51);

  fill: rgb(251, 251, 251);
}

#Overview .action-list {
  color: #00aae5;
  gap: 12px;
  margin-top: 15px;
  margin-left: 10px;
  text-decoration: none;
}

.action-list a {
  text-decoration: none;
  color: #00aae5;
}

#age-gender-chart {
  margin-bottom: 10px;
}

//Progress-Bar

.progress-section {
  width: 100%;
  max-width: 600px;
  border-radius: 7px;
  background-color: rgba(0, 170, 229, 0.22) !important;
  padding: 1rem 1rem;
  margin: 0 auto;

  .task-progress {
    // margin: 0.4rem 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .task-progress p {
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: #eee;
    margin-bottom: 5px;
    font-size: 13px;
  }

  span {
    color: #fff;
    font-weight: 600;
    font-size: 13px;
  }

  .progress {
    // width: 100%;
    -webkit-appearance: none;
    appearance: none;
    border: none;
    border-radius: 10px;
    height: 5px;
  }

  .city {
    display: flex;
    align-items: center;
    column-gap: 10px;
  }

  .percentage {
    display: flex;
    align-items: center;
    column-gap: 10px;
    margin-right: 5px;
  }

  .progress::-webkit-progress-bar {
    background-color: #000000;
  }

  .progress::-webkit-progress-bar,
  .progress::-webkit-progress-value {
    border-radius: 10px;
  }

  .progress::-moz-progress-bar {
    border-radius: 10px;
  }

  .progress1::-webkit-progress-value {
    background: #007bff;
  }

  .progress2::-webkit-progress-value {
    background: #007bff;
  }

  .progress3::-webkit-progress-value {
    background: #007bff;
  }

  .progress4::-webkit-progress-value {
    background: #007bff;
  }

  .progress5::-webkit-progress-value {
    background: #007bff;
  }

  .progress5::-webkit-progress-value {
    background: #007bff;
  }
}

@media only screen and (max-width: 1200px) {
  .camcon_card_container {
    .config_card {
      flex-grow: 0;
      flex-shrink: 0;
      flex-basis: calc(49% - 20px);
    }
  }
}

@media only screen and (max-width: 991px) {
  .camcon_result_container {
    .camcon_info_container {
      max-width: 350px;
    }
  }
}

@media only screen and (max-width: 767px) {
  .camcon_card_container {
    .config_card {
      flex-grow: 0;
      flex-shrink: 0;
      flex-basis: calc(100% - 20px);
    }
  }

  .camcon_result_container {
    .camcon_info_container {
      max-width: 350px;
    }
  }
}

@media only screen and (max-width: 575px) {
  .camcon_result_container {
    .camcon_info_container {
      max-width: 300px;
    }
  }
}