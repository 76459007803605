.main-chat-inbox-container {
  display: flex;
  background: #f2f2f2;
  padding: 15px 15px 15px 15px;
  width: 100%;
  display: flex;
  //   flex-wrap: wrap;
  border-radius: 10px;
  height: calc(100svh - 240px);

  .proposal-status {
    margin-left: 10px;
    text-align: center;
    min-width: 75px;
  }

  .proposal-status-influencer{
    width: 150px;
  }

  .list-container {
    width: 25%;
    min-width: 300px;
  }

  .chat-container {
    flex-grow: 1;
    margin-left: 10px;
    min-width: 300px;

    .inbox {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 100%;

      .in-inbox {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        .icon {
          .MuiSvgIcon-root {
            font-size: 30px;
          }
        }

        .p-1 {
          margin: -10px;
          font-size: 14px;
          font-weight: 700;
          text-align: center;
        }

        .p-2 {
          text-align: center;
        }
      }
    }
  }

  .list-container-box {
    min-width: 260px;
    height: 100%;
  }

  .search-inbox-chat {
    background-color: #fff;
    border-radius: 10px;
    margin-bottom: 10px;
    min-width: 260px;

    .MuiOutlinedInput-root {
      border-radius: 10px;
    }
  }

  .pro-inf-list-container-inbox {
    border-radius: 5px;
    background: #fff;
    /* Fixed height for the container */
    position: relative;
    min-width: 260px;
    overflow: hidden;
    height: calc(100% - 65px);
    overflow-y: auto;
    /* Prevent overflowing of content outside the div */
  }

  .profile-ul {
    padding: 0px;
    /* Let ul take up full container height */
    
    /* Allow vertical scrolling */
  }

  .profile-li {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
    cursor: pointer;
    background-color: #fff;
    text-overflow: ellipsis;
    overflow: hidden;
    position: relative;

    .profile {
      display: flex;
      align-items: center;
      // width: 190px;
      overflow: hidden;
      position: relative;
      width: 100%;

      span {
        text-wrap: nowrap;

        &:nth-child(1) {
          font-size: 16px;
          font-weight: 700;
          line-height: 24px;
        }

        &:nth-child(2) {
          font-size: 14px;
          font-weight: 400;
          line-height: 20px;
        }
      }
    }
    .unread-msg {
      position: absolute;
      top: 14px;
      right: 0px;
    }
  }

  .selected-campaign {
    border-bottom: 1px solid #cdcdcd;
    padding: 10px;
  }

  .active-profile {
    background-color: #e6e6e6;
  }

  .pro-inf-chat-container-chat {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    border-radius: 5px;
    // box-shadow: 0px 1px 5px 2px #0000002B;
    // background: #FFFFFF;
    box-sizing: border-box;
    // border: 1px solid transparent;
    // width: 98%;
    // margin: 0px 1rem;
    // height: $chatbox-height;
    height: 100%;
    // padding: 0 1rem;

    @media (width < 768px) {
      width: 100%;
      max-width: 100%;
      margin-left: auto;
      margin-right: 0;
    }

    ul {
      margin: 0;
      padding: 0;
      list-style: none;

      li {
        display: flex;
        align-items: center;
        width: 100%;
        margin: 0;
        font-size: 14px;
        font-weight: 400;
        line-height: 16.34px;
        flex-direction: column;
      }
    }
  }

  .influencer-chat-header-chat {
    display: flex;
    align-items: center;
    padding: 10px 16px 10px 16px;
    // border-radius: 60px;
    border-bottom: 1px solid #cdcdcd;
    background-color: #ffffff;
    // background: $color-f2f2f2;
    // margin: 1rem 0px 0.625rem;
    // height: $chatbox-header-height;

    .pro-detail {
      display: flex;
      align-items: center;
      width: 100%;

      section {
        min-width: 100px;
        overflow: hidden;
        margin: 0px 10px 0px 0px;
        text-overflow: ellipsis;

        span {
          text-wrap: nowrap;

          &:nth-child(1) {
            font-size: 16px;
            font-weight: 700;
            line-height: 24px;
            color: #000000;
          }

          &:nth-child(2) {
            font-size: 14px;
            font-weight: 400;
            line-height: 20px;
            color: #000000;
          }
        }
      }

      .proposal-status {
        margin-left: auto;
        /* Moves the proposal status to the right */
        font-size: 14px;
        font-weight: 500;
        color: #000000;
      }

      .back-btn {
        cursor: pointer;
        margin-right: 10px;
      }
    }
  }

  .chat-view-chat {
    flex: 1;
    overflow: hidden;
    overflow-y: auto;
    scroll-behavior: smooth;
    background-color: #ffffff;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    padding-top: 20px;

    .child-ul-chat {
      // border: 1px solid black;
      display: flex;
      width: 100%;
      height: 100%;
      flex-direction: column;
      overflow-y: auto;
      // height: calc($chatbox-height - $chatbox-header-height - $conversion-height);
      padding: 0px 10px;
      // overflow-x: hidden;
      margin-bottom: 30px;

      .child-li-chat {
        display: flex;
      }
    }

    .parent-ul-chat {
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      width: 100%;

      .to {
        justify-content: flex-end;
        flex-direction: end;
        flex-direction: row;

        .profile-image-size {
          margin-right: 0;
        }

        span {
          padding: 8px 12px 8px 12px;
          border-radius: 10px 0px 10px 10px;
          background: #e6e6e6;
          margin-right: 10px;
          word-break: break-word;
          max-width: 600px;
        }
      }

      .from {
        flex-direction: row-reverse;
        justify-content: start;

        span {
          padding: 8px 12px 8px 12px;
          border-radius: 0px 10px 10px 10px;
          background: #ffffff;
          border: 1px solid #c9ccd2;
          word-break: break-word;
          max-width: 600px;
        }
      }
    }
  }

  .conversion-section {
    margin: 10px 0px 5px 0px;
    // height: $conversion-height;
    position: relative;
    // border: 1px solid black;

    .send_chat_disable_input , .send_chat_input{
      background-color: #fff;
    }

    .send-btn {
      display: flex;
      margin: 0px;
      padding: 0px;
      background: transparent;
      border: none;
      position: absolute;
      right: 10px;
      bottom: calc(50% - 10px);
      font-size: 1.5rem;
      width: auto;
      color: #000;

      @media (width < 768px) {
        bottom: calc(50% - 10px);
      }

      .send-icon {
        font-variation-settings: "FILL" 1, "wght" 700, "GRAD" 0, "opsz" 48;
      }
    }

    input[type="text"] {
      width: 100%;
      // padding: 0.625rem 2.5rem 0.625rem 2.75rem;
      padding: 0.625rem 2.5rem 0.625rem 1.25rem;
      border-radius: 5px;
      border: 1px;
      // background: $color-f2f2f2;
    }
  }

  .mobile-view {
    width: 100%;
    .influencer-chat-header-chat {
    .proposal-status {
      font-size: 12px;
      }
    }
  }

  @media (width < 700px) {
    .chat-container {
      margin-left: 0px;
      height: 100%;
    }

    .influencer-chat-header-chat {
      padding: 10px;

      .MuiSvgIcon-root {
        font-size: 15px;
      }
    }
  }
}

.chat-back-btn {
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #000;
  border-radius: 50%;
  margin-right: 10px;
  background-color: #fff;
  cursor: pointer;
  min-width: 30px;
  &:hover{
    background-color: #000;
    color: #fff;
  }
}

.active-chat-btn {
  border: 1px solid #2b2b2b;
}

.main-inbox-container{
  margin: 10px 20px;
  height: calc(100svh - 155px);
}

.btn-class-chat {
  margin-bottom: 10px;
}

.unread-msg {
  background-color: #00aae5;
  color: #ffffff;
  border-radius: 50%;
  padding: 0px 5px;
  font-size: 12px;
  margin-left: 5px;
  width: 20px;height: 20px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}

input.send_chat_disable_input::placeholder {
  opacity: 0.3;
}

.conversion-section .send-btn[disabled] {
  opacity: 0.2;
}

.not_shortlisted_msg{
  font-size: 11px;
  font-weight: 400;
  line-height: 14.98px;
  padding: 5px 10px;
  border: 1px solid #E3E8F1;
  border-radius: 10px;
  background: #F9FAFC;
  text-align: center;
  margin-bottom: 10px;
  margin-left: 10px;
  margin-right: 10px;
  color: #d32f2f;
}