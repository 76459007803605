.custom-drawer {
  background: #fff;

  border: .0625rem solid $border-campaign-detail-card;
  border-radius: $border-radius__10;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, 500%);
  transform-origin: bottom center;
  z-index: -1;
  transition: all 0.35s cubic-bezier(0.65, 0, 0.35, 1);
  max-width: 31.25rem;
  width: 100%;
  opacity: 0;
  visibility: hidden;
  max-height: 100vh;
  overflow-y: auto;


  @media (width > 81.25em) {
    max-width: max-content;
    max-height: 90vh;
  }

  &.active {
    z-index: 9999;
    transform: translate(-50%, -50%);
    transition: transform 0.35s cubic-bezier(0.65, 0, 0.35, 1);
    opacity: 1;
    visibility: visible;

    // @media (width < 768px) {
    //   border-radius: 10px 0 0 0;
    // }

  }

  .custom-drawer-header {
    padding: 12px 24px 12px 32px;
    border-bottom: .0625rem solid $border-campaign-detail-card;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    position: sticky;
    top: 0;
    background-color: $color-white;
    z-index: 99;
  }

  .custom-drawer-close {
    margin-left: auto;
  }

  .custom-drawer-title {
    font-weight: normal;
    padding-right: 16px;
  }

  .custom-drawer-body {
    padding: 0px 12px;

    @include breakpoint(small) {
      padding: 0px 32px;
    }
  }

  .custom-drawer-footer {
    padding: 16px 32px;
    position: sticky;
    bottom: 0;
    background: #fff;
    width: 100%;
    z-index: 99;

    @include breakpoint(small) {
      margin-left: -32px;
      width: calc(100% + 64px);

    }
  }

  .custom-drawer-body-inner {
    padding-top: 1.25rem;
    padding-bottom: 1.25rem;
  }

  &.custom-drawer-lg {
    max-width: 90%;
    max-height: 90vh;

    @include breakpoint(small) {
      max-width: 80%;
      max-height: 85vh;
    }
  }

  &.custom-drawer-negotiation {
    @media only screen and (width < 768px) {
      height: 80%;
      max-height: inherit;
      max-width: 100%;
      translate: none;
      bottom: 0;
      top: auto;
      left: 0;
      transform: none;

      .custom-drawer-footer {
        padding: 8px 32px;
      }
    }
  }

  &.popup-confirm {
    @media only screen and (width < 768px) {

      transform: translate(0, 0);
      top: auto;
      bottom: 0;
      left: 0;
      max-width: 100%;
      border-radius: .625rem .625rem 0 0;
    }

    .custom-drawer-header {
      border-bottom: none;
    }
  }

}


.negotiate-user-info {
  // margin-bottom: 20px;

  span {
    &:first-of-type {
      font-size: 1.5rem;
      color: #000;
      margin-right: .625rem;
    }

    .negotiate-user-name {
      color: #6a6a6a;
    }

  }
}






.custom-drawer-active {
  overflow: hidden;

  &:after {
    content: "";
    background-color: rgba(0, 0, 0, 0.6);
    position: fixed;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 9998;

  }
}


.chat-drawer {
  bottom: 0;
  overflow-y: auto;
  transform: translateX(62.5rem);
  transition: all 0.35s ease;
  background-color: #f8f8f8;
  border: .0625rem solid $color-838383;
  border-bottom: none;
  border-top: none;
  border-right: none;
  border-radius: 0 10px 10px 0;
  position: fixed;
  z-index: 99;
  height: 80vh;
  left: 0;

  @include breakpoint(small) {
    position: absolute;
    right: 0%;
    left: auto;
    z-index: 1;
    width: 0;
    height: 31.25rem;
    transform: translateX(18.75rem);

  }

  &.active {
    width: 100%;

    @include breakpoint(small) {
      width: 18.75rem;
    }

    transform: translateX(0rem);
  }
}

.chat-drawer-header {
  padding: 12px 24px 12px 32px;
  border-bottom: .0625rem solid #D7D7D7;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  position: sticky;
  top: 0;
  background-color: #ffffff;
  z-index: 99;
  border-radius: .625rem .625rem 0 0;

  .custom-drawer-close {
    margin-left: auto;
  }

  @include breakpoint(small) {
    display: none;
  }
}

@media only screen and (width < 48rem) {
  body.chat-drawer-overlay {
    overflow: hidden;

    &:after {
      position: fixed;
      content: "";
      background: rgba(0, 0, 0, 0.5);
      height: 100%;
      width: 100%;
      z-index: 9;
      left: 0;
      top: 0;
    }

    .chat-drawer.active {
      border-radius: .625rem .625rem 0 0;
    }
  }
}


.confirm-body {
  text-align: center;
  margin-bottom: 30px;

  .confirm-icon {
    font-size: 40px;
  }

  .confirm-title {
    font-size: 20px;
    padding-bottom: 10px;
  }
}

.custom-drawer-close {
  span {
    font-size: 30px;
  }
}

.custom-drawer-modal-open {
  position: relative;

  body {
    overflow: hidden;
  }

  &:after {
    background: rgba(0, 0, 0, 0.5);
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 999;
    top: 0;
    left: 0;
  }
}

.popup-approved {
  .custom-drawer-header {
    padding-top: 0;
    padding-bottom: 0;
    border-bottom: none;

    .custom-drawer-close {
      position: absolute;
      top: .625rem;
      right: .625rem;
    }
  }

  .custom-drawer-body {
    padding-bottom: 1.875rem;
    height: clamp(75vh, 550px, 80vh);

    h2 {
      font-size: 1.25rem;
      padding-top: 1.25rem;
      padding-bottom: 1.25rem;
      font-weight: 500;
      position: sticky;
      z-index: 1;
      background-color: $color-white;
      top: 0;
    }
  }

  &.custom-drawer-lg {
    bottom: 0;
    top: auto;
    transform: none;
    left: 0;
    max-width: 100%;
    border-radius: 10px 10px 0 0;
  }

  &.popup-approved-chat {
    .custom-drawer-body {
      .creative-detail {
        @media (width < 48rem) {
          padding-bottom: 0;
        }
      }

      @media (width < 48rem) {
        padding-bottom: 0;
      }
    }
  }
}

.custom-drawer.share-drawer {
  max-width: clamp(18rem, 31.25rem, 90%);
}