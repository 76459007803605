.curated-list-sec.MuiFormGroup-root {
  display: flex;
  overflow: hidden;
  overflow-x: auto;
  justify-content: space-between;
  flex-wrap: nowrap;
  .curated-list {
      display: flex;
      margin-right: .625rem;
      margin-bottom: .625rem;
  
      justify-content: center;
      align-items: center;
      border: 1px solid #000;
      border-radius: .25rem;
      color: #2b2b2b;
      overflow: hidden;
      // box-shadow: .0625rem .0625rem .25rem rgba(0, 0, 0, 0.4);
      flex-wrap: wrap;
      background-position: center 15px;
      background-size: cover;
        position: relative;
      flex: 0 0 9.375rem;
  
      @media only screen and (max-width:47.9375rem) {
        flex: 0 0 calc(25% - .625rem);
      }
  
      @media only screen and (max-width:35.9375rem) {
        flex: 0 0 calc(50% - .3125rem);
  
      }
  
      // &:nth-child(2n) {
      //   @media only screen and (max-width:35.9375rem) {
      //     margin-right: 0;
      //   }
      // }
  
      &:last-of-type {
        margin-right: 0;
      }
  
      .MuiFormControlLabel-label {
        font-size: 1.125rem;
        font-weight: 700;
      }
  
      .label {
        color: $color-white;
        display: flex;
        width: 9.375rem;
        height: 5rem;
        justify-content: center;
        align-items: center;
        text-decoration: none;
        position: relative;
        z-index: 0;
        transform: scale(1);
        padding: 0 1.25rem;
        text-align: center;
        margin-left: 0rem;
        margin-right: 0rem;
        @extend %cubic-transition-5s;

  
        @media only screen and (max-width:47.9375rem) {
          width: 100%;
        }
  
        // &:after {
        //   position: absolute;
        //   content: "";
        //   left: 0;
        //   top: 0;
        //   width: 100%;
        //   height: 100%;
        //   background-color: rgba($color-black, 0.5);
        //   z-index: -1;
        // }
      }
  
      &:hover {
  
        .label {
          transform: scale(1.1);
        }
      }

  &.Automobile {
    background-image: url('../../../images/icon-curated-list/automobiles.svg');
    background-repeat: no-repeat;
    background-size: 33px 36px !important;
  }

  &.Edutainment {
    background-image: url('../../../images/icon-curated-list/entertainment.svg');
    background-repeat: no-repeat;
    background-size: 33px 33px !important;
  }

  &.Children {
    background-image: url('../../../images/icon-curated-list/children-family.svg');
    background-repeat: no-repeat;
        background-size: 51px 31px !important;
  }

  &.Gadgets {
    background-image: url('../../../images/icon-curated-list/gadgets-technology.svg');
    background-repeat: no-repeat;
        background-size: 33px 33px !important;
        background-position: center 5px;  
  }

  &.Health {
    background-image: url('../../../images/icon-curated-list/health-fitness.svg');
    background-repeat: no-repeat;
        background-size: 33px 33px !important;
  }

  &.Lifestyle {
    background-image: url('../../../images/icon-curated-list/lifestyle.svg');
    background-repeat: no-repeat;
        background-size: 33px 33px !important;
  }

  &.Sports {
    background-image: url('../../../images/icon-curated-list/sports.svg');
    background-repeat: no-repeat;
        background-size: 33px 33px !important;
  }

  &.Travel {
    background-image: url('../../../images/icon-curated-list/travel-tourism.svg');
    background-repeat: no-repeat;
        background-size: 33px 33px !important;
  }
  &:hover{
    background-color: rgba($color-black, 0.03);
  }
  
    }
  }
 

.filter-dropdown-sec {
  position: relative;

  &.MuiGrid-root {
    width: 100%;
    max-width: 100%;
  }
}

.filter-dropdown {
  height: 2.5rem;
  display: inline-flex;
  width: 100%;
  align-items: center;
  padding: 1.0313rem .875rem;
  border: .0625rem solid $color-838383;
  border-radius: .25rem;
  cursor: pointer;
  position: relative;
  font-size: .875rem;
  color: rgba(0, 0, 0, 0.6);
  background-color: $color-white;

  &:after {
    position: absolute;
    right: .9375rem;
    border-width: .375rem;
    width: 0;
    height: 0;
    content: "";
    border-style: solid;
    border-color: rgba(0, 0, 0, 0.6) transparent transparent transparent;
    top: 1.125rem;


  }

  transition: color 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms,
  transform 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms,
  max-width 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms;

  .filter-dropdown-text {
    transform: translate(0rem, .5625rem) scale(1);
    position: absolute;
    left: 0;
    top: 0;
    transition: color 200ms cubic-bezier(0, 0, 0.2, 1) 0ms, transform 200ms cubic-bezier(0, 0, 0.2, 1) 0ms, max-width 200ms cubic-bezier(0, 0, 0.2, 1) 0ms;
    background-color: $color-white;
    padding: 0 .625rem;
    user-select: none;

  }

  &.active {
    border-color: $color-black;
    background-color: $color-white;

    .filter-dropdown-text {
      transform: translate(0rem, -0.5625rem) scale(0.75);
      transition: color 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms,
        transform 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms,
        max-width 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms;
      color: $color-black;

      background-color: $color-white;
    }

    &:after {
      border-color: transparent transparent rgba(0, 0, 0, 0.6) transparent;
      top: .6875rem;
    }
  }

}

.search-container-content {
  position: relative;
  z-index: 2;
  max-width: calc(100% - 0rem);
  margin-right: 0rem;

  @media (width < 768px) {
    margin-bottom: 1.25rem;
  }

  .btn-search {
    position: absolute;
    right: 0;
    padding: .625rem 1rem;
  }

  .form-control {
    font-size: .875rem;
    height: 2.875rem;
    border-radius: 1.5625rem;
    padding-left: 3.125rem;
    padding-right: 3.125rem;
  }

  .search-plt-type-icon {
    position: absolute;
    top: .75rem;
    left: 1.25rem;
    width: 1.5rem;

    &.icon-youtube {
      top: .75rem;
      color: #f00;
    }

  }

}

.search-suggestion {
  width: calc(100% - 0rem);
  background-color: white;
  position: absolute;
  z-index: 100;
  left: 0rem;
  top: 1.5625rem;
  z-index: -1;
  padding-top: 20px;
  padding-bottom: 15px;
  border: 1px solid #dee2e6;
  border-radius: 0 0 4px 4px;
  box-shadow: 1px 1px 4px #dee2e6;
  height: 360px;
  overflow-y: auto;

  >ul {
    padding-right: 1.0625rem;
  }

  .insta-search-list {
    list-style: none;
    margin-top: .625rem;
    padding-bottom: .625rem;
    border-bottom: .0625rem solid #dee2e6;

    p {
      margin-bottom: 0;
    }

    .insta-search-username {
      text-decoration: none;
      color: black;
    }

    &:last-of-type {
      border-bottom: none;
      padding-bottom: 0;
    }
  }
}

.compare-toolbar {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  bottom: 0rem;
  left: 0;
  right: 0;
  padding: 0;

  .tools {
    display: flex;
    justify-content: center;
    align-items: flex-end;
    position: relative;
    height: 6.875rem;
    width: 100%;
    box-sizing: border-box;
    box-shadow: rgba(0, 0, 0, 0.05) 0rem .375rem 1.5rem 0rem,
      rgba(0, 0, 0, 0.08) 0rem 0rem 0rem .0625rem;
    border-radius: 0rem;
    z-index: 9999;
    background-color: #fff;
    padding: .9375rem .9375rem 1.25rem;

    .image-section {
      position: absolute;
      top: -2.5rem;
      display: flex;
      max-width: 1100px;
      margin: 0 auto;
      left: auto;
      right: auto;
      justify-content: center;

      @media only screen and (width < 1200px) {
        width: auto;
        max-width: 100%;
      }

      .compare-img {
        img {
          height: 5rem;
          width: 5rem;
          display: flex;
          border-radius: 50%;
          margin: 0rem .625rem;
          border: .0625rem solid #dee2e6;
          box-sizing: border-box;
          background-color: #fff;

          cursor: pointer;
        }
      }
    }

    .button-section {
      overflow: hidden;

      .btn {
        margin: 0rem .625rem;
      }
    }
  }
}

.compare-img {
  position: relative;

  .remove-infl {
    position: absolute;
    top: 0;
    left: 10px;
    right: 10px;
    bottom: 0;
    width: 80px;
    height: 80px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #f00;
    cursor: pointer;
    opacity: 0;

    svg {
      z-index: 1;
    }

    &:after {
      position: absolute;
      content: "";
      background: rgba(0, 0, 0, 0.4);
      left: 0;
      right: 0;
      width: 100%;
      height: 100%;
      border-radius: 50%;
    }
  }

  &:hover {
    .remove-infl {
      opacity: 1;
    }
  }
}


.filter-dropdown-menu {
  padding: .625rem .9375rem;
  border: .0625rem solid #ced4da;
  background: #fff;
  border-radius: .25rem;
  display: none;
  position: absolute;
  z-index: 5;
  left: 0;
  top: 2.25rem;
  width: 100%;

  &.active {
    display: block;
    border-top: transparent;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    border-color: $color-black;
  }
}

.form-filter {
  margin-bottom: 1.25rem;

  .btn {
    height: 2.5rem;
    line-height: 1;
  }
}


.infl-info-sec {
  .infl-count {
    font-weight: 600;
    margin-bottom: .625rem;
    display: block;
  }
}

.infl-category-sec {
  display: flex;
  flex-wrap: nowrap;
  overflow: hidden;
  margin-bottom: .9375rem;
  overflow-x: auto;


  .infl-category {
    display: flex;
    flex-direction: column;
    background: #eee;
    border-right: .0625rem solid #ddd;
    text-align: center;
    flex: 1;
    cursor: pointer;

    &:last-of-type {
      border-right: none;
    }

    &.active {
      background-color: #00aae5;
      color: #fff;
    }
  }

  .infl-category-name {
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: 1rem;
    padding: .625rem;
    border-bottom: .0625rem solid #d7d7d7;
    font-weight: 600;

    @media only screen and (min-width:75rem) {

      padding: .625rem 1.875rem;
    }

    span {
      font-size: .75rem;
      font-weight: 300;
    }
  }

  .infl-category-count {
    font-size: .75rem;
    padding: .3125rem 1.25rem .625rem 1.875rem;
  }
}

.infl-action-btns {
  margin-top: 1.25rem;
  margin-bottom: 1.25rem;

  a {
    color: $color-primary;
    margin-right: .625rem;
  }

  .helper-text {
    color: #2b2b2b;
    font-size: .6875rem;
  }

  span {
    margin-right: .625rem;
  }
}

.filter-dropdown-menu .MuiTypography-root {
  font-size: .875rem;
  user-select: none;
}

.filter-dropdown-menu .MuiFormControlLabel-root {
  align-items: center;
  margin-bottom: .75rem;
  margin-left: 0;

}

.filter-tab-panel {
  margin-bottom: 9.375rem;
}

.discover-platform-tab {
  .MuiTab-root {
    font-size: $font-size-body;

    svg[data-testid="YouTubeIcon"] {
      fill: $color-red;
    }

    &.Mui-selected {
      color: $color-black;
    }
  }

  .MuiTabs-indicator {
    background-color: $color-black;
  }
}

.list-section {
  ul {
    list-style: none;
    margin: 0rem;
    padding: 0rem;
    height: 18.75rem;
    overflow-y: auto;

    li {
      font-size: 1rem;
      padding: .625rem;
      margin: 0 0rem .625rem;
      cursor: pointer;
      border: .0625rem solid $color-black-7;
      border-radius: .25rem;
      background-color: $color-white;
      display: flex;
      align-items: center;

      &:hover {
        border-color: $color-black;

        .list-section-icon {
          color: #2b2b2b;

          svg path {
            fill: #2b2b2b;
          }
        }
      }
    }

    .confirm-icon {
      opacity: 0;
      visibility: hidden;
      margin-left: auto;
    }

    .selected-list {
      background-color: $color-black;
      color: $color-white;

      svg#instagram path {
        fill: $color-white;
      }

      .confirm-icon {
        opacity: 1;
        visibility: visible;
      }
    }
  }
}

.discover-platform-tab {
  .sub-tabs {
    margin-bottom: 1.25rem;

    &::before,
    &::after {
      display: none;
    }

    .nav-link {
      padding: .75rem 1rem;
    }
  }
}

.custom-popover-menu {
  min-width: 18.75rem;
  padding: .625rem .9375rem;

  .MuiPopover-paper {
    width: 100%;
    max-width: 18.75rem;
    padding: 1.25rem .9375rem 1.25rem;
    border: .0625rem solid #ced4da;
    box-shadow: none;
  }
}



#platfrom_radio_id {
  display: inline-flex;
  margin-bottom: 1.25rem;

  @media only screen and (width < 35.9375rem) {
    display: flex;

  }

  input[type="radio"] {
    display: none;
  }

  input:checked+label {
    background-color: $color-black;
    // color: $color-white;
    border-color: $color-black;
    > span {
      color: $color-white;
    }
    .icon-white {
      color: $color-white;
    }
  }

  label {
    display: flex;
    cursor: pointer;
    border-color: $color-black;

    @media only screen and (width < 62rem) {
      min-width: 10rem;
    }

    @media only screen and (width < 35.9375rem) {

      flex: 1;

    }
  }
}

.influencer_type_cla {
  &.sidebar-nav {
    margin-bottom: 0.25rem;
  }

  input[type="radio"] {
    display: none;
  }

  input:checked+label {
    background-color: $color-black;
    border-radius: $border-radius-card;
    color: $color-white;
  }

  .sidebar-nav-link {
    display: flex;
    flex-direction: column;

    &.disabled {
      opacity: .3;
      pointer-events: none;
      user-select: none;
    }

    &:hover,
    input:checked+label {
      background-color: $color-black;
      border-radius: $border-radius-card;
      color: $color-white;


    }



    >strong {
      display: flex;
      flex-direction: column;
    }
  }

  input:checked+label {
    .influencer_type_range {
      border-color: rgba($color-white, 0.3);
    }
  }
}

.influencer_type_range {
  font-size: $font-size-sml;
  padding-top: 0.5rem;
  padding-bottom: .625rem;
  border-bottom: 1px solid rgba($color-black, 0.3);
  display: block;
  width: 100%;
  margin-bottom: .625rem;
}

.influencer_type_count {
  font-size: $font-size-sml;
}

.custom-section {
  background: $color-f2f2f2;
  position: relative;
  border-radius: $border-radius-card;
  margin-top: 1rem;

  .custom-section-label {
    padding-bottom: .5rem;
    font-weight: 600;
    font-size: $font-size-body;
  }
}


.custom-collapse {
  background: $color-f2f2f2;
  border-radius: $border-radius__10;
  border: .0625rem solid $color-black;
  margin-bottom: 1.25rem;

  .custom-collapse-label {
    display: flex;
    justify-content: space-between;
    padding: .8125rem 1.625rem;
    cursor: pointer;

    span {
      cursor: pointer;
    }
  }

  .active-collapse {
    opacity: 1;
    display: block;
  }

  .inactive-collapse {
    opacity: 0;
    display: none;
  }

  &-child {
    padding-left: 1.625rem;
    padding-right: 1.625rem;
  }
}


.custom-gender-layout {
  // input[type="radio"] {
  //   display: none;
  // }

  input:checked+label {
    background-color: $color-black;
    color: $color-white;
  }


}
.user-basic {
  position: relative;
}
.pro-tag {
  position: absolute;
  z-index: 1;
  right: 0;
  background: #00aae5;
  padding: 0px 5px;
  font-size: 11px;
  border-radius: 4px;
  color: #fff;
  font-weight: 400;
}


.custom-gender-option input {
  opacity: 0;
  position: absolute;
}

.custom-gender-option {
  display: flex;
  position: relative;

  .custom-gender-label {
    &:nth-of-type(2) {
      border-right: none;
    }
  }
}

.custom-gender-layout {
  position: relative;

}

.custom-gender-layout>span {
  position: absolute;
  top: -1.25rem;
  font-size: .875rem;
}

.custom-gender-label {
  padding: .4375rem 0.75rem;
  cursor: pointer;
  border: .0625rem solid $color-838383;
  user-select: none;
  background-color: $color-white;
}

.custom-gender-layout label:first-of-type:not(:only-of-type) {
  border-radius: 1.875rem 0 0 1.875rem;
  border-left: .0625rem solid $color-838383;
  border-right: none;
}

.custom-gender-layout label:last-of-type:not(:only-of-type) {
  border-radius: 0 1.875rem 1.875rem 0;
  border-right: .0625rem solid $color-838383;

}


.toggle-arrow {
  user-select: none;
}

.default-selected {
  z-index: 9999;
  position: relative;

  .default-selected-list {
    display: flex;
    justify-content: flex-end;
    position: fixed;
    bottom: -1px;
    right: 0px;
    width: 100%;
    border-top: 0.0625rem solid #d7d7d7;
    padding: 20px;
    background-color: #fff;

    .list-name{
      font-weight: 600;
      display: flex;
      align-items: center;
      margin-right: 20px;
    }

    .influencer-count {
      display: flex;
      justify-content: center;
      align-items: center;
      position: absolute;
      border-radius: 50%;
      margin-right: 0px;
      bottom: 45px;
      height: 30px;
      width: 30px;
      background-color: #00aae5;
      color: $color-white;
      z-index: 9999;
    }

    .btn-span {
      margin-right: 6px;
    }
  }
}


.sel-category {
  background: #fff;
  padding: 10px;
  border-radius: 10px;
  margin-bottom: 15px;
  display: flex;
  flex-wrap: wrap;
  border: 1px solid rgba(0, 0, 0, 0.3);

  .MuiStack-root {
    display: flex;
    flex-wrap: wrap;
    gap: 5px;
    flex: 99999;
  }

  .MuiChip-root {
    margin-left: 0;
    height: 22px;
  }

  .MuiChip-deleteIcon {
    font-size: 2rem;
    padding: .25rem;
    margin-right: 0px !important;
  }

  >span {
    padding-bottom: 0;
    font-size: $font-size-sml;
    font-weight: 600;
    margin-right: .625rem;
  }

  &:first-of-type {
    margin-top: .625rem;
  }
}

.curated-list-infl-detail {
  display: flex;
  max-width: 15.625rem;

  .infl-name {
    flex: 99999;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    text-align: left;
    align-self: flex-start;
    max-width: calc(100% - 40px);
  }
}

.modal-curated-list .common-pagination {
  display: none;
}


// INFLUENCER DETAIL TABLE
.infl-detail-table {
  // margin-bottom: 60px;
  table {
    td {
      &:nth-of-type(2) {
        min-width: 15.625rem;
      }
    }
  }
}

.popper-popup {
  position: sticky;
  right: 32px;
  bottom: 70px;
  background: #fff;
  border: 1px solid $color-f2f2f2;
  padding: 1.25rem;
  border-radius: $border-radius-card;
  min-width: inherit;
  max-width: 25rem;
  width: 100%;
  z-index: 12;
  box-shadow: 0 1px 5px 2px rgba($color-black, 0.17);
  margin-left: auto;
  max-height: 30.125rem;
  overflow: hidden;
  overflow-y: auto;

  @include breakpoint(small) {
    min-width: 25rem;
    max-width: 25rem;
  }

  .popup-footer {
    bottom: -1.25rem;
    padding-top: 1rem;
    padding-bottom: 0;
    margin-top: 1.25rem;
  }

  .list-section ul {
    min-height: inherit;
    max-height: 15.75rem;
    height: auto;
  }

  .popper-popup-header {
    position: sticky;
    margin-left: -20px;
    margin-right: -20px;
    top: -20px;
    margin-top: -20px;
    border-radius: 14px 14px 0 0;
    margin-bottom: 20px;
    padding: 10px 14px 10px 20px;
    align-items: center;
    border-bottom: 0.0625rem solid #D7D7D7;
    background: #fff;
    z-index: 1;
  }

  .common-table-body {
    tr {
      &:first-of-type {
        td {
          border-top: 1px solid rgba($color-black, 0.3);

          &:first-of-type {
            border-radius: 14px 0 0 0;
          }

          &:last-of-type {
            border-radius: 0 14px 0 0;
          }

        }
      }
    }
  }

  &.active {
    opacity: 1;
    transform: translateY(0);
    visibility: visible;
    transition: opacity 0.3s ease, transform 0.3s ease;

  }

  &.discover-add-to-list {
    position: fixed;
    left: 0;
    right: 0;
    bottom: 40%;
    transform: translateY(10%);
    margin: 0 auto;
  }

}

.center-screen {
  position: fixed;
  left: 0;
  right: 0;
  bottom: 20%;
  margin: 0 auto;
}


.modal-curated-list {
  &.popper-popup-active {

    .popup_container-inner {
      position: relative;

      // &:after {
      //   position: absolute;
      //   content: "";
      //   left: 0;
      //   top: 0;
      //   width: 100%;
      //   height: 100%;
      //   background: rgba(0, 0, 0, 0.7);
      //   z-index: 10;
      //   border-radius: 8px;
      // }
    }

    .popup_container_content {
      overflow: hidden;
    }
  }
}

.overflow-hidden {
  position: relative;
  z-index: 10;

  &.overlay {
    &:after {
      background: rgba(0, 0, 0, 0.3);
      position: fixed;
      left: 0;
      right: 0;
      width: 100%;
      height: 100%;
      content: "";
      z-index: 9;
      top: 0;
    }

    .compare-toolbar {
      z-index: 10;
    }
  }
}

.curated-list-name {
  padding-top: 46px;
  font-weight: 600;
  text-align: center;
  line-height: 1.2;
  padding-bottom: 10px;
  position: absolute;
  z-index: -1;
  font-size: .875rem;
  padding-left: 5px;
  padding-right: 5px;
}
.discover input[type="search"]::-webkit-search-cancel-button {
  -webkit-appearance: none;
  appearance: none;
}
.d-transparent{
color: white !important;

}
#close-icon-discover{
  padding:0px;
}

.page-btm-spc-exact .main-content-sec {
  padding-bottom: 20px; 
}
.page-btm-spc-exact.add-to-list-active .main-content-sec {
  padding-bottom: 170px;
}