// .page-pricing {
//   .plan-info {
//     &.plan-info-main-tab {
//       .nav-link {
//         color: #2b2b2b;

//         &.active {
//           background: #2b2b2b !important;
//           color: #fff !important;
//         }
//       }

//       @media only screen and (max-width:768px) {
//         flex-direction: row;
//         border: 1px solid #000;

//         .nav-item {
//           flex: 1;
//           text-align: center;
//           border-radius: 0;

//           .nav-link {
//             border-radius: 0;
//             color: #2b2b2b;
//             font-size: 17px;
//             padding-top: 10px;
//             padding-bottom: 10px;
//             font-weight: 600;

//             &:hover {
//               // border-bottom: 1px solid #000;
//             }

//             &.active {
//               background: #2b2b2b !important;
//               color: #fff !important;
//             }
//           }
//         }
//       }
//     }

//   }

//   .plan-info-inner-tab {
//     border-bottom: none;

//     .nav-link {
//       color: #2b2b2b;
//       border: none;

//       &.active {
//         background-color: transparent !important;
//         color: #00aae5 !important;
//         border-bottom: 1px solid #00aae5 !important;
//       }
//     }
//   }
// }

// .page-pricing.main-content-sec {
//   // background: url("/src/assets/images/clan_connect/clanconnect-website-new/images/banner-bg.png");
//   background-repeat: repeat;
//   background-attachment: scroll;
//   background-repeat: repeat-x;
//   background-attachment: fixed;
// }

// .page-pricing.main-content-sec:after {
//   content: "";
//   // background: url("/src/assets/images/clan_connect/clanconnect-website-new/images/banner-bg-logo-outline-pink.svg");
//   background-position-x: 0%;
//   background-position-y: 0%;
//   background-repeat: repeat;
//   background-size: auto;
//   background-repeat: no-repeat;
//   background-size: 47% auto;
//   width: 100%;
//   height: 103%;
//   top: -8%;
//   background-position: right top;
//   position: fixed;
//   right: -14%;
//   z-index: -1;
// }

// .page-pricing.main-content-sec .plan-info,
// .page-pricing.main-content-sec .pricing-tab {
//   background: transparent;
// }

// .pricing-tab h3 {
//   font-family: "Open Sans", sans-serif;
// }

// .page-pricing.main-content-sec .pricing-tab #pricingTabContent {
//   max-width: 960px;
//   margin: 0 auto;
// }

.pricing-sec-infl {
  padding-top: 12px;
  padding-bottom: 100px;
}

.pricing-sec-right {
  flex: 9999;
}

.pricing-plan {
  border: 2px solid #eee;
  border-radius: 20px;
  background: #f2f2f2;
  display: flex;
  flex-direction: column;
  // flex: 0 1 clamp(calc(33.33% - 20px), 350px, 350px);
  flex: 1;
  margin-right: 30px;
  margin-bottom: 30px;
  padding: 20px;
}

.pricing-plan:last-of-type {
  margin-right: 0;
}

// .pricing-plan:hover {
//   background: #e9e9e9;
// }

.pricing-plan-title-sec {
  text-align: center;
  padding: 0px 0px 20px;
  border-bottom: 1px solid #eee;
  margin-bottom: 15px;
  display: flex;
  flex-direction: column;
}

.pricing-plan-price {
  color: #00aae5;
  font-weight: bold;
  font-size: 26px;
  /* display: flex; */
  /* flex-direction: column; */
}

.pricing-plan-price span {
  font-size: 12px;
  color: #2b2b2b;
  font-weight: normal;
}

.pricing-plan-price span.pricing-plan-reg-price {
  font-size: 22px;
  text-decoration: line-through;
}

.pricing-plan-gst {
  font-size: 12px;
  color: #bbb;
}

.pricing-plan-detail-sec {
  position: relative;
}

.pricing-plan-detail-sec ul {
  margin-bottom: 50px;
  padding-left: 0;

}

.pricing-plan-detail-sec ul li {
  list-style: none;
  display: flex;
  align-items: center;
  padding-bottom: 2px;
  font-size: 14px;
  line-height: normal;
}
.pricing-plan-influencer .pricing-plan-detail-sec ul {
column-count: 2;
margin-bottom: 1rem;
@media (width < 768px) {
column-count: 1;
}

}
.pricing-plan-influencer .pricing-plan-detail-sec ul li {
  padding-bottom: 5px;
}
.pricing-plan-influencer .pricing-plan-detail-sec ul li strong {
  margin-bottom: 0;
}
.pricing-plan-influencer  .pricing-plan-detail-sec ul li:nth-child(-n+5) {
  grid-column: 1;
  padding-top: 13px;
  @media (width < 768px) {
      padding-top: 0px;
    }
  /* Place first 5 items in the first column */
}
.pricing-plan-influencer .pricing-plan-detail-sec ul li:nth-child(-n+5):first-of-type {
  padding-top: 0;
}

.pricing-plan-influencer  .pricing-plan-detail-sec ul li:nth-child(n+6) {
  grid-column: 2;
  /* Place items from 6 onward in the second column */
}

.pricing-plan-detail-sec ul li:last-of-type {
  border-bottom: none;
}

.pricing-plan-detail-sec ul li i {
  color: rgb(38, 187, 38);
  font-size: 20px;
  line-height: 16px;
}

.pricing-plan-detail-sec ul li i.bi-x {
  color: #f00;
}

.pricing-plan-detail-sec ul li strong {
  margin-top: 15px;
  display: block;
  margin-bottom: 10px;
}

.pricing-plan-detail-sec .btn {
  position: absolute;
  bottom: -25px;
  left: 0;
  right: 0;
  margin: 0 auto;
  max-width: 150px;
  opacity: 1;
  visibility: visible;
  padding: 10px 40px;
  text-decoration: none;
  display: inline-block;
  border-radius: 80px;
}

.pricing-page-banner h4 span {
  color: #000;
  font-weight: 600;
  font-size: 36px;
  vertical-align: middle;
}


section.infl-mob.only-premium {
  width: calc(100% - 90px);
  margin-left: 15px;
}

.infl-mob.only-premium .pricing-sec-infl {
  padding-top: 0;
  padding-bottom: 40px;
}


.pricing-plan:hover .btn {
  opacity: 1;
  visibility: visible;
}

.pricing-plan-sec {
  display: flex;
  flex-wrap: wrap;
}

.pricing-plan-title {
  font-weight: bold;
}

.pricing-tab h3 {
  font-family: "Open Sans", sans-serif;
  background: rgb(25, 118, 210);
  border-radius: 8px 8px 0 0;
  padding: 10px;
  color: #fff;
  margin-bottom: 30px;
  text-transform: uppercase;
  font-size: 26px;
}

.page-pricing.main-content-sec .pricing-tab #pricingTabContent {
  max-width: 1200px;
}

.brand-li {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #eee;
  padding-bottom: 10px;
  margin-bottom: 5px;
}

.discount {
  color: #63b57f;
  font-size: 16px;
}

.brand-pricing-plan-type {
  font-size: 20px;
}

.pricing-description {
  font-size: 14px;
  color: #5d5d5d;
}

.pricing-title {
  font-size: 20px;
  font-weight: 600;
}

.pricing-plan-div {
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: space-between;
}

.pricing-plan-type {
  font-size: 26px;
  font-weight: 600;
}

.plan-type {
  font-size: 12px;
  font-weight: 600;
}

.btn.plan-btn {
  margin: 20px 0px;
  border-radius: 10px;
  padding: 12px;
  font-size: 16px;
  margin-top: auto;
}

.plan-btn:hover {
  border-color: #fff;
}

.plan-switch-btns {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;

  .sidebar-nav-link {
    flex: 0 0 auto;
    border-radius: $border-radius__10;
  }
}

.switch-btn {
  padding: 7px 20px;
  cursor: pointer;
  border-radius: 10px;
}

.plan-active {
  background: #2b2b2b;
  color: #fff !important;
}

.switch-btn-div {
  padding: 5px;
  border-radius: 10px;
  border: 1px solid #f2f2f2;
  background-color: #f2f2f2;
  font-size: 14px;
  display: flex;
}