.influncer_heading {
  display: flex;
  justify-content: space-between;
}

.excel {
  display: flex;
  justify-content: flex-end;
}

.link {
  text-decoration: none;
}

.sub-buttons .btn.btn-primary {
  margin-left: 10px;
}

.inf-database-section.influencer-database-home {
  margin-left: 64px;

  .MuiTabPanel-root {
    padding-left: 0px;
    padding-right: 0px;
  }

  .MuiTab-root {
    min-height: 0;
  }

  .MuiTab-root.Mui-selected {
    color: #00aae5;
  }

  .MuiTabs-indicator {
    background-color: #00aae5;
  }
}

@media only screen and (max-width: 575px) {
  .influncer_heading {
    flex-direction: column;
  }

  .buttons {
    display: flex;
    flex-direction: column;
  }

  .sub-buttons {
    display: flex;
    justify-content: space-between;
  }

  .link {
    text-decoration: none;
  }
}

@media only screen and (max-width: 767px) {
  .influncer_heading {
    flex-direction: column;
  }

  .buttons {
    display: flex;
    flex-direction: column;
  }

  .sub-buttons {
    display: flex;
    justify-content: space-between;
  }

  .link {
    text-decoration: none;
  }
}

@media only screen and (max-width: 991px) {
  .sub-buttons .btn.btn-primary {
    margin-left: 10px;
  }

  .influncer_heading {
    flex-direction: column;
  }

  .buttons {
    display: flex;
    flex-direction: column;
  }

  .sub-buttons {
    display: flex;
    justify-content: space-between;
  }

  .link {
    text-decoration: none;
  }
}

@media only screen and (max-width: 1200px) {
  .sub-buttons .btn.btn-primary {
    margin-left: 10px;
  }
}