.create_plan_main_container {
    // margin: 10px 0px;


    .add_campaign_plus_button {
        cursor: pointer;
    }

    .edit_icon {
        cursor: pointer;

    }

    .pln_table_body {
        .plan_navigation {
            color: $color-primary;
            cursor: pointer;
        }

        .table_cell {
            display: flex;
            justify-content: space-between;
            // border: 2px solid black;
            width: 100%
        }
    }

    .create_plan_top_buttons {
        // padding: 0px;
        // margin-bottom: 20px;
        // position: absolute;
        // right: 0;
        // top: 0;
        display: flex;
        flex-wrap: wrap;
        align-items: center;

        @media only screen and (max-width:575px) {
            flex-direction: column;
            align-items: flex-start;
        }

        .plan-list {
            max-width: 50%;
        }

    }
}


.create_plan_top_buttons {
    padding: 0 0 10px;
}

.plan-tabs .nav-link {
    @include mobileBreakpoint(mobile) {
        text-transform: none !important;
        padding: 10px;
    }

}

.table_cell_inner {
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    line-height: 18px;
    display: -webkit-box;
}

.plan-action svg {
    width: 20px;
    height: 20px;
    margin-left: 5px;
}

.camp-status {
    padding: 4px;
    font-size: 14px;
    margin-right: 4px;
    width: 24px;
    display: inline-block;
    text-align: center;

    &:last-of-type:not(:only-of-type) {
        margin-right: 0;
    }

}

.camp-status.active {
    background-color: #B8FFDD;
}

.camp-status.draft {
    background-color: #FFCACA;
}

.camp-status.negotiating {
    background-color: #f6e9ff;
}

.camp-status.completed {
    background-color: #EBDFFF;
}

.camp-status.under-review {
    background-color: #FFEEB2;
}

.plantable-container {
    .pln_table_body th {
        color: #00aae5;
        text-decoration: underline;

        &:hover {
            text-decoration: none;
        }
    }
}

.campaign-legend {
    display: flex;
    list-style: none;
    padding-left: 0;
    flex-wrap: wrap;
    margin-left: auto;
    margin-bottom: 0;

    @media only screen and (max-width:575px) {

        margin-left: 0;
        margin-top: 15px;
        margin-bottom: 0px;
    }

    li {
        position: relative;
        padding-left: 20px;
        margin-right: 10px;
        font-size: 14px;
        display: flex;
        align-items: center;
        flex-wrap: wrap;

        @media only screen and (max-width:575px) {
            align-items: flex-start;
        }

        &::after {
            position: absolute;
            content: "";
            left: 0;
            width: 16px;
            height: 16px;
            background: #000;
        }

        &.campaign-legend_accepted {
            &:after {
                background-color: #caffe6;
            }
        }

        &.campaign-legend_rejected {
            &:after {
                background-color: #FFCACA;
            }
        }

        &.campaign-legend_pending {
            &:after {
                background-color: #FFEEB2;
            }
        }

        &.campaign-legend_negotiating {
            &:after {
                background-color: #f6e9ff;
            }
        }
    }
}