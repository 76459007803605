.influencer-list-main-container {
  .container {
    max-width: 93.75rem;
  }
  .btn-outline-primary {
    margin-left: 0.9375rem;
    align-self: flex-start;
  }

  .close-icon {
    color: $color-primary;
    cursor: pointer;
  }

  .icon {
    margin-right: 0.625rem;

    &.icon-youtube {
      font-size: $font-size--1_5rem;
      color: $color-red;
    }
  }

  .edit-icons {
    font-size: $font-size--1_5rem;
    margin-left: 0.9375rem;
    cursor: pointer;

    &:hover {
      color: $color-primary;
    }
  }

  .create-list-card {
    max-width: 37.5rem;
    min-width: 25rem;
  }

  .btn-primary {
    margin-left: 0.625rem;
  }

  .inf-list-section {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
  }
}

.influencer_table_main_container {
  padding: 0px;
  margin: 0px;
  background: $color-white;
  border-radius: $border-radius-card;

  .brand_influencer_table_upper_row {
    margin-top: 10px;
    margin-bottom: 10px;
  }

  .influencer_table_instagram_icon {
    color: rgb(197, 32, 81);
  }

  .influencer_table_edit_icon {
    color: rgb(0, 110, 255);
  }

  .brand_influencer_table_button {
    // border: 2px solid orangered;
    display: flex;
    justify-content: flex-end;

    .influencer_list_sendbreif {
      margin-right: 30px;
    }
  }

  .MuiDataGrid-columnHeaders {
    background-color: $color-black;
    color: $color-white;
    border-color: $color-black-7;
    padding-top: 10px;
    padding-bottom: 10px;
    line-height: normal;
    font-size: 14px;
    text-align: left;
  }

  .util-table-list {
    .MuiTablePagination-root {
      color: #00aae5;
    }
  }

  .css-1xwli2g-MuiDataGrid-root {
    --unstable_DataGrid-radius: 0.875rem;
  }
}

.table-section-title {
  display: flex;
  align-items: center;

  .title-w-bdr {
    span {
      padding-bottom: 0;
    }
  }

  .icon-instagram {
    width: 26px;
    height: 26px;
    margin-right: 10px !important;
  }
}

.no-list-comment {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  font-size: 16px;

  .link {
    text-decoration: underline;
    cursor: pointer;
    color: #0d6efd;
  }
}

.table-section-title-sec {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 1rem;
}

.table-section-title-right {
  margin-left: auto;
}

.list-section-content {
  background: $color-f2f2f2;
  padding: 1.25rem;
  border-radius: 0.625rem 0.625rem 0 0;

  &.rounded {
    border-radius: 0.625rem !important;
    padding: 1rem;
    margin-bottom: 1rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    min-height: 80px;
  }

  input {
    background-color: $color-white;
  }

  .pricing-plan {
    background-color: $color-e8e8e8;
    border-color: $color-d2d2d2;

    &:hover {
      background-color: $color-white;
      border-color: $color-d2d2d2;
    }
  }
}

.platform_type {
  span {
    svg[data-testid="YouTubeIcon"] {
      fill: $color-red;
    }

    &:hover {
      cursor: pointer;
    }

    &.active {
      background-color: $color-black;
      color: $color-white;
      .inbox-checkbox-box-label {
        color: $color-white;
      }
    }
  }

  // span {
  //   border: 1px solid lightgray;
  //   padding: 10px 20px;
  //   border-radius: 5px;
  //   cursor: pointer;

  //   &:first-child {
  //     margin-right: 10px;
  //   }
  // }

  // .active {
  //   border-color: $color-primary;
  //   background-color: rgba(0, 170, 229, 0.2);

  //   .list-section-icon {
  //     svg {
  //       path {
  //         fill: #fff;
  //       }
  //     }
  //   }
  // }
}

.MuiModal-root.MuiModal-open:focus-visible {
  border: none !important;
  outline: none !important;
}
.list-section-sidebar {
  border-right: 1px solid $color-border-light;

  @media only screen and (max-width: 991px) {
    min-height: inherit;
    margin-bottom: 1.5rem;
  }

  ul {
    list-style: none;
    margin: 0px;
    padding: 0px;
    max-height: 100%;
    overflow-y: auto;
    height: 100%;
    width: 100%;

    li {
      cursor: pointer;
      display: flex;
      border: none;
      margin: 0;
      border-radius: $border-radius__10;
      line-height: 1.2;
      align-items: center;
      transition: 0.1s ease-out;
      margin-bottom: 5px;
      @media (width < 992px) {
        font-size: $font-size-sml;
      }

      .list-section-sidebar-link {
        color: $color-black;
        text-decoration: none;
        display: flex;
        width: 100%;
        border-radius: $border-radius__10;
        white-space: nowrap;
        padding: 0.625rem 0.625rem 0.625rem 0.9375rem;
        @media (width < 800px) {
          padding: 0.625rem 0.25rem 0.625rem 0.5rem;
        }
        &.active {
          background-color: $color-black;
          color: white;
          transition: 0.1s ease-in;
          border-color: $color-black-7;
        }
      }
      &:hover {
        .list-section-sidebar-link {
          background-color: $color-black;
          color: white;
          transition: 0.1s ease-in;
          border-color: $color-black-7;
        }
      }

      .list-section-icon {
        margin-right: 5px;
      }

      .influencer_table_del_icon {
        color: $errorColor;
        margin-left: auto;
      }

      &:hover,
      &.active {
        // background-color: $color-black;
        // color: white;
        // transition: 0.1s ease-in;
        // border-color: $color-black-7;

        .list-section-icon {
          color: #fff;

          svg path {
            fill: #fff;
          }
        }

        a {
          color: $color-white;
        }

        // &:hover {
        //   background-color: #f2f2f2;
        //   border-color: #f2f2f2;
        //   color: #2b2b2b;

        //   .list-section-icon {
        //     color: #fff;

        //     svg path {
        //       fill: #2b2b2b;
        //     }
        //   }
        // }
      }
    }
  }
}

.popup-add-infl-list {
  &.popup.popup-sm {
    max-width: 24rem;
  }
}

.profile_settings_policy {
  text-decoration: none;
  border-bottom: 1px solid #000;
  color: #000;
  font-size: 14px;
  display: inline-flex;
  // border-bottom: 1px solid transparent;
  margin: 0rem 0.625rem 0rem 0.9375rem;
  @media (width < 800px) {
    margin: 0 0.25rem 0 0.5rem;
  }
  &:hover {
    color: #666;
    border-bottom-color: #666;
  }

  &:focus {
    color: #666;
  }
}
