.detailed-analytics {
  width: 100%;
  padding: 0 0 20px 0;
  height: 100%;
  transition: all 0.3s;
  background: transparent;

  .detailed-analytics-content {
    width: 100%;
    // padding: 20px 0 20px 0;  
    background-color: #fff;
    margin-bottom: 40px;

    .custom-container {
      max-width: 1280px;

      .img-fluid {
        border-radius: 5px;
        border: 1px solid #dadada;
      }

      .row {
        display: flex;
        flex-wrap: wrap;
        margin-right: -15px;
        margin-left: -15px;

        .project-desc-sec {
          padding-left: 15px;
          padding-right: 15px;

          .title-top {
            display: flex;
            align-items: center;
            justify-content: space-between;
            flex-wrap: wrap;
            padding-bottom: 20px;

            div {
              display: flex;
            }

            .social {
              margin-right: 10px;
            }
          }

          .badge-light {
            border-radius: 25px;
            display: flex;
            align-items: center;
            padding: 10px 20px;
            font-weight: 400;
            font-size: 14px;
            background-color: #E1E5EC;
            color: #2b2b2b;
          }
        }
      }
    }

    .button-div {
      max-width: 1280px;
      margin: 0 auto;
      display: flex;
      justify-content: space-between;
      align-items: center;
      // margin-top: 20px;
      margin-bottom: 20px;

      @media screen and (max-width: 991px) {
        flex-direction: column;
        align-items: flex-start;

        .add {
          margin-bottom: 10px;
        }
      }

      @media screen and (max-width: 576px) {
        flex-direction: column;
        align-items: center;
        width: 100%;

        .add {
          width: 100%;
          justify-content: center;
        }
      }

      .buttons-group {
        display: flex;
        align-items: center;

        @media screen and (max-width: 767px) {
          flex-direction: column;
          align-items: flex-start;

          .button {
            padding: 10px 0px;
          }
        }

        @media screen and (max-width: 576px) {

          width: 100%;

          .button {
            display: flex;
            flex-direction: column;
            align-items: center;
            width: 100%;

            .btn {
              margin-bottom: 10px;
              width: 100%;
              margin-right: 0px;
            }
          }
        }

        span {
          margin-right: 10px;
          padding: 5px 15px;
        }

        .link {

          @media screen and (max-width: 576px) {
            width: 100%;
            display: flex;
            justify-content: center;
          }

          .link-type {
            margin-right: 10px;
            text-decoration: underline;
            cursor: pointer;
            color: #00aae5;
            padding: 0px 0px;
          }
        }
      }
    }

  }

  .upper-grid {
    max-width: 1280px;
    margin: 0 auto;
    // padding-bottom: 20px;
    // padding-top: 10px;
    // padding-left: 1rem;

    .upper-grid-div {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      background-color: #fff;
      border-radius: 5px;
      padding: 30px 0px;
      border-radius: $border-radius-card;
      box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
      border: none;
    }
  }

  .filter-div {
    max-width: 1280px;
    margin: 0 auto;
    display: flex;
    align-items: center;

    .filter {
      display: flex;
      align-items: center;
    }

    @media screen and (max-width: 991px) {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      margin-bottom: 10px;

      .filter {
        margin-bottom: 10px;
      }
    }

    .date_range_search {
      margin-left: 20px;

      @media screen and (max-width: 991px) {
        margin-left: 0px;

      }
    }

    span {
      margin-right: 10px;
    }
  }

  .no-data {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 400px;
  }


  .toggle-button-div {
    max-width: 1280px;
    margin: 0 auto;
    margin-bottom: 30px;

    .MuiTabs-flexContainer {
      display: flex;
    }

    .MuiTab-root {
      max-width: inherit;
    }
  }

  .toggle-button-div2 {
    max-width: 1280px;
    margin: 0 auto;

    .MuiTabs-flexContainer {
      display: flex;
    }
  }

  .toggle-button-div3 {

    .post-table,
    .influencer-table {

      .tableCell {
        padding-top: 15px;
        padding-bottom: 15px;
        font-size: 16px;
      }
    }

    .MuiTabs-flexContainer {
      display: flex;
      justify-content: flex-start;

      .filter {
        width: 100%;
        display: flex;
        justify-content: flex-end;
        align-items: center;

        @media screen and (max-width: 767px) {
          flex-direction: column;
          align-items: flex-start;
        }


        span {
          margin-right: 10px;
        }
      }
    }
  }
}

.share-invited-actions {
  display: flex;
  align-items: center;
}

.shared-user-list {
  padding-top: 15px;
  border-top: 1px solid #ddd;
  overflow: hidden;
  overflow-y: auto;
    margin-bottom: 30px;
    max-height: 300px;
    padding-right: 15px;
  > span {
    padding-bottom: .625rem;
    display: block;
  }
  ul {
    padding-left: 0;
    display: flex;
    flex-flow: column-reverse;
    li {
      font-size: 14px;
      .shared-user-delete {
        font-size: 20px;
      }
    }
  }
}

.disable-msg-post-link{
display: flex;
align-items: center;

@media screen and (max-width: 991px) {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: 10px;
}

button{
  margin-right: 10px;
}
}