.error-heading {
    font-size: 18px;
    font-weight: 600;
}

.error-play-stop {
    display: flex;
    align-items: center;
    padding-bottom: 10px;

    .error-redirect {
        font-size: 16px;
        font-weight: 400;
        margin: 0px;
        color: #000;
        margin-right: 10px;
    }

    .stop-play-icon{
        cursor: pointer;
    }
}

.erro-button {
    border: 1px solid #787878;
    padding: 10px;
    border-radius: 7px;
    background-color: #fff;
}

.error-trivia-title {
    font-size: 18px;
    font-weight: 600;
    margin-bottom: 5px;
}

.error-afresh {
    margin: 40px 0px 20px;
    color: #000;
}

.error-trivia-content {
    color: #000;
}

.error-go-home {
    margin-left: 10px;
    text-decoration: underline;
    cursor: pointer;
}

.error-page.container .MuiGrid-container {
    align-items: center;
}