.mostOutterDiv {
  width: 100%;
  background-color: white;

  .contentContaainerDiv {
    max-width: 1280px;
    // padding: 0 60px;
    margin: 0 auto;

    .paidAndViewDetailsDiv {
      display: flex;
      justify-content: space-between;
      margin-bottom: 50px;

      .paidSpan {
        display: flex;

        .paidPara {
          margin: 0;
        }
      }

      .viewDetailSpan {
        border-bottom: 1px solid black;
        display: flex;

        .viewDetailsPara {
          margin: 0;
        }
      }
    }

    .overviewAndDownloadDiv {
      display: flex;
      justify-content: space-between;
      margin-bottom: 10px;

      .overviewPara {
        margin: 0;
      }

      .downloadSpan {
        border-bottom: 1px solid black;
        display: flex;
        align-items: center;

        .downloadPara {
          margin: 0;
        }
      }
    }

    .overviewAndDownloadBoxesDiv {
      margin-bottom: 50px;

      .overviewAndDownloadBoxDiv {
        border: 1px solid black;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 30px 0;

        p {
          margin: 0;
        }
      }
    }

    .timelineViewHeading {
      margin-bottom: 10px;
    }

    .timelineViewChartOutterDiv {
      border: 1px solid black;
      padding: 10px 15px;
      margin-bottom: 50px;

      .timelineViewChartUpperDiv {
        display: flex;
        justify-content: right;
        margin-bottom: 10px;
        align-items: center;

        .dayWeekMonthDiv {
          border: 1px solid rgb(198, 193, 193);
          border-radius: 5px;
          margin-right: 10px;

          span {
            padding: 0 15px;
          }

          .day,
          .week {
            border-right: 1px solid rgb(198, 193, 193);
          }
        }

        .dateSelectDiv {
          margin-right: 10px;

          select {
            border-color: rgb(198, 193, 193);
            border-radius: 5px;
          }
        }

        select {
          border-color: rgb(198, 193, 193);
          border-radius: 5px;
        }
      }
    }

    .allCampaignsMostOutterDiv {
      margin-bottom: 50px;

      .allCampaignsHeading {
        margin-bottom: 10px;
      }

    }

    .topPerforminInfluencerMostOutterDiv {
      margin-bottom: 50px;

      .topPerforminInfluencerHeading {
        margin-bottom: 10px;
      }



      .imageNameDiv {
        display: flex;

        .imageDiv {
          width: 50px;
          height: 50px;
          border: 2px solid #e6e7e8;
          border-radius: 50%;
        }

        .instaNameDiv {
          display: flex;
          flex-direction: column;
          justify-content: center;
          padding-left: 20px;
        }
      }
    }
  }
}