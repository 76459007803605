$textColor: #5d5d5d;
$backgroundColor: #00aae5;

.profile_settings_container {
  height: calc(100vh - 100px);

  >.container {
    // height: calc(100vh - 120px);
    padding: 0px 0px 1.25rem;
    background-color: $color-white;
    border-radius: $border-radius-card;
    margin: 0 auto;
    width: calc(100% - 30px);
  }

  .profile_settings_main_container {
    // padding: 0px;

    height: calc(100% - 45px);

    // box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
    .profile_settings_profile {
      color: $textColor;
    }

    .profile_settings_left_side {
      border-right: 1px solid lightgray;
      // padding: 0px;
      display: flex;
      // flex: 0 0 240px;
      overflow-y: auto;

      // height: 100%;

    }

    .profile_settings_left_side.collapse-sidebar:hover+.profile_settings_right_side {
      margin-left: 190px;
      width: calc(100% - 190px);
    }

    .profile_settings_right_side {
      transition: margin-left 0.3s ease;
    }



    .profile_settings_right_side {
      height: 100%;
      overflow-y: auto;
      padding-bottom: 100px;
      // padding-left: 25px;
      // padding-right: 25px;

      .pin-pass-divider {
        .MuiDivider-wrapper {
          padding-top: 0;
          padding-bottom: 0;
        }
      }
    }

    .collapse-sidebar {
      position: fixed;
      width: 64px;
      background: #fff;
      z-index: 11;
      height: 100vh;
    }


    // .profile_settings_right_side {
    //   margin-left: 70px;
    // }

    // a.list-section-sidebar-link {
    //   width: 0;
    //   font-size: 0;
    // }

    .collapse-sidebar:hover {
      width: 250px;
      background: rgb(255, 255, 255);
    }

  }
}

.profile_settings_menu {
  list-style: none;
  margin: 0px;
  padding: 0px;

  li {
    // padding: 5px 20px;
    // padding: 5px 40px 5px 10px;
    font-size: $font-size-body;
    color: $textColor;
    cursor: pointer;
  }

  .profile_settings_navlinks {
    text-decoration: none;
    color: $textColor;
    padding: 8px 20px;
    display: inline-block;
    border-radius: 0px 20px 20px 0;
    // border-left: 2px solid transparent;
    position: relative;
  }
}

.profile-menu-mobile {
  margin-bottom: 20px;

  button {
    line-height: 1.4 !important;
  }
}

.add-photo-icon {
  position: absolute;
  bottom: 5px;
  right: 9px;
  padding: 5px;
  width: 32px;
  height: 32px;
  cursor: pointer;
}

.MuiTableRow-root.subscription-failed {
  opacity: 0.3;
}