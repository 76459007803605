$activeColor: #00aae5;

.invited {
    background-color: #ee1b6c;
    color: #fff;
}

.badge-primary-black {
    background: #000;
    color: #fff;
    @media (width < 768px) {
        background: rgba(#000, 0.7);
    }
}

.applied {
    background-color: #ee1b6c;
    color: #fff;
    padding: 6px 16px;
    font-size: 14px;

    &:hover {
        background-color: #fff;
        color: #ee1b6c;
        border: 1px solid #ee1b6c;
    }
}

.infl-dashboard-search-filter {

    .filter-header {
        display: flex;
        align-items: center;
        margin-bottom: 16px;
        padding-bottom: 16px;
    }

    .filter-body {
        display: flex;
        align-items: center;
        margin-bottom: 16px;
        padding-bottom: 16px;
        border-bottom: 1px solid #dee2e6;

        >span {
            min-width: 150px;
            width: 150px;
        }

        .filter-social-platform-icon {
            border: 1px solid #dee2e6;
            color: inherit;
            background-color: inherit;
            border-radius: 5px;
            padding-right: 15px;
            padding-left: 5px;

            &.icon-active {
                background-color: #e2faff;
                border-color: RGBA(0, 180, 238, 1);
            }
        }


    }

    .icon-youtube path {
        fill: red;
    }

    .Mui-checked {
        .icon-instagram path {
            fill: #000;
        }

        .icon-youtube path {
            fill: #000;
        }
    }

}

// .filter-social-platform {
//     label.input-checkbox-box {
//         flex-direction: row;

//     }
// }


.infl-dashboard-share {
    background-color: #fff;
    border: 1px solid rgba(0, 0, 0, 0.3);
    padding: 20px 15px 0;
    border-radius: 4px;

    .share-icons {
        margin-bottom: 5px;
        padding-bottom: 5px;
        border-bottom: 1px solid rgba(0, 0, 0, 0.3);

        .share-titles {
            margin-left: 5px;
            cursor: pointer;
            font-size: 14px;
        }

        .react-share__ShareButton {
            display: block;
            width: 100%;
            text-align: left;

            &:hover .share-titles {
                color: #5d5d5d;
            }

        }

        &:last-of-type {
            border-bottom: none;
        }
    }
}


.search-div {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .btn {
        margin-left: 15px;
    }
}



.campaign-header-main {
    // border: 1px solid black;

    .campaign-title {
        color: $activeColor;
        cursor: pointer;
    }


    .campaign-info {
        ul {
            list-style: none;
            display: flex;
            justify-content: flex-end;
            align-items: center;
            margin: 0px;
            padding: 0px;

            li {
                // border: 1px solid black;
                padding: 8px 10px;
                margin: 5px 10px;
                border-radius: 10px;
                background-color: lightgrey;
                cursor: pointer;

                &:last-child {
                    background-color: $activeColor;
                    color: white;
                }
            }
        }
    }
}


@media screen and (max-width: 576px) {
    .influencer_dashboard {

        .search-div {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: flex-start;

            .heading {
                display: flex;
                flex-direction: column;
                align-self: flex-start;
                margin-bottom: 10px;
            }

            .search-bar {
                width: 100%;
            }
        }
    }

    .infl-dashboard-search-filter {
        transform: translate3d(12px, 203.4px, 0px) !important;
        left: 0 !important;
        max-width: calc(100% - 25px);

        .filter-body {
            display: flex;
            margin-bottom: 16px;
            padding-bottom: 16px;
            border-bottom: 1px solid #dee2e6;

            >span {
                margin-bottom: 8px;
                display: flex;
                align-self: flex-start;
                min-width: 120px;
                width: 120px;
            }

        }

        .filter-social-platform-icon {
            margin-bottom: 10px;
            min-width: 140px;
        }
    }


}