.custom-infl-main-container {
    .contactType {
        display: flex;
        align-items: center;
    }
}


.custom-deliverable {
    // border: 2px solid black;



    .testing_extend {
        border: 2px solid orange !important;
        height: 200px;
    }

    ul {
        li {
            display: flex;
            justify-content: space-between;
            align-items: center;
            border-bottom: 1px solid lightgray;
            // padding: 10px;


            &:last-child {
                border: none;
            }

        }
    }

    input[type=text] {
        width: 28px !important;
        height: 28px !important;
        border: 1px solid #dee2e6;
        border-radius: 4px;
        text-align: center;
        font-size: 14px;
        margin: 0px 5px;
    }

    .count_button {
        width: 28px;
        height: 28px;
        background: #f2f2f2;
        border-radius: 4px;
        cursor: pointer;
        display: inline-block;
        line-height: 26px;
        padding: 0;
        vertical-align: middle;
        border: 1px solid #ddd;
        display: inline-block;
        vertical-align: middle;
        text-align: center;
        user-select: none;
        -webkit-user-select: none;
    }
}