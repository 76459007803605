$backarrowColor: #00aae5;

.temp_route {
    ul {
        list-style: none;
        display: flex;
        justify-content: flex-end;
        margin-top: 10px;

        li {
            padding: 1px solid black;

            .navlink {
                text-decoration: none;
                color: white;
                background-color: #00aae5;
                padding: 5px 10px;
                font-size: 20px;
            }
        }
    }
}