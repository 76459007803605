._influencer-container {
    padding: 0px;
    padding-top: 140px;
    padding-bottom: 100px;

    // @include breakpoint(small) {
    //     padding-left: 20px;
    //     padding-right: 20px;

    // }




    ._mandatory {
        font-size: .7rem;
        align-items: center;
    }

    input[type=number]::-webkit-inner-spin-button,
    input[type=number]::-webkit-outer-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }
}

._influencer-content-sec {
    ._influencer-content-sec-title {
        font-size: 42px;
        font-weight: 300;
        line-height: 1;
        letter-spacing: normal;
        text-align: left;
    }

    .brand-info-box {
        background-color: rgba(4, 15, 55, 0.2);
        padding-right: 1 0px;
        color: $color-body;
        font-size: $font-size-body;
        border-radius: 20px;
        padding: 24px 30px;
        padding-bottom: 30px;
        background: $color-white;
        border: 1px solid $color-primary;

        ul {
            padding-left: 0;
            list-style: none;
            margin-bottom: 0;

            li {
                padding-left: 30px;
                padding-bottom: 5px;
                line-height: 1.5;
                padding-right: 1.5rem;
                position: relative;

                &:before {
                    content: "\F231";
                    font-family: 'Bootstrap-Icons';
                    position: absolute;
                    left: 0px;
                }
            }
        }

    }

}

.ref-img img {
    border-radius: 20px;
    width: 100%;
}



.__backArrow_icon {
    border: 3px solid $backarrowColor;
    border-radius: 50%;
    margin-right: 10px;
    align-self: flex-start;
    text-decoration: none;

    // padding: 10px;

    ._icon {
        text-decoration: none;
        color: $backarrowColor;
        height: 30px;
        width: 30px;
        border-radius: 50%;
        background-color: transparent;

    }

}

.__backArrow {
    display: flex;
    align-items: center;
    color: $backarrowColor;


    .__accountname {
        margin-left: 20px;
    }
}

._influencer-login-btn {
    font-size: small;

    ._influencer-signup-navlink {
        text-decoration: none;
    }
}

.icon-insta path {
    background: radial-gradient(circle at 30% 107%, #fdf497 0%, #fdf497 5%, #fd5949 45%, #d6249f 60%, #285AEB 90%);
    -webkit-background-clip: text;
    background-clip: text !important;
    -webkit-background-clip: text !important;
    -webkit-text-fill-color: transparent;
}

._influencer-signup {
    border: 1px solid $color-body;
    padding: 35px 20px 26px;
    border-radius: 24px;
    background-color: $color-white;
    position: relative;
    z-index: 2;

    @include breakpoint(medium) {
        padding: 35px 30px 26px;
    }

    form {

        .col {

            p {
                margin: 0px;
                font-weight: bold;
            }

        }

    }

    .__signup_form {
        margin-top: 20px;

        .__wa_column {
            position: relative;

            .__button-influender {
                right: 1px !important;
            }

            .__button {
                position: absolute;
                width: max-content;
                right: 1px;
                height: 38px;
                z-index: 2;
                background-color: $whatsappColor;
                padding-left: 10px;
                padding-right: 10px;
                text-transform: none;
                font-size: $font-size-sml;
                top: 1px;
                box-shadow: none;

                .css-1d6wzja-MuiButton-startIcon {
                    margin-right: 4px;
                }

                &:hover {
                    box-shadow: none;
                }
                &.btn-disabled {
                    background-color: rgba(0, 0, 0, 0.1);
                    color: rgba(0,0,0,0.15);
                    -webkit-text-fill-color: rgba(0, 0, 0, 0.35);
                    cursor: default;
                    span {
                        color: rgba(0, 0, 0, 0.15);
                    }
                }
            }

            #email_id {
                padding-right: 110px;
               
            }
            .verified-email  {
            #email_id {
                padding-right: 40px;
                }
            }
        }

        ._signup_dob_section {
            display: flex;
            // justify-content: center;
            // align-items: center;
        }

        .__influencer-warn {
            color: #888;
            font-weight: bold;
            font-size: small
        }


        // ._influencer_dob_slash {
        //     font-size: large;
        //     padding: 5px;
        // }

    }

    .mandatory-msg {
        font-size: 10px;
    }

    // &.talent-signup {
    //     .__wa_column .__button {
    //         top: 17px;
    //         right: 0;
    //     }
    // }
}

.MuiButtonBase-root.icon-verified {
    position: absolute;
    right: 6px;
    top: 3px;
    color: green;
    .material-symbols-rounded {
        font-size: 28px;
    }
}

.mobile-verification{
    display: flex;
    .verify-button{
        margin-left: 10px;
        background-color: #25D366;
    }
}

.btn-disabled {
    background-color: rgba(0, 0, 0, 0.1);
    color: rgba(0, 0, 0, 0.15);
    -webkit-text-fill-color: rgba(0, 0, 0, 0.35);
    cursor: default !important;
    pointer-events: none !important;

    span {
        color: rgba(0, 0, 0, 0.15);
        svg, path {
            fill: rgba(0, 0, 0, 0.15) !important;
        }
    }
}