.brands-data-container {
    .brands-data-main {
        .run-camp-card {
            box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;
        }

        .brand-chart {
            font-size: 20px;
            font-weight: 400;
        }

        .brand-card {
            font-size: 20px;
            font-weight: 400;
            color: #4c4c4c;
        }

        .brand-name {
            font-weight: 600;
            font-size: 30px;
            background: -webkit-linear-gradient(45deg, #405de6, #5851db, #833ab4, #c13584, #e1306c, #fd1d1d);
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
            background-clip: text;
        }
    }
}
// .brand-pulse
// {
    .profile-card {
        width: 100%;
        max-width: 600px;
        margin: 20px auto;
        padding: 20px;
        background-color: #f5fbff;
        border-radius: 10px;
        box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
        font-family: Arial, sans-serif;
      }
      
      .profile-header {
        display: flex;
        flex-wrap: wrap;
        margin-bottom: 20px;
          padding: 15px 20px 0 60px;
          flex-wrap: wrap;
          margin-top: 100px;
          @media (width < 1200px) {
            padding: 15px 20px 0 30px;
            margin-top: 60px;
          }
          @media (width < 992px) {
            padding: 8px 20px 15px;
          }
          @media (width < 768px) {
            margin-top: 40px;
          }

          @media (width < 576px) {
            flex-direction: column;
          }
      }
      .profile-logo {
        margin-right: 40px;
          margin-top: -112px;
          margin-bottom: 30px;
        @media (width < 1200px) {
          margin-right: 20px;
          margin-top: -62px;
        }
        @media (width < 576px) {
          margin-bottom: 15px;
        }
    }
      
      .profile-logo { 
        img {
          border-radius: 50%;
          border: 2px solid #dce4ec;
          width: 287px; 
          height: 287px; 
          object-fit: cover;
          @media (width < 992px) {
            width: 200px;
            height: 200px;
          }
      }
    }
      
      .profile-info {
          display: flex;
          flex-direction: column;
          flex: 99999;
          p {
            font-weight: 400;
              font-size: 20px;
              display: flex;
              align-items: center;
              margin: 0px;
              
          }

      }
            .profile-info-industry-name {
              font-size: 16px;
              color: #6B7280;
              min-width: inherit;
            }
      .profile-info-insta-name {
        
      }
      
      .profile-name {
        display: flex;
        align-items: center;
        gap: 2px;
        margin-bottom: 8px;
        p {
          font-weight: 400;
          font-size: 20px;
          display: flex;
          align-items: center;
        }
      }
      
      h1.profile-name {
        font-size: 24px;
        font-weight: bold;
        margin: 0;
        display: flex;
        align-items: center;
      }
      span.profile-name {
        font-size: 16px;
        font-weight: bold;
        margin: 0;
        display: flex;
        align-items: center;
      }
      
      .profile-name a {
        font-size: 18px;
        margin-left: 5px;
        text-decoration: none;
        color: #0073e6;
      }
      
      .profile-category {
        font-size: 14px;
        color: #666;
        margin: 5px 0 0;
      }
      
      .profile-stats {
        display: flex;
        margin-top: 15px;
        gap: 16px; 
        margin-top: 16px;
        flex-wrap: wrap;
      }
      
      .stat-item {
        text-align: center;
        flex: 0 0 auto;
        display: flex;
        flex-direction: column;
        align-items: center;
        border: 0.84px solid #BED8E2;
        border-radius: 8.4px;
        padding: 0px 10px 5px;
        width: 150px;
        @media (width < 1200px) {
          width: 140px;
        }
        @media (width < 992px) {
          flex: 1;
        }
        strong {
          font-size: 26.87px;
          font-weight: 800;
          line-height: 36.59px;
          margin: 0;
          color:#3B5763
        }
      }
      
      .stat-value {
        font-size: 20px;
        font-weight: bold;
        color: #333;
      }
      
      .stat-label {
        font-size: 14px;
        color: #888;
      }
      

      .metrics-container {
        font-family: Open sans,Arial, sans-serif;
        // padding: 16px;
        // border: 1px solid #ddd;
        // border-radius: 8px;
        // background-color: #f9f9f9;
        // max-width: 900px;
        width:'100%';
        // margin: 0 auto;
      }
      
      .metrics-note {
        font-size: 12px;
        color: #000;
        text-align: center;
        margin-bottom: 20px;
        border:1px solid #6BC5FA;
        border-radius:6px;
        text-align: left;
        min-height: 28px; 
        display:flex;
        align-items: flex-start;
        padding:5px 10px;
      }
      
      .metrics-grid {
        display: flex;
        // grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
        flex-wrap: wrap;
        gap: 8px;
        margin-bottom: 20px;
      }
      
      .metrics-item {
        background: #fff;
        border: 1px solid #83A5B0;
        border-radius: 10.12px;
        padding:15px 8px 15px 8px;
        text-align: left;
        // box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
        width:fit-content;
        &.double {
          flex: 2;
        }
        @media (width < 36rem) {
          flex: 1;
        }
        
      }
      .infl-engg-ratio {
        @media (width > 48rem) {
          border-right: 1px solid #d2d2d2;
          padding-right: 15px;
        }
      }
      .infl-engg-er {
        border-bottom: 1px solid #d2d2d2;
        margin-left: 15px;
        padding-top: 16px;
      }
      
      .metrics-item h3 {
        font-size: 18px;
        color: #333;
        margin-bottom: 8px;
        font-weight:400;
      }
      .metrics-item .valuesContainer {
        display:flex;
        gap:12px
      }
      .metrics-item .value {
        font-size: 24px;
        font-weight: 800;
        color: #3B5763;
        line-height: 38.13px;
        white-space: nowrap;
        margin-bottom: 0;
      }
      
      .metrics-item  .subtext {
        font-size: 14px;
        font-weight: 400;
        color: #3B5763;
        line-height: 19.07px;
      }
      .metrics-item .compareInfo {
        font-weight: 400;
        font-size:10.67px;
        line-height:12.92px;
        color:#3B5763;
        max-width: 192px;
        margin-bottom:0;
        margin-left:4px;
      }
      .metrics-item .comparison {
        font-size: 12px;
        color: #007bff;
        margin-top: 8px;
      }
      
      .metrics-item .comparison-negative {
        color: #d9534f;
      }

      .comparison-table {
        width: 100%;
        border-collapse: collapse;
        font-family: Open Sans, Arial, sans-serif;
        .header-column {
          &:first-of-type {
            margin-right: 10px;
            position: sticky;
            left: 0;
            z-index: 1;
            background: #fff;
            min-width: 200px;
            @media (width < 768px) {
              min-width: 160px;
              max-width: 160px;
            }

          }
        }
       
      }
      
      .header-column {
        font-size:14px;
        font-weight: 600;
        text-align: start;
        min-height: 72px;
        align-items: center;
        max-width: 369px;
        width: 25%;
        font-size: 14px;
        min-width: 250px;
        .header-cell {
          // background-color:#189661;
          // border:1px solid blue;
          color:'#101828';
          line-height:'20px';
          border-bottom:1px solid #D9EBF1;
          min-height: 72px;
          display:flex;
          flex-direction: column;
          align-items: start;
          justify-content: center;
          padding-left:10px;
          padding-right: 10px;
          &:last-of-type .d-flex p {
            margin-bottom: 10px !important;
          }
        }
      }
      
      
      .data-column {
        display: grid;
        grid-template-rows: repeat(8);
        border-bottom: 1px solid #ddd;
        text-align: center;
        padding: 10px 0;
      }
      
      .data-row:nth-child(even) {
        background-color: #fafafa;
      }
      
      .data-row div {
        padding: 10px;
      }
      
      .header-row div {
        padding: 15px;
      }
      
      @media screen and (max-width: 768px) {
        .comparison-table {
          font-size: 12px;
          width: 180%;
        }
      
        .header-row,
        .data-row {
          grid-template-columns: repeat(4, 1fr);
        }
      }
      
      @media screen and (max-width: 576px) {
        .comparison-table {
          width: 300%;
        }
      }
      

      
// }

// Influencer Grid-----------------

.influencer-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  gap: 12px;
  // padding: 14px;
  
}

.card {
  // background: #fff;
  background-color: #F1F8FB;
  border: 1px solid #F1F8FB;
  border-radius: 8px;
  text-align: center;
  padding: 15px;
  transition: transform 0.2s;
  display:flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  // width:228px;
  height:228px;
  @media (width < 768px) {
    height: auto;
    align-items: flex-start;
  }
  .card-top {
    display: flex;
    flex-direction: column;
    align-items: center;
    @media (width < 768px) {
      flex-direction: row;
    }
  }
}

.card:hover {
  transform: translateY(-5px);
}

.card-right {
  @media (width < 768px) {
    text-align: left;
  }
}
.card-logo {
  border-radius:50%;
  max-width: 50px;
  margin-bottom: 10px;
  @media (width < 768px) {
    margin-right: 10px;
  }
}

.card-name {
  font-size: 14px;
  margin: 5px 0;
  font-weight:600;
  line-height: 20px;
  color:#101828;
  @media (width < 768px) {
    margin-bottom: 0px;
    }
}

.card-username {
  color: #777;
  font-size: 0.9rem;
  margin-bottom: 10px;
}

.card-stats {
  display: flex;
  justify-content: space-between;
  font-size: 0.8rem;
  color: #555;
  gap:4px;
  @media (width < 768px) {
    width: 100%;
  }
  
}

/* Container styles */
.top-section-influencer {
  background-color: #406776; /* Matches the background color */
  padding: 20px;
  border-radius: 10px;
  color: white;
  font-family: Arial, sans-serif;
  .section {
    margin-bottom: 20px;
  }
  
  .section-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
    font-size: 16px;
    font-weight: bold;
  }
  
  /* Right arrow style */
  .arrow {
    font-size: 18px;
    font-weight: bold;
    cursor: pointer;
  }
  
  /* Avatar container */
  .avatars {
    display: flex;
    gap: 10px;
  }
  
  /* Avatar images */
  .avatar {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    object-fit: cover;
    border: 2px solid white; /* Add border for a clean look */
  }
  
}

/* Section styles */
// lllllllllllllllllllllllllllllllllll


// .brands-data-container {
//     .brands-data-main {
//         .run-camp-card {
//             box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;
//         }

//         .brand-chart {
//             font-size: 20px;
//             font-weight: 400;
//         }

//         .brand-card {
//             font-size: 20px;
//             font-weight: 400;
//             color: #4c4c4c;
//         }

//         .brand-name {
//             font-weight: 600;
//             font-size: 30px;
//             background: -webkit-linear-gradient(45deg, #405de6, #5851db, #833ab4, #c13584, #e1306c, #fd1d1d);
//             -webkit-background-clip: text;
//             -webkit-text-fill-color: transparent;
//             background-clip: text;
//         }
//     }
// }
// .brand-insight-profile {
//     background-color:#F1F8FB;
// } 

.brand-insight-highchart {
  border-radius: 6px;
  box-shadow: 0 2px 9px rgba(0, 0, 0, 0.1);
}

.brand-insight-highchart-top {
  padding: 1rem 2.5rem 1rem;
  border-bottom: 2px solid rgb(236, 236, 236);
  margin-bottom: 1.25rem;
  display: flex;
  flex-wrap: wrap;
  @media (width < 768px) {
    padding: 1rem;
  }

  strong {
    color: #0B1354;
    font-weight: 600;
    font-size: 1.5rem;
  }
}

.brand-insight-highchart-data {
  padding: 0 1.5rem 1rem;
}

// .brands-data-container .highcharts-series.highcharts-series-2 {.highcharts-point{
//     &:first-child {
//             stroke: #165BAA;
//             fill: #165BAA;
//         }
//                 &:nth-of-type(2) {
//                     stroke: #A155B9;
//                     fill: #E697FF;
//                 }

//                 &:nth-of-type(3) {
//                     stroke: #189661;
//                     fill: #2BFFA6;
//                 }
// }}


.highcharts-axis-labels,
.highcharts-axis {
  font-family: "Open Sans", sans-serif;
}

.brand-insight-highchart-er .highcharts-series {
  &.highcharts-series-0 {
    .highcharts-point {
      stroke: #165BAA;
      fill: #63ABFD;
      stroke-width: 2px;
    }
  }

  &.highcharts-series-1 {
    .highcharts-point {
      stroke: #A155B9;
      fill: #E697FF;
      stroke-width: 2px;
    }
  }

  &.highcharts-series-2 {
    .highcharts-point {
      stroke: #189661;
      fill: #2BFFA6;
      stroke-width: 2px;
    }
  }

}

.brand-insight-influencer-competitor strong {
  display: flex;
  margin-bottom: 15px;
  font-size: 18px;
  color: #fff;
  font-weight: 400;
}

.brand-insight-influencer-competitor strong a {
  margin-left: auto;

  color: #fff;

  span {
    font-size: 30px;
  }
}


.brand-insight-influencer-competitor {
  background: #3B5763;
  
  border-radius: 10px;
  padding: 25px 40px;

  ul {
    padding-left: 0;
    display: flex;
    gap: 1rem;
    list-style: none;
    margin-bottom: 3rem;
    flex-wrap: wrap;

    &:last-of-type {
      margin-bottom: 0;
    }
  }
}

.brand-insight-influencer-competitor-row {
  display: flex;
  flex-direction: column;
  margin-bottom: 50px;

  &:last-of-type {
    margin-top: auto;
  }
}

.brand-pulse-influencer-details-table {
  // background-color: #fff;
  // border-radius: 10px;
  // border: 1px solid #D9EBF1;

  .brand-pulse-influencer-details-title {
    display: block;
    padding: 15px 0;
    padding-left: 20px;
    background-color: #fff;
      border-radius: 10px 10px 0 0;
      border: 1px solid #D9EBF1;
  }
  .common-table {
    border-radius: 0;
    padding-left: 0;
    table {
      border: 1px solid #C6DCE3;
      border-radius: 0 0 14px 14px;
    }
  }
  .common-table-header {
    tr th{
      background-color: #C6DCE3;
      font-size: .75rem;
      color: #667085;
      line-height: 1.3;
      font-weight: 600;
      border-radius: 0 !important;
      border-bottom: none;
    }
  }
  .common-table-body {
    tr {
      &:last-of-type {
        td{
          border-bottom: none;
        }
      }
    }
    tr td {
      border-bottom: 1px solid #D9EBF1;
      strong {
        line-height: 1.2;
      }
      &:last-of-type,
      &:first-of-type {
        border-left: none;
        border-right: none;
      }
    }
  }

}

.brand-pulse-similar-brands {
  padding: 20px;
  border: 1px solid #D9EBF1;
  border-radius: 10px;
  margin-bottom: 20px;
  strong {
      display: block;
      padding-bottom: 15px;
      font-weight: 500;
      font-size: 18px;
    }
}
.brand-insight-highchart-comparison {
  border: 1px solid #D9EBF1;
  box-shadow: none; 
  overflow: hidden;
  padding-left: 1.5rem;
  background-color: #fff;
  @media (width < 768px) {
    padding-left: 0rem;
  }
}
.brand-insight-highchart-comparison .brand-insight-highchart-title {
  font-size: 18px;font-weight: 500;
color: #2b2b2b;  
}
.brand-insight-highchart-comparison .brand-insight-highchart-data {
  overflow-x: auto;
  padding-left: 0;
}