.order-status-container{
    display:flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: calc(100vh - 120px) ;
    .order-status-heading {
        display: flex;
  margin-bottom: 15px;
  font-size: 20px;
  align-items: center;
  flex-wrap: wrap;
  border-bottom: 1px solid #dee2e6;
  padding-bottom: 5px;
    }
    svg{
        color: rgba(127, 244, 143, 1);
        font-size: 48px;
        margin-right: 10px;
    }
    p{
        font-size: 25px;

    }

    .order-details{
        display: flex;
        flex-direction: column;
        justify-content: center;
        border-radius: 10px;
        box-shadow: 5px 5px 5px 5px #ede6e6;
        padding: 20px 20px;

        p{
            font-size: 16px;
  margin-bottom: 10px;
  strong {
    min-width: 100px;
    display: inline-flex;
  }
        }
    }
}