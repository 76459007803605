.verification-section {
  .MuiTabPanel-root {
    padding-left: 0px;
    padding-right: 0px;
  }

  .MuiTab-root.Mui-selected {
    color: #00aae5;
  }

  .MuiTabs-indicator {
    background-color: #00aae5;
  }

  .campaign-table-data td {
    padding: 8px 16px;
  }

}

.campaign-verification-main,
.talent-verification-main {
  margin-left: 64px;
}

// .popup_content_container{
//   width: 450px;
//   @media screen and (max-width: 575px) {
//     width: 350px;
//   }

//   @media screen and (max-width: 385px) {
//     width: 100%;
//   }
// }
.tableCellHyperLink{
  .instagramLink {
    cursor: pointer;
    text-decoration: underline;
    color: #00aae5;
  }
}