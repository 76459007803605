$chatbox-height: 565px;
$chatbox-width: 392px;
$chatbox-header-height: 100px;
$conversion-height: 50px;

.pro-inf-chat-container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    border-radius: 20px;
    box-shadow: 0px 1px 5px 2px #0000002B;
    background: #FFFFFF;
    box-sizing: border-box;
    border: 1px solid transparent;
    width: 392px;
    height: $chatbox-height;
    padding: 0 1rem;
    @media (width < 768px) {
            width: 392px;
            max-width: 100%;
            margin-left: auto;
                margin-right: 0;
        }

    ul {
        margin: 0;
        padding: 0;
        list-style: none;

        li {
            display: flex;
            align-items: center;
            width: 100%;
            margin: 0;
            font-size: 14px;
            font-weight: 400;
            line-height: 16.34px;
            flex-direction: column;
        }
    }

    .influencer-chat-header {
        display: flex;
        align-items: center;
        padding: 10px 16px 10px 16px;
        border-radius: 60px;
        border: 1px;
        background: $color-f2f2f2;
        margin: 1rem 0px 0.625rem;
        // height: $chatbox-header-height;


        .pro-detail {
            display: flex;
            align-items: center;

            section {
                width: 150px;
                overflow: hidden;
                margin: 0px 10px 0px 0px;

                span {
                    text-wrap: nowrap;


                    &:nth-child(1) {
                        font-size: 16px;
                        font-weight: 700;
                        line-height: 24px;
                        color: #000000;
                    }

                    &:nth-child(2) {
                        font-size: 14px;
                        font-weight: 400;
                        line-height: 20px;
                        color: #000000;
                    }
                }

            }

            .back-btn {
                cursor: pointer;
                margin-right: 10px;
            }
        }

    }

    .chat-view {
        flex: 1;
        // max-height: 380px;
        overflow: hidden;
        overflow-y: auto;
        scroll-behavior: smooth;

        .child-ul {
            // border: 1px solid black;
            display: flex;
            width: 100%;
            height: 100%;
            flex-direction: column;
            overflow-y: auto;
            // height: calc($chatbox-height - $chatbox-header-height - $conversion-height);
            padding: 0px 10px;
            // overflow-x: hidden;
            margin-bottom: 30px;

            .child-li {
                display: flex;
            }
        }

        .parent-ul {
            display: flex;
            flex-direction: column;
            justify-content: flex-end;
            width: 100%;

            .to {
                justify-content: flex-end;
                flex-direction: end;
                flex-direction: row;

                .profile-image-size {
                    margin-right: 0;
                }

                span {
                    padding: 8px 12px 8px 12px;
                    border-radius: 10px 0px 10px 10px;
                    background: #CCD2DE;
                    margin-right: 10px;
                    word-break: break-word;
                }
            }

            .from {
                flex-direction: row-reverse;
                justify-content: start;

                span {
                    padding: 8px 12px 8px 12px;
                    border-radius: 0px 10px 10px 10px;
                    background: #EFF2F7;
                }
            }

        }
    }

    .conversion-section {
        margin: 5px 0px 10px 0px;
        height: $conversion-height;
        position: relative;
        // border: 1px solid black;

        .send-btn {
            display: flex;
            margin: 0px;
            padding: 0px;
            background: transparent;
            border: none;
            position: absolute;
            right: 10px;
            bottom: calc(50% - 10px);
            font-size: 1.5rem;
            width: auto;
            color: #000;
            @media (width < 768px) {
                bottom: calc(50% - 6px);
            }
            .send-icon {
                font-variation-settings: "FILL" 1, "wght" 700, "GRAD" 0, "opsz" 48;
            }

        }

        input[type="text"] {
            width: 100%;
            // padding: 0.625rem 2.5rem 0.625rem 2.75rem;
            padding: 0.625rem 2.5rem 0.625rem 1.25rem;
            border-radius: 3.75rem;
            border: 1px;
            background: $color-f2f2f2;
        }
    }

}

.send-box-attach-icon {
    position: absolute;
    width: 44px;
    height: 39px;
    top: 0;
    bottom: 0;
    margin: 0 auto;
    display: flex;
    align-items: center;
}

.conversion-section #file-input {
    display: none;
}

.conversion-section label[for='file-input'] * {
    vertical-align: middle;
    cursor: pointer;
}

.conversion-section label[for='file-input'] span {
    margin-left: 10px
}