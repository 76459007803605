.insta-logo-topinfluencer{
    display: flex;
    padding-bottom: 10px;
}
.yt-logo-topinfluencer{
    display: flex;
    padding-bottom: 10px;
}

.ag-courses-item_link{
    cursor: pointer;
}
// $color-primary: #00aae5 !default;
// $border-color: #bcbcbc !default;


// //Modern card


// .sub-section-1 {
//   border: 1px solid black;
// }

// // .maininsight-title {
// //   margin-left: 20px;
// //   margin-top: 10px;
// // }

// .oriflame {
//   display: flex;
//   justify-content: space-between;
//   margin-bottom: 1rem;
// }

// .oriflame-container {
//   display: flex;
//   justify-content: space-between;
//   margin-bottom: 30px;
//   flex-direction: column;

//   @media only screen and (min-width:768px) {
//     flex-direction: row;
//     align-items: center;
//   }
// }

// .toggle {
//   // margin-top: 8px;
//   margin-right: 15px;
// }



// .date {
//   margin-left: 10px;
// }

// label.ortab {
//   text-transform: lowercase;
// }

// .wrapper {
//   display: grid;
//   grid-template-columns: repeat(3, 1fr);
//   grid-gap: 50px;
//   width: 300px;
//   margin: 0 auto;
// }

// .wrapper>div {
//   background: #ffffff;
//   line-height: 50px;
//   height: 50px;
//   text-align: center;
// }

// .game-board {
//   display: grid;
//   grid-template-rows: 100px 100px 100px;
//   grid-template-columns: 300px 300px 300px;
// }

// .game-board {
//   justify-content: center;
// }

// .maininsight {
//   border-radius: 0px;
//   background: none;
//   border: none;
//   display: flex;
//   justify-content: space-around;
//   flex-wrap: wrap;
// }

// .box {
//   background: #f9f8f8;
//   padding: 30px;
//   display: flex;
//   align-items: center;
//   justify-content: center;
//   color: #080808;
// }

// .running-campaigns {
//   display: flex;
//   justify-content: space-between;
//   margin-top: 3rem;
// }

// .line {
//   border-top: 1px solid #000000;
//   width: 100%;
//   margin: 3px;
// }

// .run-camp-card {
//   // border: 1px solid $border-campaign-detail-card;
//   justify-content: center;
//   // margin: 5px;
//   // padding: 0px;
//   // width: 100%;
//   display: flex;
//   flex-direction: column;
//   align-items: center;
//   background-color: #fff;
//   padding: 10px;
//   // border-radius: $border-radius-card;

//   p {
//     text-transform: capitalize;
//   }

//   .icon {
//     cursor: pointer;

//     &:hover {
//       color: $color-primary;
//     }
//   }
// }

// // .chart-bar {
// //   border: 1px solid black;
// //   margin-top: 5px;
// // }

// .chart-main {
//   width: 100%;
// }

// .allcampaigns {
//   background: #f2efef;
// }

// .tp_influencers {
//   background: #f2efef;
// }

// .imageNameDiv {
//   display: flex;

//   .imageDiv {
//     width: 40px;
//     height: 40px;
//     border: 1px solid #dee2e6;
//     border-radius: 50%;
//   }

//   .instaNameDiv {
//     display: flex;
//     flex-direction: column;
//     justify-content: center;
//     padding-left: 20px;
//   }
// }

// .tp-info {
//   width: 100%;
//   justify-content: space-around;
// }

// .dashboard-detail .MuiGrid-container {
//   max-width: 600px;
//   margin: 0 auto;
// }

// @media only screen and (max-width: 575px) {
//   .sub-section-1 {
//     box-sizing: border-box;
//   }

//   .container-sm.camp-duration-data {
//     display: block;
//     box-sizing: border-box;
//     width: 100%;
//     margin-left: 10px;
//   }

//   // .MuiBox-root.css-ddx2y5 {
//   //   margin: 10%;
//   // }

//   // .maininsight-title {
//   //   margin-left: 10px;
//   // }

//   .css-wjh20t-MuiPagination-ul {
//     height: 50px;
//   }

//   // .css-ymqfn8-MuiStack-root {
//   //   max-width: 300px;
//   //   // margin: auto;
//   // }
// }

// @media only screen and (max-width: 767px) {
//   .sub-section-1 {
//     box-sizing: border-box;
//   }

//   .container-sm.time {
//     display: block;
//     box-sizing: border-box;
//     width: 100%;
//     margin-left: 10px;
//   }

//   // .MuiBox-root.css-ddx2y5 {
//   //   margin: 10%;
//   // }

//   // .maininsight-title {
//   //   margin-left: 10px;
//   // }

//   .css-wjh20t-MuiPagination-ul {
//     height: 50px;
//   }

//   // .css-ymqfn8-MuiStack-root>.MuiTextField-root {
//   //   max-width: 300px;
//   // }
// }

// @media only screen and (max-width: 991px) {
//   .css-wjh20t-MuiPagination-ul {
//     height: 50px;
//   }
// }

// // .camp-duration-date {
// //   display: flex;

// //   .MuiStack-root {
// //     overflow: hidden;
// //   }
// // }