.influencer-clan-shop{
    form{
        margin: 0 auto;
        width: 500px;

        button{
            width: 100%;
        }
    }
}



.affl-gen-link p {
    padding: 5px 10px;
    border: 1px solid #dee2e6;
    border-radius: 4px;
  }
  .affl-gen-link {
    margin-top: 10px;
    position: relative;
    max-width: 80%;
  }
  .affl-gen-link #copy-icon {
    position: absolute;
    top: 10px;
    right: 10px;
    cursor: pointer;
  }