$border-color-card: #dee2e6 !default;
$border-radius-4px: 4px !default;

.influencer_setting_refer_main {

    .influencer_setting_refer {
        margin-left: 100px;
    }

    ._influencer_setting_refer_earn_text {
        margin: 0px;
        padding: 0px;
    }

    ._influencer_setting_refer_earn_middle {
        border: 1px solid $border-color-card;

        padding: 20px 30px;
        padding-bottom: 100px;
        margin-top: 20px;
        border-radius: $border-radius-4px;

    }

    ._influencer_refer_earn_data {
        margin-top: 100px;
    }

    ._refer_earn_setting {
        ul {
            list-style: none;
            margin: 0px;
            padding: 0px;
            display: flex;
            justify-content: space-between;
            border: 1px solid gray;
            background-color: rgb(231, 221, 221);
        }
    }
}