
.popup-drawer{
  &.guide-to-connect {
    .MuiPaper-elevation {
      width: 100%;
      @include breakpoint(small) {
        width: clamp(400px, 420px, 450px);
      }
    }
    &.apply-restriction-popup {
      z-index: 1300;
    }
  }
}
.MuiModal-open.safari-video-block {
  z-index: 1400;
}
.popup-drawer {

  // .MuiAutocomplete-paper {
  //   // padding: 20px;
  //   width: 60%;
  // }

  .popup-drawer-header {
    padding: 1.25rem 1.5rem 1.25rem 1.5rem;
    display: grid;
    grid-auto-flow: column;
    grid-template-columns: 1fr auto;
    border-top: 1px solid $border-campaign-detail-card;

    bottom: 0;
    width: 100%;
    background: $color-white;
    z-index: 2;
    top: auto;


    @include breakpoint(small) {
      padding: 1.25rem 1.5rem 1.25rem 1.5rem;
      position: sticky;
      top: 0;
      border-top: auto;
      border-bottom: 1px solid $border-campaign-detail-card;
    }

    &-left {
      position: fixed;
      top: 20px;
      right: 10px;

      @include breakpoint(small) {
        position: static;
        max-width: 100%;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        width: 100%;
        display: flex;
        align-items: center;

      }

      .scrolling-text-sec {
        max-width: calc(100% - 1.875rem);
        padding-left: 10px;
      }

      .inner-scrolling-text-container {
        max-width: 100%;
        vertical-align: -5px;
      }
    }



    &-right {
      gap: 0.75rem;
      display: grid;
      grid-auto-flow: column;
    }
  }

  &.drawer-infl-dashboard {
    .MuiDrawer-paper {
      width: 100%;
      border-radius: $border-radius__10;

      @include breakpoint(small) {
        width: 60%;
        // top: 0 !important;
        // left: auto !important;
      }
    }

    .dashboard-card {
      grid-template-columns: auto;
      padding-left: 1.3125rem;
      padding-right: 1.125rem;
      border-bottom-color: $border-campaign-detail-card ;
      margin-bottom: 1.25rem;
      border-top: none;
      border-bottom: 1px solid #d7d7d7;

      &:hover,
      .active,
      .active:hover {
        background-color: transparent;
      }
    }

    .popup-drawer-body {
      padding-left: 1.4375rem;
      padding-right: 2.25rem;
      height: calc(100svh + 150px);

      .camp-detail-card {
        &:last-of-type {
          // margin-bottom: 5rem;
          padding-bottom: 6rem;
        }
      }
    }

    .camp-detail-card-inner.grid-3-col {
      grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
      grid-gap: 1rem;

      @include breakpoint(small) {
        grid-gap: 52px;
      }
    }

    .inf-description {
      display: inline-block;
      overflow: visible;
      text-overflow: inherit;
      display: block;
      -webkit-line-clamp: initial;
      line-clamp: initial;
      -webkit-box-orient: inherit;

      .description {
        overflow: initial;
        text-overflow: initial;
        display: initial;
        -webkit-line-clamp: initial;
        line-clamp: initial;
        -webkit-box-orient: initial;
      }
    }

    .camp-detail-card {
      padding-left: 1.25rem;
      padding-right: 1.25rem;
    }

    &.drawer-add-infl {
      .MuiDrawer-paper {
        // width: 30%;
        
        width: clamp(400px, 40%, 400px);
        
        @media (width < 48rem) {
          width: 100%;
          border-radius: 10px 10px 0 0;
        }
      }
      .popup-drawer-header-left {
        @media (width < 48rem) {
            bottom: 10px;
            top: auto;
          }
      }
    }

    @media (width < 48rem) {
      .popup-drawer-header {
        padding: 1.25rem 1.25rem 1.25rem 1.5rem;
        display: grid;
        grid-auto-flow: column;
        grid-template-columns: 1fr auto;
        border-top: 1px solid $border-campaign-detail-card;
        position: fixed;
        bottom: 0;
        width: 100%;
        background: $color-white;
        z-index: 2;
        top: auto;


      }
    }
  }
}


.custom-drawer-footer-sticky {
  position: sticky;
  bottom: 0;
  background: #fff;
  width: calc(100% + (1.4375rem + 1rem));
  margin-top: 30px;
  margin-left: -1.4375rem;
  padding: 1rem;
  z-index: 11;
  margin-right: 55px;
}