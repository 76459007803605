.input-checkbox-box-container {
  gap: 1.25rem;
  input:checked+label {
    background-color: #2b2b2b;
    // color: $color-white;
    border-color: $color-black;

    >span {
      color: $color-white;
    }

    .icon-white {
      color: $color-white;
    }
  }
  .input-checkbox-box {
    position: relative;
    z-index: 0;
    padding: 0.625rem 1.25rem;
    margin-left: 0;
    margin-right: 0;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
    border: 1px solid $color-black;
    border-radius: 2.5rem;
    flex: 0 0 auto;
    margin-left: 0;
    margin-right: 0;
    color: #000;
    .icon-white {
      color: #f00;
    }
    &.icon-active {
      .MuiTypography-root {
        color: #fff;
      }
    }
    > span {
      color: #000;
    }
    &.active {
      .input-checkbox-box-label {
          color: #fff;
        }
    }
    
    

    .MuiCheckbox-root {
      position: static;

      &:after {
        content: "";
        background: transparent;
        width: 100%;
        height: 100%;
        position: absolute;
        left: 0;
        z-index: -1;
        top: 0;
        border-radius: $border-radius-card;
      }

      &.Mui-checked:after {
        background: #000;
        color: #fff;
        border-radius: 50px;
      }
    }

    &:first-of-type {
      border-left: 1px solid $color-black;
    }
    span:last-of-type {
      font-size: 1rem;
      margin-left: 5px;
      font-weight: 500;
    }
  }

}

// .input-checkbox-box.filter-social-platform-icon.icon-active.filter-content-outline .MuiTypography-root {
//   color: #fff !important;
// }