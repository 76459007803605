.brand_creative_main_container {
  .selector-container {
    width: 100%;
  }

  // .Rejected {
  //     // border: .125rem solid black;
  //     // display: flex;
  //     margin-top: 1.25rem;
  //     padding: .3125rem .625rem;
  //     border-radius: .3125rem;
  //     background-color: red;
  //     color: white;
  //     font-weight: bold;
  // }

  // .Approved {
  //     // display: flex;
  //     margin-top: 1.25rem;
  //     padding: .3125rem .625rem;
  //     border-radius: .3125rem;
  //     background-color: green;
  //     color: white;
  //     font-weight: bold;
  // }

  // .Pending {
  //     // display: flex;
  //     margin-top: 1.25rem;
  //     padding: .3125rem .625rem;
  //     border-radius: .3125rem;
  //     background-color: yellow;
  //     color: white;
  //     font-weight: bold;

  // }

  .selected-creative {
    // margin-top: .625rem;
    max-height: 100%;
    min-height: 100%;
    object-fit: cover;
    margin: 0 auto;
    width: 100%;
    border-radius: $border-radius-card;
    aspect-ratio: 1 / 1;
  }

  .send-comment {
    display: flex;
  }

  .inf-details-main-container {
    .proposal-comment-send-box {
      display: none;
    }
  }

  .proposal-detail-container {
    height: 380px;
  }

}


.creatives-container {
  padding: 0;
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
  // margin: 0 -0.9375rem;

  @include breakpoint(small) {
    // padding: 0 .9375rem;
  }


  .creatives-container-list {
    // margin: 1.25rem .625rem;
    // display: flex;
    // flex-direction: column
    // border-bottom: .0625rem solid lightgray;
    // margin-bottom: 1.25rem;
    font-size: $font-size-sml;
    // border-bottom: .0625rem solid $border-color-card;
    padding: 0;
    border-radius: 0;
    padding: 0;
    border-radius: .875rem;
    flex: 0 0 100%;

    @include breakpoint(xsml) {
      flex: 0 0 calc(50% - 0.75rem);
    }

    @include breakpoint(small) {
      flex: 0 0 calc(33.33333% - 0.75rem);
    }

    @include breakpoint(medium) {
      flex: 0 0 calc(25% - 1.25rem);
    }

    @include breakpoint(xlarge) {
      flex: 0 0 calc(20% - 1rem);
    }

    border: none;
    position: relative;
    aspect-ratio: 1/1;

    .creative-card {
      position: relative;
      overflow: hidden;
    }

    .creative-card-action {
      bottom: .625rem;
      padding: .625rem;

      width: auto;
      border-radius: .625rem;
      opacity: 1;
      position: absolute;
      z-index: 1;
      // transform: translateX(4.375rem);
      transition: transform 0.35s ease 0.15s, opacity 0.35s ease;

      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      visibility: visible;
      background: rgba(0, 0, 0, 0.8);
      right: 10px;
      gap: 8px;

      >span {
        color: $color-white;
        // margin-bottom: .625rem;
        font-size: 1.25rem;
        cursor: pointer;

        &:hover {
          color: rgba($color-white, 0.8);
        }
      }

      .btn {
        margin-right: .3125rem;
      }
    }

    &:hover {

      .creative-card {
        &:after {
          top: 0;
          opacity: 1;
          transform: translateX(0);
        }
      }

      .creative-card-action {
        visibility: visible;
        opacity: 1;
        transform: translateX(0);
      }
    }
  }



}

.comment-sec-wrapper {
  width: 100%;
  // padding: 0 .625rem;

  .send-comment {
    margin-bottom: .9375rem;
  }

  .comments-section {
    width: 100%;

    .text-field-section {
      label {
        font-size: $font-size-sml;
      }
    }
  }
}

.comments {
  padding-left: 0;
}

.comment-section {
  // border: .125rem solid black;

  .text-field-section {
    display: flex
  }

  li {
    display: flex;
    justify-content: space-between;
    padding-bottom: .5rem;
    margin-bottom: .5rem;
    border-bottom: .0625rem solid $border-color-card;
    // flex-direction: column;

    &:last-child {
      border: none;
    }

    .created_at {
      font-size: small;
      font-style: italic;
    }
  }
}

.creative-detail {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr;
  grid-column-gap: 0rem;
  grid-row-gap: 0rem;
  height: calc(100svh - 200px);
  @media (width < 48rem) {
    grid-template-columns: auto;
  }

  &.inner {
    flex-direction: column;
    overflow-x: auto;
    padding-bottom: 0;

    @include breakpoint(small) {
      grid-template-columns: 1fr;
      grid-template-rows: 1fr;
      padding-bottom: 2.5rem;
      height: calc(100vh - 200px);
    }



    .creative-detail-img {
      max-width: inherit;
      width: auto;
      height: auto;
      border-right: 0.0625rem solid $border-creative-card;

      @include breakpoint(small) {
        max-width: 100%;
        width: 100%;
      }


      img,
      video {
        // border: .0625rem solid $border-creative-card;


      }


    }

  }

  .creative-detail-img {
    // max-width: 100%;
    // width: 100%;
    // border: .0625rem solid $border-creative-card;
    border-right: none;
    display: flex;
    justify-content: center;
    align-items: center;
    // height: 200px;
    height: 95%;
    overflow: hidden;
    margin-bottom: 1.25rem;

    @media (width < 48rem) {
      height: 150px;
    }

    img,
    video {
      width: auto;
      max-height: 27.125rem;
      max-width: 100%;
      border-right: none;
      border-radius: 0rem;
      object-fit: contain;
      cursor: zoom-in;

      .creative-infl-side & {
        cursor: default;
      }

      // @media (width < 48rem) {
      //   height: auto;
      // }

      @media (width < 48rem) {
        border: .0625rem solid $border-creative-card;

        aspect-ratio: 16 / 9;
        height: auto;
        max-height: 150px;
      }

    }
  }
}

.brand_creative_main_container .inf-child-section .table-plan,
.brand_creative_main_container .selector-tab {
  display: none;
}

.brand-creative-container {
  padding: 1.25rem 0;
}

.creative-detail {
  .modal-content {
    border-radius: 0;
    border: none;
    height: 102%;
    min-height: 102%;

    @media (width < 768px) {
      min-height: 274px
    }
  }
}

.chatbox.showbox.pro-inf-chat-container {
  height: calc(100vh - 71vh)  ;
  width: 100%;
  @media (width < 768px) {
      height: 44vh;
    }
}

.chatbox.showbox.pro-inf-chat-container .modal-dialog-scrollable {
  height: calc(100% - 0rem);
}

.profile-info {
  display: flex;

  &-img {
    width: 3rem;
    height: 3rem;
    border-radius: 50%;
    border: 1px solid #b5b5b5;
    display: inline-flex;
    font-size: 0;
    margin-right: .625rem;
  }

  &-right {
    display: flex;
    flex-direction: column;

    strong {
      font-weight: 600;
      line-height: 1.3;
    }
  }
}

.container-approved-creative {
  .creatives-container-list {
    display: flex;
    gap: .625rem;
    flex-direction: column;

    .creative-card {
      border-color: #b5b5b5;
    }
  }
}