$label-height: 80px;

.proposal-chat-container {
    width: 392px;
    position: fixed;
        right: 2rem;
        bottom: 6.5rem;
        // opacity: 0;
        transform: scale(0);
        transform-origin: bottom right;
        transition: all 0.35s cubic-bezier(0.075, 0.82, 0.165, 1);
        z-index: 9;
        &.proposal-chat-active {
            // opacity: 1;
            transform: scale(1);
            transition: all 0.35s cubic-bezier(0.075, 0.82, 0.165, 1);
        }
        
        @media (width < 768px) {
        right: 2rem;
        width: calc(100% - 2rem);
        }
}

.pro-inf-list-container {
    border-radius: 1.25rem;
    box-shadow: 0px 1px 5px 2px #0000002B;
    background: $color-white;
    position: relative;
    width: 392px;
    height: 565px;
    padding: 1rem;
    @media (width < 768px) {
        width:392px;
        max-width: 100%;
        margin-left: auto;
            margin-right: 0;
    }
    .profile-blurred {
        filter: blur(3px);
    }

    // .material-symbols-outlined {
    //     font-variation-settings:
    //         'FILL' 1,
    //         'wght' 400,
    //         'GRAD' 0,
    //         'opsz' 24
    // }

    .pro-inf-chat-label {
        display: flex;
        align-items: center;
        width: 100%;
        // height: $label-height;
        margin-bottom: 0.5rem;

        .col {
            display: flex;
            align-items: center;
            width: 200px;
            overflow: hidden;

            span {
                text-wrap: nowrap;
            }
        }

        .campaign-name {
            font-size: 24px;
            font-weight: 700;
            margin: 0px 20px 0px 10px;
            text-align: left;
            width: 12rem;
            overflow: hidden;

        }

        .influencer-count {
            font-size: 14px;
            font-weight: 600;
            line-height: 19.07px;
            text-align: left;
            color: #7C828A;
        }
    }

    ul {
        // width: 392px;
        // height: 565px;
        list-style: none;
        // margin: $label-height 0px 0px 0px;
        padding: 0px;
        overflow-y: auto;
        max-height: 460px;
        padding-right: 10px;

        li {
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 10px 16px 10px 16px;
            border-radius: 1rem;
            background-color: $color-f2f2f2;
            // opacity: 0px;
            margin: 10px 0px;
            cursor: pointer;

            .profile {
                display: flex;
                align-items: center;
                width: 190px;
                overflow: hidden;

                span {
                    text-wrap: nowrap;

                    &:nth-child(1) {
                        font-size: 16px;
                        font-weight: 700;
                        line-height: 24px;
                    }

                    &:nth-child(2) {
                        font-size: 14px;
                        font-weight: 400;
                        line-height: 20px;
                    }
                }
            }

        }
    }
}

.inactive-count {
    opacity: 0;
}

.proposal-status {
    font-size: 11px;
    font-weight: 400;
    line-height: 14.98px;
    padding: 5px 10px;
    border: 1px solid #E3E8F1;
    border-radius: 10px;
    background: #F9FAFC;
    text-align: center;
}

.message-count {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 15px;
    height: 15px;
    font-size: 10px;
    border-radius: 50%;
    background: #00AAE5;
    color: #FFFFFF;

}

.profile-image-size {
    height: 44px;
    width: 44px;
    border-radius: 50%;
    border: 1px solid #838383;
    margin-right: 10px;
    min-width: 44px;
}

.influencer-details {
    overflow: hidden;
    max-width: 68%;
    text-overflow: ellipsis;
    @media (width < 700px) {
        max-width: inherit;
        padding-right: 22px;
    }
}