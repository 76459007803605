.subs-sec .container {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    margin: 0 auto;
    justify-content: flex-start;
    align-items: center;
    max-width: 1320px;
    padding: 0 15px;
}

.subs-sec.compare-infl {
    padding-top: 0;
}

.subs-sec.compare-infl.bottom-row ul {
    display: flex;
    width: 100%;
    flex-direction: column;
    margin-bottom: 0;
}

.subs-sec.compare-infl.bottom-row ul li {
    display: flex;
    width: 100%
}

.compare-infl-title {
    padding: 12px 5px 12px 0px;
    border-bottom: 1px solid #ddd;
    text-align: left;
    flex: 0 0 280px;
    margin-right: 20px
}

.compare-infl-text {
    padding: 12px 0px;
    border: 1px solid #eee;
    border-bottom-color: #ddd;
    margin-right: 20px;
    flex: 1;
    border-left-color: #ddd;
    border-right-color: #ddd;
    border-top-color: transparent;
    text-decoration: none;

    .stacked-list-item{
        background: #0c8879;
        position: relative;
        padding: 5px 10px 5px 10px;
        margin-bottom: 4px;
        text-align: left;
        color: #fff;
        align-items: center;

        ::before{
        position: absolute;
        left: 5px;
        font-size: 12px;
        }
    }
}

.labelContainer{
        height: 24px;
        display: flex;
        width: 180px;
        overflow: hidden;
        position: relative;
        align-items: center;

        .city-name {
            margin-left: 10px;
    } 
}

.compare-infl-text-sec {
    flex: 1;
    display: flex;

    .highcharts-container {
        width:300px;
    }
}



.compare-infl-text-sec .compare-infl-text:last-of-type {
    margin-right: 0
}

.blank-heading {
    border-bottom: transparent;
    font-weight: 600;
    padding-bottom: 0px;
    text-transform: uppercase;
    min-height: 51px;
    padding-top: 30px
}

.blank-heading span {
    font-size: 14px;
    padding-bottom: 0px;
    border-bottom: 1px solid #5d5d5d;
    display: inline-block
}

.blank-sec {
    flex: 9999;
    display: flex
}

.blank-sec span {
    flex: 1;
    border: 1px solid #eee;
    border-bottom: none;
    border-left-color: #ddd;
    border-right-color: #ddd;
    border-top-color: transparent
}

.blank-sec span:last-of-type {
    margin-right: 0
}

.subs-sec.compare-infl.bottom-row ul li.space-lg .compare-infl-title,
.subs-sec.compare-infl.bottom-row ul li.space-lg .compare-infl-text {
    padding-top: 20px;
    padding-bottom: 20px
}

.subs-sec.compare-infl.bottom-row.auto .compare-infl-text {
    border: 1px solid #eee;
    border-top-color: #ddd;
    border-left-color: #ddd;
    border-right-color: #ddd
}

.subs-sec.compare-infl.bottom-row ul.tab-pane,
.subs-sec.compare-infl.top-row ul.tab-pane {
    flex: 0 0 calc(33.33% - 15px)
}

.subs-sec.compare-infl.bottom-row ul {
    margin-bottom: 0
}

.subs-sec.compare-infl.bottom-row .heading-row:first-of-type {
    padding-top: 0;
    min-height: 21px
}

.subs-sec.compare-infl.bottom-row .subs-title-sec ul li:nth-of-type(18),
.subs-sec.compare-infl.bottom-row .subs-plan-sec ul li:nth-of-type(18),
.subs-sec.compare-infl.bottom-row .subs-title-sec ul li:nth-of-type(19),
.subs-sec.compare-infl.bottom-row .subs-plan-sec ul li:nth-of-type(19),
.subs-sec.compare-infl.bottom-row .subs-title-sec ul li:nth-of-type(20),
.subs-sec.compare-infl.bottom-row .subs-plan-sec ul li:nth-of-type(20) {
    min-height: 65px
}

.subs-sec.compare-infl.bottom-row .subs-title-sec ul li:nth-of-type(17),
.subs-sec.compare-infl.bottom-row .subs-plan-sec ul li:nth-of-type(17) {
    min-height: 59px
}

.subs-sec.compare-infl.bottom-row ul.tab-pane {
    box-shadow: 0px 0px 16px rgba(0, 0, 0, 0.1);
    border-radius: 0 0 10px 10px
}

.compare-infl .progress-bar {
    background-color: #00aae5
}

.compare-infl .progress .progress-bar:nth-of-type(2) {
    background-color: #f25094
}

.compare-username {
    font-size: 16px;
    color: #2b2b2b;
}


.compare-social-username {
    color: #00aae5;

}