.personal_info-header{
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
}

.personal_info-image-div{
    display: flex;
    align-items: center;
    margin-bottom: 25px;

    .personal_info-image{
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-right: 40px;

        .personal_info-image-button{
            font-size: 22px;
            border-radius: 50%;
           padding: 5px 12px;
            position: relative;
            bottom: 40px;
            left: 40px;
            background-color: #00aae5;
            color: white;
            align-items: center;
            text-align: center;
        }

        img{
            width: 150px;
            height: 150px;
            border-radius: 50%;
        }
    }

    .personal_info-image-right-heading{
        width: 100%;

        .MuiInput-input {
            font-size: 20px;
            font-weight: 600;
        }
    }

   
}