.toogle-chat {
    background: black;
    border: none;
    height: 60px;
    width: 60px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    position: fixed;
    bottom: 2rem;
    right: 2rem;
    z-index: 10;

    .proposal-icon {
        color: white;
    }

    .toggle-message_count {
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        top: 0;
        right: 0;
        width: 15px;
        height: 15px;
        padding: 10px;
        font-size: 10px;
        border-radius: 50%;
        background: #00AAE5;
        color: #FFFFFF;
    }

}

.proposal-chat {
    // position: fixed;
    // left: 0;
    // bottom: 50px;
    // z-index: 9999;

    // .row {
    //     justify-content: flex-end;
    //     align-items: flex-end;
    // }
}