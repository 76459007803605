.deliverable-component {


   

}

.autocomplete_option_inactive {
    background-color: lightgray !important;
    opacity: 0.5;
    cursor: not-allowed;
    pointer-events: none;
}

.cln_autocomplete_default{
    cursor: pointer;
    &.disabled {
        pointer-events: none;
    }
    span.disabled {
        opacity: .5;
    }
}

.autocomplete-option {
    font-size: 14px;
    background-color: inherit;
    transition: background-color 0.3s ease;
    height: 34px;
    min-height: auto;
  
    &:hover {
      background-color: rgba(0, 0, 0, 0.04);
    }
  
    &.selected {
      background-color: #f0f8ff; 
  
      &:hover {
        background-color: rgba(25, 118, 210, 0.12);
      }
    }
  }
  
  