._influencer_setting_personal_info_main {

    .col {
        width: 100%;
    }

    .influencer_personal_info {
        margin-top: 10px;


    }

    ._influencer_setting_img {
        position: relative;

        img {
            height: 100px;
            width: 100px;
            border-radius: 50%;
            border: 1px solid black;
            display: inline-block;
            position: relative;

        }
    }

    ._influencer_setting_img_change_btn {




        >button {
            position: absolute;
            bottom: 6px;
            right: 10px;
            background: #fff;
            padding: 5px;
            border-radius: 50%;
            border: 1px solid #000;
            width: 30px;
            height: 30px;
            min-width: 30px;
            display: flex;
            justify-content: center;
            align-items: center;
        }
    }

    .genre_personal_info {
        label {
            margin-top: 8px;
        }
    }

    #demo-multiple-name-label {
        margin-top: 10px;
    }

    #demo-simple-select-label {
        margin-top: 10px;
    }

    ._dobinput {

        input::-webkit-outer-spin-button,
        input::-webkit-inner-spin-button {
            -webkit-appearance: none;
            margin: 0;
        }

        // input{
        //     height: 1.4375em;
        // }

        input[type=number] {
            -moz-appearance: textfield;
        }
    }

    // ._influencer_dob_slash {
    //     padding: 5px;
    // }

    ._signup_dob_section {
        input {
            height: 1.4375em;
        }
    }

    .date_picker {
        input {
            padding: 10px 14px;
        }

        label {
            line-height: .85em;
        }
    }
}