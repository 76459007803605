$errorColor: #d32f2f !default;

.product-details-main {
  display: flex;

  .container {
    background-color: #fff;
    padding: 30px;
    border-radius: 8px;
  }

  img {}

  .details {}

  .return-exchange-icon {
    display: inline-flex;
    width: auto;
    text-align: center;
    // margin-top: 20px;
    // padding: 10px;
    font-size: 14px;
    margin-bottom: 0;

    &:hover {
      cursor: pointer;
      color: #00aae5;
    }
  }

  .product-detail-img {
    border: 1px solid #dee2e6;
    border-radius: 8px;
    margin-bottom: 15px;

    img {
      border-radius: 8px;
      width: 100%;
    }
  }

  .delivery_availaible{
    color: rgb(37, 231, 37);
  }
}

.MuiDialog-paperWidthMd {
  max-width: 600px !important;
  width: 600px !important;
}

.user-add-details {
  .influencer_personal_info {
    margin-bottom: 10px;
  }
}

.product-quantity {
  margin-left: 10px;
  display: flex;
  align-items: flex-start;
}

.prod-qty-btn {
  border-radius: 4px;
  background: #00aae5;
  color: #fff;
  width: 40px;
  height: 40px;
  border: 1px solid #00aae5;
}

.prod-qty-btn[disabled] {
  background: #eee;
  color: #2b2b2b;
  border: 1px solid #dee2e6;
}

.product-quantity-input {
  width: 40px;
  height: 40px;
  text-align: center;
  margin: 0 5px;
}


.prod-inventory {
  font-size: 12px;
  padding: 2px 10px;
  border-radius: 4px;
  margin-bottom: 10px;
  display: inline-block;
}

.out-of-stock {
  background: #f00;
  color: #fff;
}

.in-stock {
  background: rgb(58, 132, 58);
  color: #fff;
}

.few-stock {
  background: rgb(212, 158, 71);
}