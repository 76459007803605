.product-integeration {
  .headings {
    display: flex;
    align-items: center;
    justify-content: space-around;
    border-bottom: 1px solid;

  }

  margin-bottom: 70px;

  .divider {}

  // .ql-toolbar.ql-snow{
  //     display: none;
  // }

  // .ql-container.ql-snow{
  //     ;
  // }

  .editor_components .ql-toolbar.ql-snow+.ql-container.ql-snow {
    border: 1px solid #ccc !important;
    height: 232px !important;
  }

  h3 {
    font-weight: bold;
  }

  // #next-btn {
  //   float: right;
  //   margin-right: 25px;
  //   margin-bottom: 10px;
  // }

  .integration-container {
    display: flex;
    flex-wrap: wrap;
    border: 1px solid #dee2e6;
    border-radius: 8px;


    @media only screen and (max-width:991px) {
      flex-direction: column;

    }


    .inner-container {
      flex: 1;

    }
  }

  .inner-container h4 {
    font-weight: 500;
    font-size: 20px;
    color: #00aae5;

  }

  .container {
    // display: flex;
    // // flex-direction: column;
    // align-items: center;
    // justify-content: space-around;

    .inner-container {
      width: 100%;
      padding: 20px 20px 40px;
      border-right: 1px solid #ddd;

      &:last-of-type {
        border-right: none;
      }

      .inner-container-data {
        // height: 200px;

        p {
          display: flex;
          flex-direction: column;
          align-items: center;
        }
      }

      .code-snippet-container {
        background: black;
        color: #0af30a;
        min-height: 300px;
        padding: 20px;

        @media only screen and (max-width:991px) {

          min-height: inherit;

        }

        #copy-icon {
          float: right;
        }
      }
    }
  }
  pre{
    overflow: inherit !important;
  }
}


.inner-container-title {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  border-bottom: 1px solid #ddd;
  padding-bottom: 10px;
  margin-bottom: 15px;
  line-height: 1;

  @media only screen and (max-width:575px) {

    flex-direction: column;
    align-items: flex-start;

  }

  h4 {
    margin-bottom: 0;
    margin-right: auto;

    @media only screen and (max-width:575px) {
      margin-bottom: 5px;
    }
  }

  p {

    margin-bottom: 0;
  }
}